import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import Axios from 'axios';
import { FilterModal, DatePicker, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { PenawaranApi } from 'api';

const ModalFilter = ({ show, setShow, data, setData }) => {
  const [loading, setLoading] = useState(true);
  const [dropdown, setDropdown] = useState({
    customer: [{ label: 'Semua', value: undefined }],
    proyek: [{ label: 'Semua', value: undefined }],
    instalasi: [
      { label: 'Semua', value: undefined },
      { label: 'Dengan Instalasi', value: true },
      { label: 'Tanpa Instalasi', value: false },
    ],
    statusDeal: [
      { label: 'Semua', value: undefined },
      { label: 'Deal', value: 'deal' },
      { label: 'Tidak Deal', value: 'tidak_deal' },
      { label: 'Belum Deal', value: 'belum_deal' },
    ],
  });

  const getDataDropdown = () => {
    Axios.all([
      PenawaranApi.getDropdownCustomer(),
      PenawaranApi.getDropdownProyek(),
    ])
      .then(
        Axios.spread((customer, proyek) => {
          const mapDataCustomer =
            customer?.data?.data?.map((val) => ({
              label: val?.nama_customer,
              value: val?.id_customer,
            })) ?? [];

          const mapDataProyek =
            proyek?.data?.data?.map((val) => ({
              label: val?.nama_proyek,
              value: val?.id_proyek,
            })) ?? [];

          setDropdown((prev) => ({
            ...prev,
            customer: prev.customer.concat(mapDataCustomer),
            proyek: prev.proyek.concat(mapDataProyek),
          }));
        })
      )
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getDataDropdown();
  }, []);

  const formInitialValues = {
    tgl_penawaran_mulai: data?.filter?.tgl_penawaran_mulai,
    tgl_penawaran_selesai: data?.filter?.tgl_penawaran_selesai,
    customer: data?.filter?.customer,
    proyek: data?.filter?.proyek,
    realisasi_penawaran_mulai: data?.filter?.realisasi_penawaran_mulai,
    realisasi_penawaran_selesai: data?.filter?.realisasi_penawaran_selesai,
    instalasi: data?.filter?.instalasi,
    status_deal: data?.filter?.status_deal,
  };

  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: {
          ...values,
          active: true,
        },
        pagination: {
          ...data.pagination,
          page: 1,
        },
      });
    } else {
      setData({
        ...data,
        filter: {
          ...values,
          active: false,
        },
      });
    }

    setShow(false);
  };

  const onTanggalPenawaranChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_penawaran_mulai: startDate,
      tgl_penawaran_selesai: endDate,
    });
  };

  const onTanggalRealisasiPenawaranChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      realisasi_penawaran_mulai: startDate,
      realisasi_penawaran_selesai: endDate,
    });
  };

  const onResetButtonClick = (values, setValues, handleSubmit) => {
    setValues({
      tgl_penawaran_mulai: undefined,
      tgl_penawaran_selesai: undefined,
      customer: undefined,
      proyek: undefined,
      realisasi_penawaran_mulai: undefined,
      realisasi_penawaran_selesai: undefined,
      instalasi: undefined,
    });

    handleSubmit();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() =>
            onResetButtonClick(values, setValues, handleSubmit)
          }
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Penawaran"
            placeholderText="Pilih Tanggal Penawaran"
            startDate={
              values.tgl_penawaran_mulai
                ? new Date(values.tgl_penawaran_mulai)
                : ''
            }
            endDate={
              values.tgl_penawaran_selesai
                ? new Date(values.tgl_penawaran_selesai)
                : ''
            }
            onChange={(dates) =>
              onTanggalPenawaranChange(dates, values, setValues)
            }
            monthsShown={2}
          />
          <SelectSearch
            key={values.customer}
            label="Customer"
            placeholder="Pilih customer"
            defaultValue={dropdown.customer.find(
              (val) => val.value === values.customer
            )}
            option={dropdown.customer}
            onChange={(val) => setFieldValue('customer', val.value)}
            loading={loading}
          />
          <SelectSearch
            key={values.proyek}
            label="Proyek"
            placeholder="Pilih Proyek"
            defaultValue={dropdown.proyek.find(
              (val) => val.value === values.proyek
            )}
            option={dropdown.proyek}
            onChange={(val) => setFieldValue('proyek', val.value)}
            loading={loading}
          />
          <DatePicker
            selectsRange
            label="Tgl. Realisasi Penawaran"
            placeholderText="Pilih Tanggal Realisasi Penawaran"
            startDate={
              values.realisasi_penawaran_mulai
                ? new Date(values.realisasi_penawaran_mulai)
                : ''
            }
            endDate={
              values.realisasi_penawaran_selesai
                ? new Date(values.realisasi_penawaran_selesai)
                : ''
            }
            onChange={(dates) =>
              onTanggalRealisasiPenawaranChange(dates, values, setValues)
            }
            monthsShown={2}
          />
          <SelectSearch
            key={values.instalasi}
            label="Instalasi"
            placeholder="Pilih Instalasi"
            defaultValue={dropdown.instalasi.find(
              (val) => val.value === values.instalasi
            )}
            option={dropdown.instalasi}
            onChange={(val) => setFieldValue('instalasi', val.value)}
          />

          <SelectSearch
            key={values.status_deal}
            label="Status Deal"
            placeholder="Pilih Status Deal"
            defaultValue={dropdown.statusDeal.find(
              (val) => val.value === values.status_deal
            )}
            option={dropdown.statusDeal}
            onChange={(val) => setFieldValue('status_deal', val.value)}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;

import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import Axios from 'axios';
import { FilterModal, DatePicker, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { RegistrasiPeluangApi } from 'api';

const ModalFilter = ({ show, setShow, data, setData }) => {
  const [loading, setLoading] = useState({
    allDropdown: true,
    proyek: true,
  });
  const [dataPeringkatPeluang, setDataPeringkatPeluang] = useState([
    { label: 'Semua', value: undefined },
  ]);
  const [dataCustomer, setDataCustomer] = useState([
    { label: 'Semua', value: undefined },
  ]);
  const [dataProyek, setDataProyek] = useState([
    { label: 'Semua', value: undefined },
  ]);
  const [dataKelompokProyek, setDataKelompokProyek] = useState([
    {
      value: undefined,
      label: 'Semua',
    },
    {
      value: 'proyek',
      label: 'Proyek',
    },
    {
      value: 'umum',
      label: 'Umum',
    },
    {
      value: 'tender',
      label: 'Tender',
    },
  ]);
  const [dataInstalasi, setDataInstalasi] = useState([
    { label: 'Semua', value: undefined },
    { label: 'Dengan Instalasi', value: true },
    { label: 'Tanpa Instalasi', value: false },
  ]);

  const formInitialValues = {
    tgl_peluang_mulai: data?.tgl_peluang_mulai,
    tgl_peluang_selesai: data?.tgl_peluang_selesai,
    peringkat_peluang: data?.peringkat_peluang,
    customer: data?.customer,
    proyek: data?.proyek,
    kelompok_proyek: data?.kelompok_proyek,
    target_penawaran_mulai: data?.target_penawaran_mulai,
    target_penawaran_selesai: data?.target_penawaran_selesai,
    realisasi_penawaran_mulai: data?.realisasi_penawaran_mulai,
    realisasi_penawaran_selesai: data?.realisasi_penawaran_selesai,
    instalasi: data?.instalasi,
  };

  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        ...values,
        active: true,
      });
    } else {
      setData({
        ...values,
        active: false,
      });
    }

    setShow(false);
  };

  const getDataDropdown = () => {
    Axios.all([
      RegistrasiPeluangApi.getPeringkatPeluang(),
      RegistrasiPeluangApi.getCustomer(),
      RegistrasiPeluangApi.getProyek(),
    ])
      .then(
        Axios.spread((peringkat, customer, proyek) => {
          const mapDataPeringkat = peringkat?.data?.data
            ? peringkat.data.data.map((val) => ({
                label: val?.nama_peringkat_peluang,
                value: val?.id_peringkat_peluang,
              }))
            : [];
          const mapDataCustomer = customer?.data?.data
            ? customer.data.data.map((val) => ({
                label: val?.nama_customer,
                value: val?.id_customer,
              }))
            : [];
          const mapDataProyek = proyek?.data?.data
            ? proyek.data.data.map((val) => ({
                label: val?.nama_proyek,
                value: val?.id_proyek,
              }))
            : [];

          setDataPeringkatPeluang([
            ...dataPeringkatPeluang,
            ...mapDataPeringkat,
          ]);
          setDataCustomer([...dataCustomer, ...mapDataCustomer]);
          setDataProyek([
            { label: 'Semua', value: undefined },
            ...mapDataProyek,
          ]);
        })
      )
      .finally(() => {
        setLoading({
          ...loading,
          allDropdown: false,
          proyek: false,
        });
      });
  };

  const getProyek = (data) => {
    setLoading({
      ...loading,
      proyek: true,
    });

    RegistrasiPeluangApi.getProyek({ id_customer: data })
      .then((proyek) => {
        const mapDataProyek = proyek?.data?.data
          ? proyek.data.data.map((val) => ({
              label: val.nama_proyek,
              value: val.id_proyek,
            }))
          : [];
        setDataProyek([{ label: 'Semua', value: undefined }, ...mapDataProyek]);
      })
      .finally(() => {
        setLoading({
          ...loading,
          proyek: false,
        });
      });
  };

  const onTanggalPeluangChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_peluang_mulai: startDate,
      tgl_peluang_selesai: endDate,
    });
  };

  const onTargetPenawaranChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      target_penawaran_mulai: startDate,
      target_penawaran_selesai: endDate,
    });
  };

  const onRealisasiPenawaranChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      realisasi_penawaran_mulai: startDate,
      realisasi_penawaran_selesai: endDate,
    });
  };

  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_peluang_mulai: undefined,
      tgl_peluang_selesai: undefined,
      peringkat_peluang: undefined,
      customer: undefined,
      proyek: undefined,
      kelompok_proyek: undefined,
      target_penawaran_mulai: undefined,
      target_penawaran_selesai: undefined,
      realisasi_penawaran_mulai: undefined,
      realisasi_penawaran_selesai: undefined,
      instalasi: undefined,
    });
  };

  useEffect(() => {
    getDataDropdown();
  }, []);

  useEffect(() => {
    data.customer && getProyek(data.customer);
  }, [data.customer]);

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Peluang"
            placeholderText="Pilih tanggal peluang"
            startDate={
              values.tgl_peluang_mulai ? new Date(values.tgl_peluang_mulai) : ''
            }
            endDate={
              values.tgl_peluang_selesai
                ? new Date(values.tgl_peluang_selesai)
                : ''
            }
            onChange={(dates) =>
              onTanggalPeluangChange(dates, values, setValues)
            }
            monthsShown={2}
          />
          <SelectSearch
            key={values.peringkat_peluang}
            label="Peringkat Peluang"
            placeholder="Pilih peringkat peluang"
            defaultValue={dataPeringkatPeluang.find(
              (val) => val.value === values.peringkat_peluang
            )}
            option={dataPeringkatPeluang}
            onChange={(val) => setFieldValue('peringkat_peluang', val.value)}
            loading={loading.allDropdown}
          />
          <SelectSearch
            key={values.customer}
            label="Customer"
            placeholder="Pilih customer"
            defaultValue={dataCustomer.find(
              (val) => val.value === values.customer
            )}
            option={dataCustomer}
            onChange={(val) => {
              setValues({
                ...values,
                customer: val.value,
                proyek: undefined,
              });
              getProyek(val.value, values, setValues);
            }}
            loading={loading.allDropdown}
          />
          <SelectSearch
            key={values.proyek}
            label="Proyek"
            placeholder="Pilih proyek untuk memilih proyek"
            defaultValue={dataProyek.find((val) => val.value === values.proyek)}
            option={dataProyek}
            onChange={(val) => setFieldValue('proyek', val.value)}
            loading={loading.proyek}
          />
          <SelectSearch
            key={values.kelompok_proyek}
            label="Kelompok Proyek"
            placeholder="Pilih kelompok proyek"
            defaultValue={dataKelompokProyek.find(
              (val) => val.value === values.kelompok_proyek
            )}
            option={dataKelompokProyek}
            onChange={(val) => setFieldValue('kelompok_proyek', val.value)}
            loading={loading.allDropdown}
          />
          <DatePicker
            selectsRange
            label="Target Penawaran"
            placeholderText="Pilih target penawaran"
            startDate={
              values.target_penawaran_mulai
                ? new Date(values.target_penawaran_mulai)
                : ''
            }
            endDate={
              values.target_penawaran_selesai
                ? new Date(values.target_penawaran_selesai)
                : ''
            }
            onChange={(dates) =>
              onTargetPenawaranChange(dates, values, setValues)
            }
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Realisasi Penawaran"
            placeholderText="Pilih realisasi penawaran"
            startDate={
              values.realisasi_penawaran_mulai
                ? new Date(values.realisasi_penawaran_mulai)
                : ''
            }
            endDate={
              values.realisasi_penawaran_selesai
                ? new Date(values.realisasi_penawaran_selesai)
                : ''
            }
            onChange={(dates) =>
              onRealisasiPenawaranChange(dates, values, setValues)
            }
            monthsShown={2}
          />
          <SelectSearch
            key={values.instalasi}
            label="Instalasi"
            placeholder="Pilih instalasi"
            defaultValue={dataInstalasi.find(
              (val) => val.value === values.instalasi
            )}
            option={dataInstalasi}
            onChange={(val) => setFieldValue('instalasi', val.value)}
          />
        </FilterModal>
      )}
    </Formik>
  );
};

export default ModalFilter;

import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Input, DatePicker, SelectSearch } from 'components';
import { SuratPerjanjianKerjaApi } from 'api';

const FormSection = ({ formik, dataSegmentPasar }) => {
  const { values, errors, touched, setFieldValue } = formik;

  // Menangani generate nomor baru
  const getNomorHandler = (date) => {
    setFieldValue('tgl_spk', date);
  };

  useEffect(() => {}, []);

  return (
    <>
      <Row className="d-flex justify-content-center align-items-center">
        <Col md>
          <DatePicker
            label="Tgl. SPK"
            placeholderText="Pilih tanggal SPK"
            selected={values.tgl_spk ? new Date(values.tgl_spk) : ''}
            onChange={(val) => {
              const value = val.toISOString().slice(0, 10);
              getNomorHandler(value);
            }}
            error={errors.tgl_spk && touched.tgl_spk && true}
            errorText={errors.tgl_spk && touched.tgl_spk && errors.tgl_spk}
          />
        </Col>
        <Col md>
          <Input
            label="No. SPK"
            placeholder="Pilih tgl. SPK untuk menentukan nomor"
            value={values.no_spk}
            readOnly
          />
        </Col>
        <Col md>
          <DatePicker
            label="Tgl. Selesai Barang Jadi"
            placeholderText="Pilih tanggal selesai"
            selected={values.tgl_selesai ? new Date(values.tgl_selesai) : ''}
            onChange={(val) => {
              const value = val.toISOString().slice(0, 10);
              setFieldValue('tgl_selesai', value);
            }}
            error={errors.tgl_selesai && touched.tgl_selesai && true}
            errorText={
              errors.tgl_selesai && touched.tgl_selesai && errors.tgl_selesai
            }
          />
        </Col>
        <Col md>
          <SelectSearch
            label="Segment Pasar"
            placeholder="Pilih Segment Pasar"
            onChange={(val) => setFieldValue('id_segmentasi_pasar', val.value)}
            option={dataSegmentPasar}
            defaultValue={
              values.id_segmentasi_pasar
                ? dataSegmentPasar.find(
                    (item) => item.value === values.id_segmentasi_pasar
                  )
                : ''
            }
            error={
              errors.id_segmentasi_pasar && touched.id_segmentasi_pasar && true
            }
            errorText={
              errors.id_segmentasi_pasar &&
              touched.id_segmentasi_pasar &&
              errors.id_segmentasi_pasar
            }
          />
        </Col>
      </Row>
      <hr />
    </>
  );
};

export default FormSection;

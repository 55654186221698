import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  DataStatus,
  Th,
  ThFixed,
  TdFixed,
  Td,
  Table,
  TextEditor,
} from 'components';
import { DateConvert } from 'utilities';
import { IoCheckboxOutline, IoCloseCircleOutline } from 'react-icons/io5';
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const TabInfo = ({ dataInfoPeluang, dataTablePeluang }) => {
  const [textEditorState, setTextEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(dataInfoPeluang.lain_lain ?? '')
      )
    )
  );
  const InfoItemModal = ({ label, text, className, style, width }) => (
    <div className={`d-flex align-items-top ${className}`} style={style}>
      <div style={{ width: width ? width : 150, fontSize: 14 }}>{label}</div>
      <div className="pl-3 pr-2" style={{ fontSize: 14 }}>
        :
      </div>
      <div style={{ fontSize: 14 }}>{text}</div>
    </div>
  );

  const InfoItem = ({ title, value }) => (
    <tr>
      <td>{title}</td>
      <td className="pl-4 pr-2">:</td>
      <td className="text-capitalize">{value}</td>
    </tr>
  );

  const ShowData = ({ title, text, line }) => (
    <div>
      <small
        className="text-secondary"
        style={{ opacity: 0.9, textTransform: 'capitalize' }}
      >
        {title}
      </small>
      <div className="text-dark">
        <div>{text ? text : '-'}</div>
      </div>

      {line && <hr className="my-2" />}
    </div>
  );

  const updateEditorState = (value, setFieldValue) => {
    setTextEditorState(value);
    const convertData = convertToRaw(value.getCurrentContent());
    setFieldValue('lain_lain', draftToHtml(convertData));
  };

  return (
    <>
      <Row className="mb-4">
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Tgl. Peluang"
                value={
                  dataInfoPeluang?.tgl_peluang
                    ? DateConvert(new Date(dataInfoPeluang.tgl_peluang)).detail
                    : '-'
                }
              />
              <InfoItem
                title="No. Peluang"
                value={dataInfoPeluang?.no_peluang ?? '-'}
              />
              <InfoItem
                title="Target Penawaran"
                value={
                  dataInfoPeluang?.tgl_selesai
                    ? DateConvert(new Date(dataInfoPeluang.tgl_selesai)).detail
                    : '-'
                }
              />
              <InfoItem
                title="Realisasi Penawaran"
                value={
                  dataInfoPeluang?.realisasi_penawaran
                    ? DateConvert(new Date(dataInfoPeluang.realisasi_penawaran))
                        .detail
                    : '-'
                }
              />
            </tbody>
          </table>
        </Col>
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Peringkat Peluang"
                value={dataInfoPeluang?.nama_peringkat_peluang ?? '-'}
              />
              <InfoItem
                title="Customer"
                value={dataInfoPeluang?.nama_customer ?? '-'}
              />
              <InfoItem
                title="Proyek"
                value={dataInfoPeluang?.nama_proyek ?? '-'}
              />
              <InfoItem title="ATT" value={dataInfoPeluang?.att ?? '-'} />
              <InfoItem
                title="Kelompok Proyek"
                value={dataInfoPeluang?.kelompok_proyek ?? '-'}
              />
            </tbody>
          </table>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md>
          <ShowData
            title="Keterangan Pengiriman"
            text={dataInfoPeluang.keterangan_pengiriman ?? '-'}
            line={true}
          />
          <ShowData
            title="Lokasi Pengiriman"
            text={dataInfoPeluang.lokasi_pengiriman ?? '-'}
            line={true}
          />
          <ShowData
            title="Link Referensi"
            text={dataInfoPeluang.link_referensi ?? '-'}
            line={true}
          />
        </Col>
        <Col md>
          <ShowData
            title="Schedule Kebutuhan Proyek (Total Hari)"
            text={dataInfoPeluang.schedule_kebutuhan_proyek ?? '-'}
            line={true}
          />
          <ShowData
            title="Instalasi"
            text={
              dataInfoPeluang.instalasi === true ? (
                <IoCheckboxOutline />
              ) : (
                <IoCloseCircleOutline />
              )
            }
            line={true}
          />
        </Col>
      </Row>
      <TextEditor
        readOnly
        options={[]}
        label="Informasi Lainya"
        editorState={textEditorState}
      />
      <hr />
      <div className="my-2">
        <b>Barang Jadi</b>
      </div>
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <Th style={{ maxWidth: 80 }} className="py-2">
              Kode Barang Jadi
            </Th>
            <Th style={{ minWidth: 200 }}>Nama Barang Jadi</Th>
            <Th>Jenis Barang Jadi</Th>
            <Th>Satuan</Th>
            <Th>Qty</Th>
          </tr>
        </thead>
        <tbody>
          {dataTablePeluang && dataTablePeluang.length > 0 ? (
            dataTablePeluang.map((val, index) => (
              <tr>
                <TdFixed>{index + 1}</TdFixed>
                <Td>{val.kode_item}</Td>
                <Td>{val.nama_item}</Td>
                <Td>{val.nama_jenis}</Td>
                <Td>{val.nama_satuan_jual}</Td>
                <Td textRight>{val.qty}</Td>
              </tr>
            ))
          ) : (
            <tr>
              <Td colSpan={6}>
                <DataStatus text="Tidak data data" />
              </Td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default TabInfo;

import React, { forwardRef, useEffect, useState } from 'react';
import config from 'config';
// import { TableLC, TableMP } from '../../RAB'
import { RupiahConvert, DateConvert } from 'utilities';
import parseHtml from 'html-react-parser';

const PrintPenawaran = forwardRef((props, ref) => {
  let id = props.id;
  let dataRAB = props.dataRAB;
  let dataPrelim = props.dataPrelim;
  let dataListRAB = props.dataListRAB;
  let values = props.values;

  const [totalLC, setTotalLC] = useState(0);
  const [totalMP, setTotalMP] = useState(0);
  const [totalPenawaran, setTotalPenawaran] = useState(0);

  const tableStyling = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle',
  };

  // FUNCTION KONVERSI TANGGAL INPUT
  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const getDate = inputDate.split(' ');
      const date = new Date(getDate[0]);
      // return `${DateConvert(date).defaultDay} ${DateConvert(date).detailMonth} ${DateConvert(date).defaultYear}`
      return DateConvert(date).custom;
    }
    return '-';
  };

  const getTotal = (index) => {
    const price = dataListRAB[index].rounded ? dataListRAB[index].rounded : 0;
    const qty = dataListRAB[index].qty ? dataListRAB[index].qty : 0;

    return parseFloat(price * qty);
  };

  const getAfterProfit = (index) => {
    const newProfit = values.profit ? parseFloat(values.profit) : 0;
    const newHarga = dataListRAB[index].harga
      ? parseFloat(dataListRAB[index].harga)
      : 0;
    const afterProfit = (newProfit / 100 + 1) * newHarga;

    return parseInt(afterProfit);
  };

  const getGrandTotalPrelim = dataPrelim.reduce((prev, current) => {
    const newQtyItem = current.qty_item ? parseFloat(current.qty_item) : 0;
    const newHarga = current.harga ? parseFloat(current.harga) : 0;
    const newQtyDurasi = current.qty_durasi
      ? parseFloat(current.qty_durasi)
      : 0;
    const newKonts = current.konstanta ? parseFloat(current.konstanta) : 0;
    const subTotal = newQtyItem * newHarga;
    const total = subTotal * newKonts;

    return prev + total;
  }, 0);

  const getGrandTotal = dataListRAB.reduce((prev, current, index) => {
    return prev + getTotal(index);
  }, 0);

  const TablePenawaran = ({ dataListRAB, setTotalPenawaran }) => {
    return (
      <>
        <div className="p-2 mt-3">
          <b>List Item RAB</b>
        </div>

        <div>
          <table className="table table-bordered bg-white table-sm">
            <thead className="text-center">
              <tr>
                <th rowSpan={2} width={30} className="align-middle">
                  No.
                </th>
                <th rowSpan={2} width={120} className="align-middle">
                  Kode Barang Jadi
                </th>
                <th rowSpan={2} width={250} className="align-middle">
                  Item Barang Jadi
                </th>
                <th rowSpan={2} className="align-middle">
                  Qty
                </th>
                <th rowSpan={2} className="align-middle">
                  Satuan
                </th>
                <th rowSpan={2} width={125} className="align-middle">
                  Unit Cost
                </th>
                <th width={125} className="align-middle">
                  Profit (%)
                </th>
                <th rowSpan={2} width={125} className="align-middle">
                  Rounded
                </th>
                <th rowSpan={2} width={125} className="align-middle">
                  Sub Total
                </th>
              </tr>
              <tr>
                <th className="align-middle">{values.profit}</th>
              </tr>
            </thead>
            <tbody>
              {dataListRAB && dataListRAB.length > 0 ? (
                dataListRAB.map((val, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{val.kode_item}</td>
                    <td>{val.nama_item}</td>
                    <td>{val.qty ? parseFloat(val.qty) : 0}</td>
                    <td>{val.nama_satuan}</td>
                    <td className="text-right">
                      {val.harga
                        ? RupiahConvert(String(parseFloat(val.harga))).detail
                        : RupiahConvert(String(0)).detail}
                    </td>
                    <td className="text-right">
                      {RupiahConvert(String(getAfterProfit(index))).detail}
                    </td>
                    <td className="text-right">
                      {val.rounded
                        ? RupiahConvert(String(parseFloat(val.rounded))).detail
                        : RupiahConvert(String(0)).detail}
                    </td>
                    <td className="text-right">
                      {RupiahConvert(String(getTotal(index))).detail}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={9}>
                    {/* <DataStatus text="Tidak ada data" /> */}
                  </td>
                </tr>
              )}
              <tr>
                <td className="pb-2">
                  {dataListRAB && dataListRAB.length > 0
                    ? dataListRAB.length + 1
                    : 1}
                </td>
                <td>-</td>
                <td>
                  <a
                  // href="#"
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   setModalPrelim({ show: true });
                  // }}
                  >
                    <b>PRELIM</b>
                  </a>
                </td>
                <td>1</td>
                <td>-</td>
                <td className="text-right">
                  {RupiahConvert(String(getGrandTotalPrelim)).detail}
                </td>
                <td className="text-right">
                  {RupiahConvert(String(getGrandTotalPrelim)).detail}
                </td>
                <td className="text-right">
                  {RupiahConvert(String(getGrandTotalPrelim)).detail}
                </td>
                <td className="text-right">
                  {RupiahConvert(String(getGrandTotalPrelim)).detail}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const GrandTotalSection = () => {
    return (
      <div className="py-2 p-1 bg-white text-right border">
        <b
          className="text-nowrap pr-3 d-flex justify-content-end align-items-center"
          style={{ fontSize: '14px' }}
        >
          <div className="pr-3">Grand Total :</div>
          {RupiahConvert(String(getGrandTotal + getGrandTotalPrelim)).detail}
        </b>
      </div>
    );
  };

  const Header = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="col pl-0">
          <img src={config.LOGO} height="150" />
        </div>
        <div className="col-10 pl-0">
          <h6 className="font-weight-bold">PT. MARDIKA GRIYA PRASTA</h6>
          <span>Jl. Sekarsari No.30, Kesambi, Denpasar, Bali, Indonesia</span>
          <br />
          <span>Phone +62 (0)361 467-342</span>
          <br />
          <span>Fax (0361) 46730</span>
          <br />
          <span>
            Email{' '}
            <span style={{ color: '#6aadff' }}>sales@mgpwoodworks.com</span>
          </span>
          <br />
        </div>
      </div>
    );
  };

  const InfoField = ({ title, value, isBold }) => {
    return (
      <div className="row">
        <div className="col">
          <h6 className={isBold ? 'font-weight-bold' : ''}>{title}</h6>
        </div>
        <div className="col-10">
          <h6>: {value}</h6>
        </div>
      </div>
    );
  };

  return (
    <div className="container my-5 px-5" ref={ref}>
      <Header />
      <div className="my-3">
        <InfoField
          title="Tgl. RAB"
          value={getConvertedDate(dataRAB.tgl_rab) ?? '-'}
        />
        <InfoField title="No. RAB" value={dataRAB.no_rab ?? '-'} />
        <InfoField
          title="Tgl. RAE"
          value={getConvertedDate(dataRAB.tgl_rae) ?? '-'}
        />
        <InfoField title="No. RAE" value={dataRAB.no_rae ?? '-'} />
        <InfoField title="Proyek" value={dataRAB.nama_proyek ?? '-'} />
        <InfoField title="Customer" value={dataRAB.nama_customer} />
        <InfoField title="ATT" value={dataRAB.att ?? '-'} />
        <InfoField
          title="Tgl. Realisasi Penawaran"
          value={getConvertedDate(dataRAB.tgl_selesai) ?? '-'}
        />
      </div>

      <hr />

      <div className="my-3">
        {parseHtml(dataRAB?.teks_pembuka ?? '<p></p>')}
      </div>

      <TablePenawaran
        dataListRAB={dataListRAB}
        setTotalPenawaran={setTotalPenawaran}
      />

      <GrandTotalSection
        totalPenawaran={totalPenawaran}
        totalLC={totalLC}
        totalMP={totalMP}
      />

      <div className="my-3">
        {parseHtml(dataRAB?.teks_penutup ?? '<p></p>')}
      </div>

      {/* FOOTER SIGNATURE*/}
      <div className="container pb-5 mt-5" style={{ height: '15vh' }}>
        <div className="row pb-5">
          <div className="col d-flex flex-column justify-content-center align-items-start">
            <h5 className="mb-5 pl-4 text-center">Hormat Kami,</h5>
            <div className="d-flex mt-5 flex-column justify-content-end align-items-center ">
              <span>
                <u>A.A. Ngurah Temaja Putra</u>
              </span>
              <span>Direktur</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PrintPenawaran;

import React, { useState, useEffect } from 'react';
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { TextEditor, ActionButton } from '../../../../../../components';

const TextEditorSection = ({ formik }) => {
  const { values, isSubmitting, setFieldValue, handleSubmit } = formik;
  const [catatan, setCatatan] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(values.catatan))
    )
  );

  // Memperbaharui nilai catatan & submit
  const submitHandler = () => {
    const convertData = convertToRaw(catatan.getCurrentContent());
    setFieldValue('catatan', draftToHtml(convertData));
    handleSubmit();
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <TextEditor
        label="Catatan"
        placeholder="Masukan catatan"
        editorState={catatan}
        onEditorStateChange={(val) => setCatatan(val)}
      />
      <div className="d-flex justify-content-end mt-3">
        <ActionButton
          text="Ubah Data SPK"
          variant="success"
          onClick={submitHandler}
          loading={isSubmitting}
        />
      </div>
    </>
  );
};

export default TextEditorSection;

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { DateConvert } from 'utilities';

const InfoSection = ({ dataPenawaran, setModalPeluangConfig }) => {
  const InfoItem = ({ title, value, link, onClick }) => (
    <tr onClick={onClick}>
      <td>{title}</td>
      <td className={`pl-4 pr-2`}>:</td>
      <td
        className={link ? 'text-primary' : ''}
        style={{ cursor: link ? 'pointer' : 'default' }}
      >
        {value}
      </td>
    </tr>
  );

  return (
    <Row>
      <Col>
        <table style={{ fontSize: '14px' }}>
          <tbody>
            <InfoItem
              title="Tgl. Penawaran"
              value={
                dataPenawaran.tgl_penawaran &&
                DateConvert(new Date(dataPenawaran.tgl_penawaran)).custom
              }
            />
            <InfoItem
              title="No. Penawaran"
              value={dataPenawaran.no_penawaran}
            />
            <InfoItem
              link
              title="No. Peluang"
              value={dataPenawaran?.no_peluang ?? '-'}
              onClick={() =>
                setModalPeluangConfig({
                  show: true,
                  no_peluang: dataPenawaran?.no_peluang ?? '',
                })
              }
            />
          </tbody>
        </table>
      </Col>

      <Col>
        <table style={{ fontSize: '14px' }}>
          <tbody>
            <InfoItem title="Customer" value={dataPenawaran.nama_customer} />
            <InfoItem title="ATT" value={dataPenawaran.att} />
            <InfoItem
              title="Tgl. Realisasi Penawaran"
              value={
                dataPenawaran.tgl_selesai &&
                DateConvert(new Date(dataPenawaran.tgl_selesai)).custom
              }
            />
            <InfoItem title="Proyek" value={dataPenawaran.nama_proyek ?? '-'} />
          </tbody>
        </table>
      </Col>
    </Row>
  );
};

export default InfoSection;

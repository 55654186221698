// React
import React, { useState, useEffect } from 'react';

// Component
import {
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  Td,
  Th,
  SelectSearch,
  TextArea,
  Input,
  CreateButton,
  UpdateButton,
  DeleteButton,
  ReadButton,
} from 'components';
import { DeleteModal } from './ViewComponent';
import { Col, Row, Modal } from 'react-bootstrap';
import { TableNumber } from 'utilities';
import * as Yup from 'yup';

// Form
import { Formik } from 'formik';

// API
import { ProyekApi } from 'api';
import axios from 'axios';

const Proyek = ({ setNavbarTitle }) => {
  // VARIABLES
  const title = 'Proyek';

  // STATES
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [data, setData] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    tipe: '',
    data: {
      id_proyek: '',
      kode_proyek: '',
      nama_proyek: '',
      lokasi_proyek: '',
      pemilik_proyek: '',
      id_customer: '',
      id_karyawan: '',
      id_penanggung_jawab: '',
      ket: '',
    },
  });
  const [dataAtribut, setDataAtribut] = useState({
    customer: [],
    karyawan: [],
  });
  const [loadingAtribut, setLoadingAtribut] = useState({
    customer: false,
    karyawan: false,
  });

  const tableStyle = {
    tdClassname: 'p-1 px-2 align-middle',
    tdStyle: { width: 'auto' },
    tdFixedStyle: { width: '100px' },
  };

  const getInitialData = () => {
    setIsPageLoading(true);

    ProyekApi.page({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setData(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  useEffect(() => {
    setNavbarTitle(title);
    getInitialData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setNavbarTitle,
    searchConfig.key,
    searchConfig.bulan,
    searchConfig.tahun,
    paginationConfig.page,
    paginationConfig.dataLength,
  ]);

  useEffect(() => {
    setLoadingAtribut({
      customer: true,
      karyawan: true,
    });

    axios
      .all([
        ProyekApi.dropdown({ tipe: 'customer' }),
        ProyekApi.dropdown({ tipe: 'karyawan' }),
      ])
      .then(
        axios.spread((customer, karyawan) =>
          setDataAtribut({
            customer: customer.data.data,
            karyawan: karyawan.data.data,
          })
        )
      )
      .finally(() =>
        setLoadingAtribut({
          customer: false,
          karyawan: false,
        })
      );
  }, []);

  // KOMPONEN MODAL FORM POSTING
  const ModalData = () => {
    // STATES
    const [noBaru, setNoBaru] = useState('');

    const formInitialValues = {
      kode_proyek:
        modalConfig?.tipe === 'tambah'
          ? noBaru
          : modalConfig?.data?.kode_proyek,
      nama_proyek: modalConfig?.data?.nama_proyek,
      lokasi_proyek: modalConfig?.data?.lokasi_proyek,
      pemilik_proyek: modalConfig?.data?.pemilik_proyek,
      id_customer: modalConfig?.data?.id_customer,
      id_karyawan: modalConfig?.data?.id_karyawan,
      id_penanggung_jawab: modalConfig?.data?.id_penanggung_jawab,
      ket: modalConfig?.data?.ket,
    };
    const formValidationSchema = Yup.object().shape({
      nama_proyek: Yup.string().required('Masukkan Nama Proyek'),
      id_customer: Yup.string().required('Pilih Customer').nullable(),
      id_karyawan:
        modalConfig?.tipe === 'tambah'
          ? Yup.string().required('Pilih Penanggung Jawab').nullable()
          : '',
    });

    if (modalConfig?.tipe !== 'tambah') {
      formInitialValues.id_proyek = modalConfig?.data?.id_proyek;
    }

    const formSubmitHandler = (values) => {
      if (modalConfig.tipe === 'tambah') {
        ProyekApi.store(values)
          .then(() => {
            // SUCCESS ALERT
            setAlertConfig({
              show: true,
              variant: 'primary',
              text: 'Tambah data berhasil!',
            });
          })
          .catch(() => {
            // ERROR ALERT
            setAlertConfig({
              show: true,
              variant: 'danger',
              text: `Tambah data gagal!`,
            });
          })
          .finally(() => {
            setModalConfig({
              ...modalConfig,
              show: false,
            });

            // FETCH DATA DARI SERVER
            getInitialData();
          });
      } else {
        ProyekApi.update(values)
          .then(() => {
            // SUCCESS ALERT
            setAlertConfig({
              show: true,
              variant: 'primary',
              text: 'Ubah data berhasil!',
            });
          })
          .catch(() => {
            // ERROR ALERT
            setAlertConfig({
              show: true,
              variant: 'danger',
              text: `Ubah data gagal!`,
            });
          })
          .finally(() => {
            setModalConfig({
              ...modalConfig,
              show: false,
            });

            // FETCH DATA DARI SERVER
            getInitialData();
          });
      }
    };

    useEffect(() => {
      modalConfig?.tipe === 'tambah' &&
        ProyekApi.no_baru().then((res) => setNoBaru(res.data.data));
    }, []);

    return (
      <Modal
        show={modalConfig.show && modalConfig.tipe !== 'hapus'}
        onHide={() => {
          setModalConfig({
            ...modalConfig,
            show: false,
          });
        }}
      >
        <Modal.Header closeButton>
          <b className="text-capitalize">{`${modalConfig.tipe} ${title}`}</b>
        </Modal.Header>
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Input
                  label="Kode Proyek"
                  placeholder="Kode Proyek"
                  type="text"
                  value={values.kode_proyek}
                  readOnly={true}
                />

                <Input
                  label="Nama Proyek"
                  placeholder="Nama Proyek"
                  type="text"
                  name="nama_proyek"
                  value={values.nama_proyek}
                  onChange={handleChange}
                  error={errors.nama_proyek && touched.nama_proyek && true}
                  errorText={errors.nama_proyek}
                  readOnly={modalConfig?.tipe === 'detail' ? true : false}
                />

                <TextArea
                  label="Lokasi Proyek"
                  placeholder="Lokasi Proyek"
                  name="lokasi_proyek"
                  rows="3"
                  value={values.lokasi_proyek}
                  onChange={handleChange}
                  error={errors.lokasi_proyek && touched.lokasi_proyek && true}
                  errorText={errors.lokasi_proyek}
                  readOnly={modalConfig?.tipe === 'detail' ? true : false}
                />

                <Input
                  label="Pemilik Proyek"
                  placeholder="Pemilik Proyek"
                  type="text"
                  name="pemilik_proyek"
                  value={values.pemilik_proyek}
                  onChange={handleChange}
                  error={
                    errors.pemilik_proyek && touched.pemilik_proyek && true
                  }
                  errorText={errors.pemilik_proyek}
                  readOnly={modalConfig?.tipe === 'detail' ? true : false}
                />

                <SelectSearch
                  label="Customer"
                  placeholder="Pilih Customer"
                  onChange={(val) =>
                    setValues({
                      ...values,
                      id_customer: val.value,
                    })
                  }
                  option={dataAtribut.customer.map((val) => {
                    return {
                      value: val.id_customer,
                      label: val.nama_customer,
                    };
                  })} //option harus berupa value dan label
                  defaultValue={
                    values.id_customer
                      ? dataAtribut.customer.map((val) => {
                          if (val.id_customer === values.id_customer) {
                            return {
                              label: val.nama_customer,
                              value: val.id_customer,
                            };
                          }
                        })
                      : ''
                  }
                  error={errors.id_customer && touched.id_customer && true}
                  errorText={
                    errors.id_customer &&
                    touched.id_customer &&
                    errors.id_customer
                  }
                  isDisabled={modalConfig?.tipe === 'detail' ? true : false}
                  loading={loadingAtribut.customer}
                />
                {console.log(values)}
                <SelectSearch
                  label="Penanggung Jawab"
                  placeholder="Pilih Penanggung Jawab"
                  onChange={(val) =>
                    setValues({
                      ...values,
                      id_karyawan: val.value,
                      id_penanggung_jawab: val.value,
                    })
                  }
                  option={dataAtribut.karyawan.map((val) => {
                    return {
                      value: val.id_karyawan,
                      label: val.nama_karyawan,
                    };
                  })} //option harus berupa value dan label
                  defaultValue={
                    values.id_penanggung_jawab
                      ? dataAtribut.karyawan.map((val) => {
                          if (val.id_karyawan === values.id_penanggung_jawab) {
                            return {
                              label: val.nama_karyawan,
                              value: val.id_karyawan,
                            };
                          }
                        })
                      : ''
                  }
                  error={errors.id_karyawan && touched.id_karyawan && true}
                  errorText={
                    errors.id_karyawan &&
                    touched.id_karyawan &&
                    errors.id_karyawan
                  }
                  isDisabled={modalConfig?.tipe === 'detail' ? true : false}
                  loading={loadingAtribut.karyawan}
                />

                <TextArea
                  label="Keterangan"
                  placeholder="Keterangan"
                  name="ket"
                  rows="3"
                  value={values.ket}
                  onChange={handleChange}
                  error={errors.ket && touched.ket && true}
                  errorText={errors.ket}
                  readOnly={modalConfig?.tipe === 'detail' ? true : false}
                />
              </Modal.Body>

              {modalConfig?.tipe !== 'detail' && (
                <Modal.Footer>
                  <ActionButton
                    type="submit"
                    variant="primary"
                    text="Simpan"
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </Modal.Footer>
              )}
            </form>
          )}
        </Formik>
      </Modal>
    );
  };

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      ProyekApi.delete({ id_proyek: modalConfig.data.id_proyek })
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Hapus data berhasil!',
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: `Hapus data gagal! (${err?.response?.data?.message})`,
          });
        })
        .finally(() => {
          setModalConfig({
            ...modalConfig,
            show: false,
          });

          getInitialData();
        });
    };

    return (
      <DeleteModal
        show={modalConfig.show && modalConfig.tipe === 'hapus'}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={`${modalConfig.tipe} ${title}`}
      >
        <div>Nama Proyek : {modalConfig?.data?.nama_proyek}</div>
        <div>Kode Proyek : {modalConfig?.data?.kode_proyek}</div>
      </DeleteModal>
    );
  };

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <Th style={{ width: 6 }}>No</Th>
            <Th style={{ width: 6 }}>Aksi</Th>
            <Th>Kode</Th>
            <Th>Nama</Th>
            <Th>Customer</Th>
            <Th>Lokasi</Th>
            <Th>Pemilik</Th>
          </tr>
        </thead>
        <tbody>
          {data.map((val, index) => (
            <tr key={index}>
              <Td className={tableStyle.tdClassname} style={tableStyle.tdStyle}>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </Td>
              <Td>
                <div className="d-flex justify-content-center">
                  <ReadButton
                    size="sm"
                    onClick={() =>
                      setModalConfig({
                        show: true,
                        tipe: 'detail',
                        data: val,
                      })
                    }
                  />

                  <UpdateButton
                    size="sm"
                    onClick={() =>
                      setModalConfig({
                        show: true,
                        tipe: 'ubah',
                        data: val,
                      })
                    }
                  />

                  <DeleteButton
                    size="sm"
                    onClick={() =>
                      setModalConfig({
                        show: true,
                        tipe: 'hapus',
                        data: val,
                      })
                    }
                  />
                </div>
              </Td>
              <Td className={`${tableStyle.tdClassname} text-center`}>
                {val?.kode_proyek ?? '-'}
              </Td>
              <Td className={tableStyle.tdClassname}>
                {val?.nama_proyek ?? '-'}
              </Td>
              <Td className={tableStyle.tdClassname}>
                {val?.nama_customer ?? '-'}
              </Td>
              <Td className={tableStyle.tdClassname}>
                {val?.lokasi_proyek ?? '-'}
              </Td>
              <Td className={tableStyle.tdClassname}>
                {val?.pemilik_proyek ?? '-'}
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <>
      {/* head section */}
      <Row className="mb-2 d-flex justify-content-around align-items-end">
        <Col md={4}>
          <InputSearch
            value={searchConfig.key}
            onChange={onInputSearchChange}
          />
        </Col>

        <Col className="d-flex justify-content-end">
          <CreateButton
            onClick={() =>
              setModalConfig({
                show: true,
                tipe: 'tambah',
                data: {
                  id_proyek: '',
                  kode_proyek: dataAtribut.no_baru,
                  nama_proyek: '',
                  lokasi_proyek: '',
                  pemilik_proyek: '',
                  id_customer: null,
                  id_karyawan: null,
                  ket: '',
                },
              })
            }
          />
        </Col>
      </Row>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <>
          <PageContent />
          {modalConfig.show && <ModalData />}
          <HapusModal />
        </>
      )}
    </>
  );
};

export default Proyek;

import { useRef, useState, useEffect } from 'react';
import { Card, Nav } from 'react-bootstrap';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  ActionButton,
  Alert,
  Approval,
  BackButton,
  DataStatus,
} from 'components';
import { RegistrasiPeluangApi } from 'api';
import { TabInfo, TabGambar, TabFile } from './Section';

const UbahRegistrasiPeluang = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const tabsRef = useRef();
  const { state } = useLocation();
  const [dataInfo, setDataInfo] = useState({});
  const [dataGambar, setDataGambar] = useState([]);
  const [dataFile, setDataFile] = useState([]);
  const [dataBarangJadi, setDataBarangJadi] = useState([]);
  const [listBarangJadi, setListBarangJadi] = useState([]);
  const [dropdown, setDropdown] = useState({
    customer: [],
    peringkat_peluang: [],
    barang_jadi: [],
    kelompok_proyek: [
      {
        value: 'proyek',
        label: 'Proyek',
      },
      {
        value: 'umum',
        label: 'Umum',
      },
      {
        value: 'tender',
        label: 'Tender',
      },
    ],
  });

  const [defaultTabs, setDefaultTabs] = useState('info');
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: '',
    text: '',
  });

  // Konfigurasi komponen tabs
  const tabsConfig = [
    {
      tab: 'info',
      label: 'Informasi Umum',
      component: ({ ...rest }) => <TabInfo {...rest} />,
    },
    {
      tab: 'gambar',
      label: 'Gambar',
      component: ({ ...rest }) => <TabGambar {...rest} action="UPDATE" />,
    },
    {
      tab: 'file',
      label: 'File',
      component: ({ ...rest }) => <TabFile {...rest} />,
    },
  ];

  // Nilai awal pada form
  const formInitialValues = {
    id_peluang: id,
    tgl_peluang: dataInfo?.tgl_peluang ?? '',
    no_peluang: dataInfo?.no_peluang ?? '',
    id_customer: dataInfo?.id_customer ?? '',
    id_proyek: dataInfo?.id_proyek ?? '',
    att: dataInfo?.att ?? '',
    id_peringkat_peluang: dataInfo?.id_peringkat_peluang ?? '',
    tgl_selesai: dataInfo?.tgl_selesai ?? '',
    realisasi_penawaran: dataInfo?.realisasi_penawaran ?? '',
    keterangan_pengiriman: dataInfo?.keterangan_pengiriman ?? '',
    instalasi: dataInfo?.instalasi ?? false,
    lokasi_pengiriman: dataInfo?.lokasi_pengiriman ?? '',
    lain_lain: dataInfo?.lain_lain ?? '',
    schedule_kebutuhan_proyek: dataInfo?.schedule_kebutuhan_proyek ?? '',
    link_referensi: dataInfo?.link_referensi ?? '',
    kelompok_proyek: dataInfo?.kelompok_proyek ?? '',
  };

  // Validasi pada form
  const formValidationSchema = Yup.object().shape({
    tgl_peluang: Yup.date().required('Tanggal Peluang wajib diisi'),
    id_customer: Yup.string().required('Customer wajib diisi'),
    id_proyek: Yup.string().required('Proyek wajib diisi'),
    id_peringkat_peluang: Yup.string().required(
      'Peringkat Peluang wajib diisi'
    ),
    tgl_selesai: Yup.date().required('Tanggal Selesai wajib diisi'),
    realisasi_penawaran: Yup.date().required('Realisasi Penawaran wajib diisi'),
    link_referensi: Yup.string().required('Link Referensi wajib diisi'),
  });

  // Menangani submit form
  const formSubmitHandler = (values, { setSubmitting }) => {
    const { REACT_APP_UPLOAD_FILE_BASE_URL } = process.env;
    const uploadLink = `${REACT_APP_UPLOAD_FILE_BASE_URL}/upload/peluang_multiple`;

    const mergeFile = [
      ...dataGambar.filter((val) => val.data),
      ...dataFile.filter((val) => val.data),
    ];
    const prevGambar = dataGambar
      .filter((val) => !val.data)
      .map((val) => val.link);
    const prevFile = dataFile.filter((val) => !val.data).map((val) => val.link);
    const mergePrevFile = [...prevFile, ...prevGambar]; // Gabungan data dari state gambar dan file yang telah di filter menjadi link untuk disimpan ke database
    const fileData = new FormData();

    mergeFile.map((val, index) => {
      fileData.append(`file_${index}`, val.data);
    });

    RegistrasiPeluangApi.upload(fileData)
      .then((res) => {
        const fileResponse = res.data.data;
        const filePath = Object.values(fileResponse);
        const finalValues = {
          ...values,
          detail: listBarangJadi,
          path_gambar: [...filePath, ...mergePrevFile],
        };
        return RegistrasiPeluangApi.update(finalValues)
          .then(() => {
            history.push('/transaksi/registrasi-peluang', {
              ...state,
              registerAlertConfig: {
                variant: 'primary',
                text: 'Ubah data berhasil!',
              },
            });
          })
          .catch(() => {
            setAlertConfig({
              show: true,
              variant: 'danger',
              text: 'Data gagal disimpan!',
            });
          });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data & file gagal disimpan!',
        });
      })
      .finally(() => {
        setSubmitting(false);
      });

    // Axios.post(uploadLink, fileData, {
    //   headers: {
    //     "Api-Key": "TUdQQmFuZ3NhdFBhbnRla0FzdQ==",
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((res) => {
    //     const fileResponse = res.data.data;
    //     const filePath = Object.values(fileResponse);
    //     const finalValues = {
    //       ...values,
    //       detail: listBarangJadi,
    //       path_gambar: [...filePath, ...mergePrevFile],
    //     };

    //     return RegistrasiPeluangApi.update(finalValues)
    //       .then(() => {
    //         history.push("/transaksi/registrasi-peluang", {
    //           ...state,
    //           registerAlertConfig: {
    //             variant: "primary",
    //             text: "Ubah data berhasil!",
    //           },
    //         });
    //       })
    //       .catch(() => {
    //         setAlertConfig({
    //           show: true,
    //           variant: "danger",
    //           text: "Data gagal disimpan!",
    //         });
    //       });
    //   })
    //   .catch(() => {
    //     setAlertConfig({
    //       show: true,
    //       variant: "danger",
    //       text: "Data & file gagal disimpan!",
    //     });
    //   })
    //   .finally(() => {
    //     setSubmitting(false);
    //   });
  };

  // Menangani perubahan pada tabs
  const onTabsChangeHandler = (e, tabs) => {
    e.preventDefault();
    setDefaultTabs(tabs);
  };

  // Menangani cek type dari file yang dikirim
  const checkTypeAndStoreFile = (data) => {
    let gambar = [];
    let file = [];

    data.map((val) => {
      const map = {
        link: val.path_gambar,
        id: val.id_peluang_gambar,
        nama: '',
        data: null,
      };

      const generalFileType = map.link.split('.').pop();
      const fileName = map.link.split('/').pop();

      // Check apakah data adalah gambar
      if (['jpg', 'png', 'gif', 'jpeg'].includes(generalFileType)) {
        return gambar.push({ ...map, nama: fileName });
      }

      return file.push({ ...map, nama: fileName });
    });

    setDataGambar(gambar);
    setDataFile(file);
  };

  // Fetch data pada saat page pertama kali dibuka
  const fetchInitialData = async () => {
    let id_customer;

    setFetchingStatus({
      loading: true,
      success: false,
    });

    return await Axios.all([
      RegistrasiPeluangApi.getSingle(id),
      RegistrasiPeluangApi.getCustomer(),
      RegistrasiPeluangApi.getPeringkatPeluang(),
      RegistrasiPeluangApi.getBarangJadi(),
    ])
      .then(
        Axios.spread((data, customer, peringkat, barang) => {
          const dataInfo = data?.data?.data ?? {};
          id_customer = dataInfo?.id_customer ?? '';

          const dataCustomer = customer?.data?.data?.map((val) =>
            Object({ ...val, value: val.id_customer, label: val.nama_customer })
          );
          const dataPeringkat = peringkat?.data?.data?.map((val) =>
            Object({
              ...val,
              value: val.id_peringkat_peluang,
              label: val.nama_peringkat_peluang,
            })
          );
          const dataBarangJadi = barang?.data?.data?.map((val) =>
            Object({
              ...val,
              value: val.id_item_buaso,
              label: val.nama_item,
            })
          );

          setDropdown((prevState) =>
            Object({
              ...prevState,
              customer: dataCustomer ?? [],
              peringkat: dataPeringkat ?? [],
            })
          );

          checkTypeAndStoreFile(dataInfo?.gambar ?? []);
          setDataInfo(dataInfo);
          setDataBarangJadi(dataBarangJadi ?? []);
          setListBarangJadi(
            dataInfo?.detail?.map((val) =>
              Object({
                ...val,
                nama_satuan: val.nama_satuan_jual,
              })
            ) ?? []
          );

          setFetchingStatus({
            loading: false,
            success: true,
          });
        })
      )
      .then(() => {
        // Fetch data proyek berdasarkan id_customer
        if (id_customer) {
          RegistrasiPeluangApi.getProyek(id_customer).then((res) => {
            const dataProyek = res?.data?.data?.map((val) =>
              Object({ ...val, value: val.id_proyek, label: val.nama_proyek })
            );
            setDropdown((prevState) =>
              Object({ ...prevState, proyek: dataProyek ?? [] })
            );
          });
        }
      })
      .catch(() => {
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  useEffect(async () => {
    setNavbarTitle('Registrasi Peluang');
    await fetchInitialData();
    return () => {};
  }, []);

  return (
    <>
      {/* Title */}
      <div className="d-flex justify-content-between align-items-center pb-2">
        <h6 className="p-1">
          <b>Ubah Data Peluang</b>
        </h6>
        <BackButton
          size="sm"
          onClick={() =>
            history.push('/transaksi/registrasi-peluang', { ...state })
          }
        />
      </div>

      {/* Alert */}
      <Alert
        showCloseButton
        show={alertConfig.show}
        text={alertConfig.text}
        variant={alertConfig.variant}
        onClose={() =>
          setAlertConfig({
            show: false,
            variant: '',
            text: '',
          })
        }
      />

      <Card>
        <Card.Header>
          {/* Tab Header */}
          <Nav variant="tabs" defaultActiveKey={`#${defaultTabs}`}>
            {tabsConfig.map((val, index) => (
              <Nav.Item key={index}>
                <Nav.Link
                  ref={tabsRef}
                  href={`#${val.tab}`}
                  onClick={(e) => onTabsChangeHandler(e, val.tab)}
                >
                  {val.label}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {fetchingStatus.loading ? (
            <DataStatus loading text="Memuat data . . ." />
          ) : !fetchingStatus.success ? (
            <DataStatus text="Data gagal dimuat!" />
          ) : (
            <>
              <Formik
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={formSubmitHandler}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    {/* Tab Body */}
                    {tabsConfig.map(
                      ({ tab, component: Component }, index) =>
                        tab === defaultTabs && (
                          <Component
                            key={index}
                            type="UPDATE"
                            formik={formik}
                            dataGambar={dataGambar}
                            dataFile={dataFile}
                            dropdown={dropdown}
                            listBarangJadi={listBarangJadi}
                            dataBarangJadi={dataBarangJadi}
                            alertConfig={alertConfig}
                            setListBarangJadi={setListBarangJadi}
                            setDataBarangJadi={setDataBarangJadi}
                            setDropdown={setDropdown}
                            setDataGambar={setDataGambar}
                            setDataFile={setDataFile}
                            setAlertConfig={setAlertConfig}
                          />
                        )
                    )}
                    <hr />

                    {/* Submit Button */}
                    <div className="text-right">
                      <ActionButton
                        type="submit"
                        variant="success"
                        text="Ubah Peluang"
                        loading={formik.isSubmitting}
                      />
                    </div>
                  </form>
                )}
              </Formik>
              <Approval data={dataInfo} />
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default UbahRegistrasiPeluang;

import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { IoDocumentTextOutline } from 'react-icons/io5';
import FileSaver from 'file-saver';
import {
  CRUDLayout,
  Tr,
  Th,
  Td,
  Alert,
  DataStatus,
  TdFixed,
  ThFixed,
  THead,
  TBody,
  DatePicker,
  ActionButton,
} from 'components';
import { DateConvert, RupiahConvert } from 'utilities';
import { LaporanGeneralMarketingApi } from 'api';

const LaporanGeneralMarketing = ({ setNavbarTitle }) => {
  const TODAY = new Date().toISOString().slice(0, 10);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [form, setForm] = useState({
    tanggal_start: TODAY,
    tanggal_end: TODAY,
  });
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
    show: false,
  });

  const mapDataFromServer = (data) => {
    const uniqNumber = []; // variable untuk mengecek nomor rap unik
    const subTotalMap = []; // variable untuk menampung harga barang jadi
    const subTotalPrelim = []; // variable untuk menampung harga prelim
    const dataBarangJadiRAP = []; // variable menampung data item

    //maping data atribut dari rap
    const mappedData = data.map((val) => {
      //push data ke dataBarangJadiRAP
      val.barang_jadi_rap.map((res) => dataBarangJadiRAP.push(res));

      //push data ke subTotalMap
      val.barang_jadi.map((res) => {
        const subTotal = parseFloat(res.qty_rab) * parseInt(res.rounded);

        subTotalMap.push({
          id_rab: res.id_rab,
          total: subTotal,
        });
      });

      // push data ke subTotalPrelim
      val.detail_prelim.map((res) => {
        const subTotal = parseFloat(res.qty_analisa) * parseInt(res.unit_price);

        subTotalPrelim.push({
          id_rab: res.id_rab,
          total_prelim: subTotal,
        });
      });

      return {
        tgl_peluang: val.tgl_peluang,
        no_peluang: val.no_peluang,
        nama_proyek: val.nama_proyek,
        tgl_penawaran: val.tgl_penawaran,
        no_penawaran: val.no_penawaran,
        tgl_spk: val.tgl_spk,
        no_spk: val.no_spk,
        no_rap: val.no_rap,
        id_rap: val.id_rap,
        id_rab: val.id_rab,
        diskon: val.diskon,
        ppn: val.ppn,
      };
    });

    //menjumlahkan data barang jadi rap dengan id rab sama
    const sumSameIDBarangJadi = Array.from(
      subTotalMap.reduce(
        (m, { id_rab, total }) => m.set(id_rab, (m.get(id_rab) || 0) + total),
        new Map()
      ),
      ([id_rab, total]) => ({ id_rab, total })
    );

    //menjumlahkan data prelim dengan id rab sama
    const sumSameIDPrelim = Array.from(
      subTotalPrelim.reduce(
        (m, { id_rab, total_prelim }) =>
          m.set(id_rab, (m.get(id_rab) || 0) + total_prelim),
        new Map()
      ),
      ([id_rab, total_prelim]) => ({ id_rab, total_prelim })
    );

    // menggabungkan data rap dengan total barang jadi rap dan total prelim
    const mergeDataWithTotal = mappedData.map((val) => ({
      ...sumSameIDBarangJadi.find((item) => item.id_rab === val.id_rab && item),
      ...sumSameIDPrelim.find((item) => item.id_rab === val.id_rab && item),
      ...val,
    }));

    // menggabungkan data seluruh rap dan data item job order
    const mergeFinalData = dataBarangJadiRAP.map((val) => ({
      ...mergeDataWithTotal.find((item) => item.id_rap === val.id_rap && item),
      ...val,
    }));

    // final maping dan mengecek norap sama
    const mappedFinalData = mergeFinalData.map((item) => {
      if (!uniqNumber.includes(item.no_rap)) {
        uniqNumber.push(item.no_rap);
        return item;
      }
      return Object({
        nama_item: item.nama_item,
        no_job_order: item.no_job_order,
        tgl_job_order: item.tgl_job_order,
        tgl_mulai_jo: item.tgl_mulai_jo,
        tgl_selesai_jo: item.tgl_selesai_jo,
        qty_rap: item.qty_rap,
        status_job_order: item.status_job_order,
      });
    });

    return mappedFinalData;
  };

  const GetGrandTotalAll = () => {};

  const handleFetchReport = () => {
    setLoading(true);

    LaporanGeneralMarketingApi.getPage(form)
      .then(({ data }) => setData(mapDataFromServer(data.data ?? [])))
      .catch(() =>
        setAlertConfig({
          variant: 'danger',
          text: 'Tidak dapat memuat data!',
          show: true,
        })
      )
      .finally(() => setLoading(false));
  };

  const handleButtonExport = (tipe) => {
    const query = { ...form, tipe };

    LaporanGeneralMarketingApi.getGeneralMarketingExport(query).then(
      ({ data }) => {
        const ext = tipe === 'excel' ? 'xlsx' : 'pdf';
        FileSaver.saveAs(data.data, `Laporan_SPK.${ext}`);
      }
    );
  };

  useEffect(() => {
    setNavbarTitle('Laporan General Marketing');
  }, [setNavbarTitle]);

  const ExportButton = ({
    variant = 'outline-success',
    text = 'Export EXCEL',
    onClick,
  }) => {
    return (
      <Button variant={variant} onClick={onClick} className="mx-1 mb-1">
        <IoDocumentTextOutline size={18} />
        <span className="ml-2 text-uppercase">{text}</span>
      </Button>
    );
  };

  const TableSection = () => {
    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>No. Peluang</ThFixed>
              <ThFixed>Tgl. Peluang</ThFixed>
              <Th>Proyek</Th>
              <ThFixed>No. Penawaran</ThFixed>
              <ThFixed>Tgl. Penawaran</ThFixed>
              <ThFixed>No. SPK</ThFixed>
              <ThFixed>Tgl. SPK</ThFixed>
              <Th style={{ minWidth: 150 }}>Total SPK Setelah Pajak</Th>
              <ThFixed>No. RAP</ThFixed>
              <ThFixed>No. Job Order</ThFixed>
              <ThFixed>Tgl. Job Order</ThFixed>
              <ThFixed>Tgl. Mulai Job Order</ThFixed>
              <ThFixed style={{ minWidth: 100 }}>
                Tgl. Selesai Job Order
              </ThFixed>
              <Th style={{ minWidth: 150 }}>Description (Barang Jadi)</Th>
              <Th>Qty.</Th>
              <ThFixed style={{ minWidth: 100 }}>Status Job Order</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((item, index) => {
              const subTotal =
                parseInt(item.total ?? 0) + parseInt(item.total_prelim ?? 0);
              const totalDiskon = subTotal - (item.diskon / 100) * subTotal;
              const totalPPN = totalDiskon + (item.ppn / 100) * totalDiskon;
              return (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <Td className="text-center">
                    {item.no_peluang ? item.no_peluang : '-'}
                  </Td>
                  <Td className="text-center">
                    {item.no_peluang
                      ? item.tgl_peluang
                        ? DateConvert(new Date(item.tgl_peluang)).custom
                        : '-'
                      : '-'}
                  </Td>
                  <Td>{item.no_peluang ? item.nama_proyek : '-'}</Td>
                  <Td className="text-center">
                    {item.no_peluang ? item.no_penawaran : '-'}
                  </Td>
                  <Td className="text-center">
                    {item.no_peluang
                      ? item.tgl_penawaran
                        ? DateConvert(new Date(item.tgl_penawaran)).custom
                        : '-'
                      : '-'}
                  </Td>
                  <Td className="text-center">
                    {item.no_spk ? item.no_spk : '-'}
                  </Td>
                  <Td className="text-center">
                    {item.tgl_spk
                      ? item.tgl_spk
                        ? DateConvert(new Date(item.tgl_spk)).custom
                        : '-'
                      : '-'}
                  </Td>
                  <Td>
                    {item.total
                      ? RupiahConvert(parseInt(totalPPN).toString()).detail
                      : '-'}
                  </Td>
                  <Td>{item.no_rap ?? '-'}</Td>
                  <Td>{item.no_job_order ? item.no_job_order : '-'}</Td>
                  <Td>
                    {item.tgl_job_order
                      ? DateConvert(new Date(item.tgl_job_order)).custom
                      : '-'}
                  </Td>
                  <Td>
                    {item.tgl_mulai_jo
                      ? DateConvert(new Date(item.tgl_mulai_jo)).custom
                      : '-'}
                  </Td>
                  <Td>
                    {item.tgl_selesai_jo
                      ? DateConvert(new Date(item.tgl_selesai_jo)).custom
                      : '-'}
                  </Td>
                  <Td>{item.nama_item ? item.nama_item : '-'}</Td>
                  <Td className="text-center text-uppercase">
                    {item.qty_rap ? item.qty_rap ?? '-' : ''}
                  </Td>
                  <Td className="text-center">
                    {item.status_job_order
                      ? String(item.status_job_order).toUpperCase()
                      : '-'}
                  </Td>
                </Tr>
              );
            })}
            {/* <Tr>
              <Td colSpan={15} className="text-right font-weight-bold text-uppercase">
                Total
              </Td>
              <Td colSpan={3} className="text-right font-weight-bold">
                {RupiahConvert(String(parseInt(GetGrandTotal() ?? 0))).detail}
              </Td>
            </Tr> */}
          </TBody>
        </CRUDLayout.Table>
      </>
    );
  };

  return (
    <CRUDLayout>
      <Row className="d-flex flex-wrap px-1 py-2 mx-1 mt-1 mb-3 bg-white rounded-lg">
        {/* Periode */}
        <Col className="d-flex flex-row justify-content-start align-items-center">
          <span className="mr-2 mt-1 text-">Periode:</span>
          <DatePicker
            label="Tgl. Mulai"
            name="tanggal_start"
            selected={form.tanggal_start ? new Date(form.tanggal_start) : ''}
            onChange={(val) => {
              const value = val.toISOString().slice(0, 10);
              setForm((prev) => ({ ...prev, tanggal_start: value }));
            }}
          />
          <span className="mx-2 mt-1">s.d.</span>
          <DatePicker
            label="Tgl. Selesai"
            name="tanggal_end"
            selected={form.tanggal_end ? new Date(form.tanggal_end) : ''}
            onChange={(val) => {
              const value = val.toISOString().slice(0, 10);
              setForm((prev) => ({ ...prev, tanggal_end: value }));
            }}
          />
          <ActionButton
            className="ml-2 mt-3 p-2"
            size="sm"
            text="LIHAT DATA"
            onClick={handleFetchReport}
          />
        </Col>
      </Row>

      <Alert
        showCloseButton={true}
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig((prev) => ({ ...prev, show: false }))}
      />

      {!isLoading && data.length > 0 && (
        <Row className="d-flex justify-content-end align-items-center mx-1 mt-4 mb-1">
          <ExportButton
            variant="outline-danger"
            text="Export PDF"
            onClick={() => handleButtonExport('pdf')}
          />
          <ExportButton onClick={() => handleButtonExport('excel')} />
        </Row>
      )}

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat data ..." />
      ) : (
        <TableSection />
      )}
    </CRUDLayout>
  );
};

export default LaporanGeneralMarketing;

import { useState, useEffect } from 'react';
import Axios from 'axios';
import { Row } from 'react-bootstrap';
import { debounce } from 'lodash';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  FilterButton,
  Pagination,
  InputSearch,
  Table,
  Th,
  Td,
  ThFixed,
} from 'components';
import { DateConvert, PageNumber, RupiahConvert } from 'utilities';
import { LaporanHargaKubikasiKayuApi } from 'api';
import { ModalFilterHargaKubikasiKayu } from './components';

const LaporanHargaKubikasiKayu = ({ setNavbarTitle }) => {
  const title = 'Laporan Harga Kubikasi Kayu';
  const [isFetchingReport, setIsFetchingReport] = useState(false);
  const [data, setData] = useState([]);
  const [dropdown, setDropdown] = useState({ jenis_kayu: [], part_kayu: [] });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: 'detail',
    data: {},
  });
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: false,
      jenis_kayu: undefined,
      part_kayu: undefined,
    },
    pagination: {
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    },
    search: { key: '' },
  });

  // FETCH DROPDOWN FILTER
  const fetchDropdown = () => {
    Axios.all([
      LaporanHargaKubikasiKayuApi.dropdown({ kayu: 'jenis_kayu' }),
      LaporanHargaKubikasiKayuApi.dropdown({ kayu: 'part_kayu' }),
    ])
      .then(
        Axios.spread((resJenisKy, resPartKy) => {
          const jenis_kayu =
            resJenisKy?.data?.data?.map((val) => ({
              value: val.id_jenis_kayu,
              label: val.nama_jenis_kayu,
            })) ?? [];
          const part_kayu =
            resPartKy?.data?.data?.map((val) => ({
              value: val.id_part_kayu,
              label: val.nama_part_kayu,
            })) ?? [];

          setDropdown({ jenis_kayu, part_kayu });
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      );
  };

  // FETCH DATA REPORT SESUAI FILTER
  const getReportData = () => {
    setIsFetchingReport(true);

    LaporanHargaKubikasiKayuApi.getPage({
      q: dataFilter?.search?.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      jenis_kayu: dataFilter?.filter?.jenis_kayu,
      part_kayu: dataFilter?.filter?.part_kayu,
    })
      .then(({ data }) => {
        console.log(data);
        setData(data?.data ?? []);
        setDataFilter((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            dataCount: data?.data_count,
            totalPage: data?.total_page,
          },
        }));
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => setIsFetchingReport(false));
  };

  useEffect(() => {
    setNavbarTitle(title);
    fetchDropdown();

    return () => {
      setDropdown({ jenis_kayu: [], part_kayu: [] });
      setData([]);
    };
  }, [setNavbarTitle]);

  // fetch report on dataFilter change
  useEffect(() => {
    getReportData();
  }, [
    dataFilter?.search?.key,
    dataFilter?.filter?.active,
    dataFilter?.filter?.jenis_kayu,
    dataFilter?.filter?.part_kayu,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
  ]);

  const PageContent = () => {
    return (
      <>
        <div className="font-weight-bold mb-2">
          List Data Harga Kubikasi Kayu
        </div>
        <Table>
          <thead>
            <tr>
              <ThFixed className="p-2">No.</ThFixed>
              <Th width="200px">Jenis Kayu</Th>
              <Th width="200px">Part Kayu</Th>
              <ThFixed>T</ThFixed>
              <ThFixed>W</ThFixed>
              <ThFixed>L</ThFixed>
              <Th width="90px">Harga Rata-rata/M3</Th>
              <ThFixed>Tgl. Update Terakhir</ThFixed>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((val, index) => (
                <tr key={index}>
                  <Td className="align-middle text-nowrap text-center">
                    {PageNumber(
                      dataFilter?.pagination?.page,
                      dataFilter?.pagination?.dataLength,
                      index
                    )}
                  </Td>
                  <Td className="align-middle text-nowrap">
                    {val.nama_jenis_kayu ?? ''}
                  </Td>
                  <Td className="align-middle text-nowrap">
                    {val.nama_part_kayu ?? ''}
                  </Td>
                  <Td className="align-middle text-right">
                    {val.t ? parseFloat(val.t) : '-'}
                  </Td>
                  <Td className="align-middle text-right">
                    {val.w ? parseFloat(val.w) : '-'}
                  </Td>
                  <Td className="align-middle text-right">
                    {val.l ? parseFloat(val.l) : '-'}
                  </Td>
                  <Td className="align-middle text-right">
                    {
                      RupiahConvert(
                        String(parseInt(val.harga_rata_rata_per_kubik ?? 0))
                      ).detail
                    }
                  </Td>
                  <Td className="align-middle text-nowrap">
                    {val.updated_at
                      ? DateConvert(new Date(val.updated_at)).custom
                      : '-'}
                  </Td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={10}
                  className="align-middle text-nowrap text-center"
                >
                  Tidak ada data
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {/* Pagination */}
        {data?.length > 0 && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter((prev) => ({
                ...prev,
                pagination: { ...prev.pagination, page: selected + 1 },
              }))
            }
            onDataLengthChange={(e) =>
              setDataFilter((prev) => ({
                ...prev,
                pagination: {
                  ...prev.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              }))
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <Row className="d-flex ml-1 mb-3">
        <div className="mr-2 mb-1">
          <InputSearch
            placeholder="Cari barang jadi"
            onChange={debounce(
              (e) =>
                setDataFilter((prev) => ({
                  ...prev,
                  pagination: {
                    ...prev.pagination,
                    page: 1,
                  },
                  search: { key: e.target.value },
                })),
              1500
            )}
            onSubmit={(e) => e.preventDefault()}
          />
        </div>

        <FilterButton
          active={dataFilter?.filter?.active}
          onClick={() => setModalConfig({ show: true, type: 'filter' })}
          className="mr-2"
        />
      </Row>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {/* content */}
      {isFetchingReport ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <div style={{ minHeight: '45vh' }}>
          <PageContent />
        </div>
      )}

      {/* modal filter */}
      {modalConfig.show && modalConfig.type === 'filter' && (
        <ModalFilterHargaKubikasiKayu
          dropdown={dropdown}
          setDropdown={setDropdown}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default LaporanHargaKubikasiKayu;

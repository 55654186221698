import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Axios from 'axios';
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Card } from 'react-bootstrap';
import {
  Alert,
  DataStatus,
  ActionButton,
  BackButton,
  Input,
  TextEditor,
  DatePicker,
  Approval,
} from 'components';
import { RupiahConvert, DateConvert, TableNumber } from 'utilities';
import { PenawaranApi } from 'api';
import { TablePenawaran, TableLC, TableMP } from '../TablePenawaran';
import PeluangPenawaran from '../PeluangPenawaran';

const UbahPenawaran = ({ setNavbarTitle }) => {
  const { id_penawaran } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [isPageLoading, setIsPageLoading] = useState(true);

  const [dataPenawaran, setDataPenawaran] = useState({});
  const [dataBarangJadi, setDataBarangJadi] = useState([]);
  const [dataPrelim, setDataPrelim] = useState([]);
  const [dataListLC, setDataListLC] = useState([]);
  const [dataListMP, setDataListMP] = useState([]);

  const [totalPenawaran, setTotalPenawaran] = useState(0);
  const [totalLC, setTotalLC] = useState(0);
  const [totalMP, setTotalMP] = useState(0);

  // STATE TEXT EDITOR
  const [textEditorState, setTextEditorState] = useState(() => {
    return EditorState.createEmpty();
  });
  const [textEditorStatePenutup, setTextEditorStatePenutup] = useState(() => {
    return EditorState.createEmpty();
  });
  const [modalPeluangConfig, setModalPeluangConfig] = useState({
    show: false,
    no_peluang: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    //GET SINGLE DETAIL RAE
    Axios.all([PenawaranApi.getSingle({ id_penawaran: id_penawaran })])
      .then(
        Axios.spread((data) => {
          const dataInfo = data?.data?.data ?? {};
          const filterPrelim = dataInfo?.detail_prelim?.map((val, index) => ({
            id_kelompok: val.id_kelompok,
            id_item: val.id_item_prelim,
            kode_item: val.kode_item_prelim,
            nama_item: val.nama_item_prelim,
            qty_item: val.qty_analisa,
            id_unit: val.id_satuan_prelim,
            nama_unit: val.nama_satuan_prelim,
            harga: parseFloat(val.unit_price ?? 0),
            qty_durasi: parseFloat(val.qty_durasi ?? 0),
            konstanta: parseFloat(val.konstanta ?? 0),
            order: index + 1,
          }));

          setDataPenawaran(dataInfo);
          setDataBarangJadi(dataInfo.detail_barang_jadi ?? []);
          setDataPrelim(filterPrelim ?? []);
        })
      )
      .catch(() => {
        // setIsFetchingFailed(true)
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const updateEditorState = (value, setFieldValue, editor) => {
    editor === 'teks_pembuka'
      ? setTextEditorState(value)
      : setTextEditorStatePenutup(value);
    console.log(editor);
    const convertData = convertToRaw(value.getCurrentContent());

    setFieldValue(editor, draftToHtml(convertData));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const InfoSection = () => {
    const InfoItem = ({ title, value, link, onClick }) => (
      <tr onClick={onClick}>
        <td>{title}</td>
        <td className={`pl-4 pr-2`}>:</td>
        <td
          className={link ? 'text-primary' : ''}
          style={{ cursor: link ? 'pointer' : 'default' }}
        >
          {value}
        </td>
      </tr>
    );

    return (
      <Row>
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Tgl. RAB"
                value={
                  dataPenawaran?.tgl_rab
                    ? DateConvert(new Date(dataPenawaran.tgl_rab)).detail
                    : '-'
                }
              />
              <InfoItem title="No. RAB" value={dataPenawaran?.no_rab ?? '-'} />
              <InfoItem
                link
                title="No. Peluang"
                value={dataPenawaran?.no_peluang ?? '-'}
                onClick={() =>
                  setModalPeluangConfig({
                    show: true,
                    no_peluang: dataPenawaran?.no_peluang ?? '',
                  })
                }
              />
              <InfoItem
                title="Proyek"
                value={dataPenawaran?.nama_proyek ?? '-'}
              />
            </tbody>
          </table>
        </Col>
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Customer"
                value={dataPenawaran?.nama_customer ?? '-'}
              />
              <InfoItem title="ATT" value={dataPenawaran?.att ?? '-'} />
              <InfoItem
                title="Tgl. Realisasi Penawaran"
                value={
                  dataPenawaran?.tgl_selesai
                    ? DateConvert(new Date(dataPenawaran.tgl_selesai)).detail
                    : '-'
                }
              />
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const FormCard = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Penawaran</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataPenawaran.stakeholder.map(
              (val, index) =>
                index !== 0 &&
                val.status_approval !== 'PEN' && (
                  <Col sm>
                    <InfoItem
                      title1={
                        val.status_approval === 'VER' ||
                        val.status_approval === 'REV'
                          ? `Pemeriksa ${
                              val.approval_level !== '0'
                                ? val.approval_level
                                : ''
                            }`
                          : val.status_approval === 'APP'
                          ? 'Pengesah'
                          : 'Di Tolak Oleh'
                      }
                      value1={val.nama_karyawan ?? '-'}
                      title2="Catatan"
                      value2={val.catatan ?? '-'}
                    />
                  </Col>
                )
            )}
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const GrandTotalSection = ({ totalRAB, totalLC, totalMP }) => {
    const hitungGrandTotal = () => {
      let grandTotal = totalRAB + totalLC + totalMP;
      return RupiahConvert(String(grandTotal)).detail;
    };

    useEffect(() => {
      hitungGrandTotal();
    }, [totalRAB, totalLC, totalMP]);

    return (
      <div className="p-2 bg-light text-right border">
        <b className="text-nowrap pr-3" style={{ fontSize: '14px' }}>
          Grand Total : {hitungGrandTotal()}
        </b>
      </div>
    );
  };

  useEffect(() => {
    setTextEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(dataPenawaran?.teks_pembuka ?? '<p></p>')
        )
      )
    );
    setTextEditorStatePenutup(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(dataPenawaran?.teks_penutup ?? '<p></p>')
        )
      )
    );
  }, [dataPenawaran]);

  useEffect(() => {
    setNavbarTitle('Penawaran');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  // INITIAL VALUES FORM TAMBAH DATA TIPE BARANG JADI
  const formInitialValues = {
    id_penawaran: id_penawaran,
    id_rab: dataPenawaran.id_rab,
    tgl_penawaran: dataPenawaran.tgl_penawaran,
    no_penawaran: dataPenawaran.no_penawaran,
    ppn: parseInt(dataPenawaran.ppn ?? 0),
    diskon: parseFloat(dataPenawaran.diskon ?? 0).toPrecision(),
    teks_pembuka: dataPenawaran.teks_pembuka,
    teks_penutup: dataPenawaran.teks_penutup,
  };

  // FORMIK VALIDATION SCHEMA TIPE BARANG JADI DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    tgl_penawaran: Yup.date().required('Tanggal Penawaran wajib diisi'),
    ppn: Yup.number().required('PPN wajib diisi'),
    teks_pembuka: Yup.string(),
    teks_penutup: Yup.string(),
  });

  // KIRIM UPDATE DATA KE SERVER
  const formSubmitHandler = (values) => {
    PenawaranApi.save(values)
      .then((res) => {
        history.push('/transaksi/penawaran', {
          alert: {
            show: true,
            variant: 'primary',
            text: 'Ubah data berhasil!',
          },
        });
      })
      .catch((err) => {
        // ERROR ALERT
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: `Ubah data gagal! (${err.response.data.message})`,
        });
      });
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Ubah Penawaran</b>
          <div className="d-flex align-items-center">
            {!isPageLoading && (
              <ActionButton
                variant="success"
                text="Ubah RAB"
                className="mb-1 mr-2"
                onClick={() =>
                  history.push({
                    pathname:
                      '/transaksi/rencana-anggaran-biaya/ubah/' +
                      dataPenawaran?.id_rab,
                    state: { nonBaseline: true },
                  })
                }
              />
            )}
            <BackButton
              onClick={() =>
                history.push('/transaksi/penawaran', { ...location?.state })
              }
            />
          </div>
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              <InfoSection />
              <hr />
            </>
          )}

          <Formik
            enableReinitialize={true}
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                {!isPageLoading && (
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <DatePicker
                        portalId="root-portal"
                        label="Tgl. Penawaran"
                        name="tgl_penawaran"
                        selected={
                          values.tgl_penawaran
                            ? new Date(values.tgl_penawaran)
                            : ''
                        }
                        onChange={(val) => {
                          const value = val.toISOString().slice(0, 10);
                          setFieldValue('tgl_penawaran', value);
                        }}
                        error={
                          errors.tgl_penawaran && touched.tgl_penawaran && true
                        }
                        errorText={errors.tgl_penawaran}
                      />
                    </div>

                    <div className="col-md-6">
                      <Input
                        label="No. Penawaran"
                        type="text"
                        name="no_penawaran"
                        value={values.no_penawaran}
                        readOnly={true}
                      />
                    </div>
                  </div>
                )}

                {!isPageLoading && (
                  <div className="row mt-2">
                    <div className="col">
                      <TextEditor
                        label="Teks Pembuka"
                        placeholder="Tuliskan teks pembuka"
                        editorState={textEditorState}
                        onEditorStateChange={(val) => {
                          updateEditorState(val, setFieldValue, 'teks_pembuka');
                        }}
                        error={
                          errors.teks_pembuka && touched.teks_pembuka && true
                        }
                        errorText={
                          errors.teks_pembuka &&
                          touched.teks_pembuka &&
                          errors.teks_pembuka
                        }
                      />
                    </div>
                  </div>
                )}

                {!isPageLoading && (
                  <>
                    <TablePenawaran
                      ppn={values.ppn}
                      diskon={values.diskon}
                      setFieldValue={setFieldValue}
                      setTotalPenawaran={setTotalPenawaran}
                      totalPenawaran={totalPenawaran}
                      dataBarangJadi={dataBarangJadi}
                      setDataBarangJadi={setDataBarangJadi}
                      dataPrelim={dataPrelim}
                    />
                    <GrandTotalSection
                      totalRAB={totalPenawaran}
                      totalLC={totalLC}
                      totalMP={totalMP}
                    />
                    <div className="row mt-2">
                      <div className="col">
                        <TextEditor
                          label="Teks Penutup"
                          placeholder="Tuliskan teks penutup"
                          editorState={textEditorStatePenutup}
                          onEditorStateChange={(val) => {
                            updateEditorState(
                              val,
                              setFieldValue,
                              'teks_penutup'
                            );
                          }}
                          error={
                            errors.teks_penutup && touched.teks_penutup && true
                          }
                          errorText={
                            errors.teks_penutup &&
                            touched.teks_penutup &&
                            errors.teks_penutup
                          }
                        />
                      </div>
                    </div>
                    {/* <FormCard /> */}
                    <Approval data={dataPenawaran} />
                    <div className="text-right mt-3">
                      <ActionButton
                        type="submit"
                        className="btn btn-success text-white px-4"
                        size="md"
                        text="Ubah Penawaran"
                        loading={isSubmitting}
                      />
                    </div>
                  </>
                )}
              </form>
            )}
          </Formik>
        </Card.Body>
      </Card>

      {/* Modal Peluang */}
      {modalPeluangConfig.show && (
        <PeluangPenawaran
          type="UBAH"
          modalPeluangConfig={modalPeluangConfig}
          setModalPeluangConfig={setModalPeluangConfig}
        />
      )}
    </>
  );
};

export default UbahPenawaran;

import React, { useState, useEffect } from 'react';
import { Modal, Card, Nav } from 'react-bootstrap';
import { DataStatus } from 'components';
import { RencanaAnggaranBiayaApi } from 'api';
import { TabInfo, TabGambar, TabFile } from './Tabs';

const PeluangRAB = ({
  type: TYPE,
  modalPeluangConfig,
  setModalPeluangConfig,
}) => {
  const [dataInfoPeluang, setDataInfoPeluang] = useState({});
  const [dataGambar, setDataGambar] = useState([]);
  const [dataFile, setDataFile] = useState([]);
  const [dataTablePeluang, setDataTablePeluang] = useState([]);
  const [fetchPeluangStatus, setFetchPeluangStatus] = useState({
    loading: true,
    success: false,
  });
  const [defaultTabs, setDefaultTabs] = useState('info');
  const tabsConfig = [
    {
      tab: 'info',
      label: 'Informasi Umum',
      component: ({ ...rest }) => <TabInfo {...rest} />,
    },
    {
      tab: 'gambar',
      label: 'Gambar',
      component: ({ ...rest }) => <TabGambar {...rest} />,
    },
    {
      tab: 'file',
      label: 'File',
      component: ({ ...rest }) => <TabFile {...rest} />,
    },
  ];

  const onTabsChangeHandler = (e, tabs) => {
    e.preventDefault();
    setDefaultTabs(tabs);
  };

  const checkTypeAndStoreFile = (data) => {
    let gambar = [];
    let file = [];

    data.map((val) => {
      const map = {
        link: val.path_gambar,
        id: val.id_peluang_gambar,
        nama: '',
        data: null,
      };

      const generalFileType = map.link.split('.').pop();
      const fileName = map.link.split('/').pop();

      // Check apakah data adalah gambar
      if (['jpg', 'png', 'gif', 'jpeg'].includes(generalFileType)) {
        return gambar.push({ ...map, nama: fileName });
      }

      return file.push({ ...map, nama: fileName });
    });

    setDataGambar(gambar);
    setDataFile(file);
  };

  const getDataPeluang = (no_peluang) => {
    setFetchPeluangStatus({
      loading: true,
      success: false,
    });

    RencanaAnggaranBiayaApi.getDetailPeluangRAB({ no_peluang })
      .then((res) => {
        const dataInfo = res.data.data ?? {};
        const dataTable = res.data.data.detail ?? {};

        checkTypeAndStoreFile(dataInfo?.gambar ?? []);
        setDataInfoPeluang(dataInfo);
        setDataTablePeluang(dataTable);
        setFetchPeluangStatus({
          loading: false,
          success: true,
        });
      })
      .catch(() => {
        setFetchPeluangStatus({
          loading: false,
          success: false,
        });
      });
  };

  useEffect(() => {
    if (modalPeluangConfig.no_peluang) {
      getDataPeluang(modalPeluangConfig.no_peluang);
    } else {
      setFetchPeluangStatus({
        loading: false,
        success: false,
      });
    }
  }, []);

  if (fetchPeluangStatus.loading || !fetchPeluangStatus.success) {
    return (
      <Modal
        size="xl"
        show={modalPeluangConfig.show}
        onHide={() =>
          setModalPeluangConfig({
            show: false,
            no_peluang: '',
          })
        }
      >
        <Modal.Header closeButton>
          <b>Detail Peluang</b>
        </Modal.Header>
        <Modal.Body>
          {fetchPeluangStatus.loading ? (
            <DataStatus loading text="Memuat data . . ." />
          ) : (
            <DataStatus text="Data gagal dimuat" />
          )}
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal
      size="xl"
      show={modalPeluangConfig.show}
      onHide={() =>
        setModalPeluangConfig({
          ...modalPeluangConfig,
          show: false,
        })
      }
    >
      <Modal.Header closeButton>
        <b>Detail Peluang</b>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Header>
            {/* Tab Header */}
            <Nav variant="tabs" defaultActiveKey={`#${defaultTabs}`}>
              {tabsConfig.map((val, index) => (
                <Nav.Item key={index}>
                  <Nav.Link
                    // ref={tabsRef}
                    href={`#${val.tab}`}
                    onClick={(e) => onTabsChangeHandler(e, val.tab)}
                  >
                    {val.label}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>
            {fetchPeluangStatus.loading ? (
              <DataStatus loading text="Memuat data . . ." />
            ) : !fetchPeluangStatus.success ? (
              <DataStatus text="Data gagal dimuat!" />
            ) : (
              <>
                {tabsConfig.map(
                  ({ tab, component: Component }, index) =>
                    tab === defaultTabs && (
                      <Component
                        key={index}
                        type={TYPE}
                        dataInfoPeluang={dataInfoPeluang}
                        dataGambar={dataGambar}
                        dataFile={dataFile}
                        dataTablePeluang={dataTablePeluang}
                        setDataGambar={setDataGambar}
                        setDataFile={setDataFile}
                      />
                    )
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default PeluangRAB;

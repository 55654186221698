import React, { forwardRef, useEffect, useState } from 'react';
import config from 'config';
// import { TableLC, TableMP } from '../../RAB'
import { RupiahConvert, DateConvert } from 'utilities';
import parseHtml from 'html-react-parser';

const PrintPenawaran = forwardRef((props, ref) => {
  let id = props.id;
  let dataPenawaran = props.dataPenawaran;
  let dataPrelim = props.dataPrelim;
  let dataListPenawaran = props.dataListPenawaran;

  const [totalLC, setTotalLC] = useState(0);
  const [totalMP, setTotalMP] = useState(0);
  const [totalPenawaran, setTotalPenawaran] = useState(0);

  const tableStyling = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle',
  };

  // FUNCTION KONVERSI TANGGAL INPUT
  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const getDate = inputDate.split(' ');
      const date = new Date(getDate[0]);
      // return `${DateConvert(date).defaultDay} ${DateConvert(date).detailMonth} ${DateConvert(date).defaultYear}`
      return DateConvert(date).custom;
    }
    return '-';
  };

  const getConvertedDateDetail = (inputDate) => {
    if (inputDate != null) {
      const getDate = inputDate.split(' ');
      const date = new Date(getDate[0]);
      return DateConvert(date).detail;
    }
    return '-';
  };

  const getGrandTotalPrelim = dataPrelim.reduce((prev, current) => {
    const newQtyItem = current.qty_item ? parseFloat(current.qty_item) : 0;
    const newHarga = current.harga ? parseFloat(current.harga) : 0;
    // const newQtyDurasi = current.qty_durasi ? parseFloat(current.qty_durasi) : 0;
    const newKonts = current.konstanta ? parseFloat(current.konstanta) : 0;
    const subTotal = newQtyItem * newHarga;
    const total = subTotal * newKonts;

    return prev + total;
  }, 0);

  const TablePenawaran = ({ dataListPenawaran, setTotalPenawaran }) => {
    // FUNGSI UNTUK HITUNG SUBTOTAL
    const hitungSubTotal = (index) => {
      let rounded = dataListPenawaran[index].rounded ?? 0;
      let qty = dataListPenawaran[index].qty_rab;
      let subTotal = rounded * qty;

      // SET HASIL SUBTOTAL KE DALAM OBJECT
      dataListPenawaran[index].subTotal = subTotal;
      return RupiahConvert(String(subTotal)).detail;
    };

    // FUNGSI HITUNG JUMLAH KESELURUHAN SUBTOTAL
    const hitungTotalPenawaran = () => {
      let totalPenawaran = dataListPenawaran.reduce(function (
        accumulator,
        { subTotal }
      ) {
        return accumulator + subTotal;
      },
      0);

      totalPenawaran = totalPenawaran + getGrandTotalPrelim;

      // Hitung Dioskon
      let totalSetelahDiskon =
        (dataPenawaran.diskon ?? 0 * totalPenawaran) / 100;
      totalSetelahDiskon = parseInt(totalSetelahDiskon);

      let totalPenawaranDiskon =
        totalPenawaran - (totalPenawaran * dataPenawaran.diskon ?? 0) / 100;
      totalPenawaranDiskon = parseInt(totalPenawaranDiskon);

      // Hitung PPN
      let totalSetelahPPN =
        (dataPenawaran.ppn ?? 0 * totalPenawaranDiskon) / 100;
      totalSetelahPPN = parseInt(totalSetelahPPN);

      let totalPenawaranPPN =
        totalPenawaranDiskon +
        (totalPenawaranDiskon * dataPenawaran.ppn ?? 0) / 100;
      totalPenawaranPPN = parseInt(totalPenawaranPPN);

      // SET TOTAL SETELAH PAJAK
      setTotalPenawaran(totalPenawaranPPN);

      return {
        totalPenawaran,
        totalSetelahDiskon,
        totalPenawaranDiskon,
        totalSetelahPPN,
        totalPenawaranPPN,
      };
    };

    return (
      <>
        <div className="p-2 mt-3">
          <b>List Item Penawaran</b>
        </div>

        <div>
          <table className="table table-bordered bg-white table-sm">
            <thead className="text-center">
              <tr key="head1">
                <th rowSpan={2} className="align-middle" style={tableStyling}>
                  No.
                </th>
                <th
                  rowSpan={2}
                  className="align-middle"
                  style={{ ...tableStyling, width: '20px' }}
                >
                  Kode Item
                </th>
                <th rowSpan={2} className="align-middle" style={tableStyling}>
                  Nama Item
                </th>
                <th colSpan={2} className="align-middle" style={tableStyling}>
                  Volume
                </th>
                <th rowSpan={2} className="align-middle" style={tableStyling}>
                  Harga Satuan
                </th>
                <th
                  rowSpan={2}
                  className="align-middle"
                  style={{ ...tableStyling, width: '121px' }}
                >
                  Sub Total (Rp)
                </th>
              </tr>
              <tr key="head2">
                <th style={tableStyling}>Qty</th>
                <th style={tableStyling}>Unit</th>
              </tr>
            </thead>
            <tbody>
              {dataListPenawaran &&
                dataListPenawaran.length > 0 &&
                dataListPenawaran.map((val, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td
                        style={{ ...tableStyling, width: '20px' }}
                        className="px-2"
                      >
                        {val.kode_item}
                      </td>
                      <td style={tableStyling} className="px-2">
                        {val.nama_item}
                      </td>
                      <td style={tableStyling} className="text-right">
                        {parseFloat(val.qty_rab).toPrecision()}
                      </td>
                      <td style={tableStyling} className="px-2">
                        {val.nama_satuan}
                      </td>
                      <td style={tableStyling} className="text-right">
                        {RupiahConvert(String(val.rounded)).detail}
                      </td>
                      <td style={tableStyling} className="text-right">
                        {hitungSubTotal(index)}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td className="text-center">
                  {dataListPenawaran && dataListPenawaran.length > 1
                    ? dataListPenawaran.length + 1
                    : 1}
                </td>
                <td style={{ ...tableStyling, width: '20px' }} className="px-2">
                  -
                </td>
                <td style={tableStyling} className="px-2">
                  PRELIM
                </td>
                <td style={tableStyling} className="text-right">
                  1
                </td>
                <td style={tableStyling} className="px-2">
                  -
                </td>
                <td style={tableStyling} className="text-right">
                  {RupiahConvert(String(getGrandTotalPrelim)).detail}
                </td>
                <td style={tableStyling} className="text-right">
                  {RupiahConvert(String(getGrandTotalPrelim)).detail}
                </td>
              </tr>

              <tr>
                <td colSpan={6} className="text-right p-2" style={tableStyling}>
                  <b>Total: </b>
                </td>
                <td className="text-right text-nowrap" style={tableStyling}>
                  <b>
                    {
                      RupiahConvert(
                        String(hitungTotalPenawaran().totalPenawaran)
                      ).detail
                    }
                  </b>
                </td>
              </tr>
              {parseInt(dataPenawaran.diskon) !== 0 && dataPenawaran.diskon ? (
                <tr>
                  <td
                    colSpan={6}
                    className="text-right p-2"
                    style={tableStyling}
                  >
                    <b>Diskon (%) </b>
                  </td>
                  <td className="text-right text-nowrap" style={tableStyling}>
                    <b>{parseInt(dataPenawaran.diskon ?? 0)}</b>
                  </td>
                </tr>
              ) : (
                ''
              )}

              <tr>
                <td colSpan={6} className="text-right p-2" style={tableStyling}>
                  <b>PPN (%) </b>
                </td>
                <td className="text-right text-nowrap" style={tableStyling}>
                  <b>{parseInt(dataPenawaran.ppn ?? 0)}</b>
                </td>
              </tr>
              <tr>
                <td colSpan={6} className="text-right p-2" style={tableStyling}>
                  <b>Total Setelah PPN: </b>
                </td>
                <td className="text-right text-nowrap" style={tableStyling}>
                  <b>
                    {
                      RupiahConvert(
                        String(hitungTotalPenawaran().totalPenawaranPPN)
                      ).detail
                    }
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const GrandTotalSection = ({ totalPenawaran, totalLC, totalMP }) => {
    const hitungGrandTotal = () => {
      let grandTotal = totalPenawaran + totalLC + totalMP;
      return RupiahConvert(String(grandTotal)).detail;
    };

    useEffect(() => {
      hitungGrandTotal();
    }, [totalPenawaran, totalLC, totalMP]);

    return (
      <div className="py-2 p-1 bg-white text-right border">
        <b
          className="text-nowrap pr-3 d-flex justify-content-end align-items-center"
          style={{ fontSize: '14px' }}
        >
          <div className="pr-3">Grand Total :</div> {hitungGrandTotal()}
        </b>
      </div>
    );
  };

  const Header = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="col pl-0">
          <img src={config.LOGO} height="150" />
        </div>
        <div className="col-10 pl-0">
          <h6 className="font-weight-bold">PT. MARDIKA GRIYA PRASTA</h6>
          <span>Jl. Sekarsari No.30, Kesambi, Denpasar, Bali, Indonesia</span>
          <br />
          <span>Phone +62 (0)361 467-342</span>
          <br />
          <span>Fax (0361) 46730</span>
          <br />
          <span>
            Email{' '}
            <span style={{ color: '#6aadff' }}>sales@mgpwoodworks.com</span>
          </span>
          <br />
        </div>
      </div>
    );
  };

  const InfoField = ({ title, value, isBold }) => {
    return (
      <div className="row">
        <div className="col">
          <h6 className={isBold ? 'font-weight-bold' : ''}>{title}</h6>
        </div>
        <div className="col-10">
          <h6>: {value}</h6>
        </div>
      </div>
    );
  };

  return (
    <div className="container my-5 px-5" ref={ref}>
      <Header />
      <div className="my-3">
        <InfoField title="Perihal" value="Penawaran" />
        <InfoField
          title="Tgl. Penawaran"
          value={getConvertedDate(dataPenawaran.tgl_penawaran) ?? '-'}
        />
        <InfoField
          title="No. Penawaran"
          value={dataPenawaran.no_penawaran ?? '-'}
        />
        <InfoField
          title="Tgl. RAB"
          value={getConvertedDate(dataPenawaran.tgl_rab) ?? '-'}
        />
        <InfoField title="No. RAB" value={dataPenawaran.no_rab ?? '-'} />
        <InfoField title="Kepada" value={dataPenawaran.kepada ?? '-'} />
        <InfoField title="ATT" value={dataPenawaran.att ?? '-'} />
        <InfoField
          title="Tgl. Realisasi Penawaran"
          value={getConvertedDate(dataPenawaran.tgl_selesai) ?? '-'}
        />
      </div>

      <hr />

      <div className="my-3">
        {parseHtml(dataPenawaran?.teks_pembuka ?? '<p></p>')}
      </div>

      <TablePenawaran
        dataListPenawaran={dataListPenawaran}
        setTotalPenawaran={setTotalPenawaran}
      />

      <GrandTotalSection
        totalPenawaran={totalPenawaran}
        totalLC={totalLC}
        totalMP={totalMP}
      />

      <div className="my-3">
        {parseHtml(dataPenawaran?.teks_penutup ?? '<p></p>')}
      </div>

      {/* FOOTER SIGNATURE*/}
      <div className="container pb-5 mt-5" style={{ height: '15vh' }}>
        <div className="row pb-5">
          <div className="col d-flex flex-column justify-content-center align-items-start">
            <h5 className="mb-5 pl-4 text-center">Hormat Kami,</h5>
            <div className="d-flex mt-5 flex-column justify-content-end align-items-center ">
              <span>
                <u>A.A. Ngurah Temaja Putra</u>
              </span>
              <span>Direktur</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PrintPenawaran;

import { Services } from '../../../services';

class PenawaranApi {
  getPagePenawaran(params) {
    return Services.get('/penawaran/page', { params });
  }

  getNomorPenawaran(params) {
    return Services.get('/penawaran/no_baru', { params });
  }

  getPageRAB(params) {
    return Services.get('/penawaran/list_rab', { params });
  }

  // GET SINGLE RAE DAN RAB (UBAH PARAMS)
  getSingle(params) {
    return Services.get('/penawaran/single', { params });
  }

  getSingleRab(params) {
    return Services.get('/penawaran/single_rab', { params });
  }

  getDetailPeluangPenawaran(params) {
    // Get Modal Peluang Penawaran
    return Services.get('/penawaran/detail_peluang/', { params });
  }

  save(data) {
    return Services.post('/penawaran/', data);
  }

  statusDeal(data) {
    return Services.put('penawaran/status_deal', data);
  }

  getDropdownCustomer() {
    return Services.get('/dropdown/customer');
  }

  getDropdownProyek() {
    return Services.get('/dropdown/proyek');
  }
}

export default new PenawaranApi();

import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Axios from 'axios';
import { Alert, DataStatus, BackButton, ThFixed } from 'components';
import { Row, Col, Card, Modal } from 'react-bootstrap';
import { RupiahConvert, DateConvert } from 'utilities';
import { RencanaAnggaranBiayaApi } from 'api';
import { TableLC, TableMP } from './TableRAB';
import AnalisaBarangJadi from './AnalisaBarangJadi';

const DetailRAE = ({ setNavbarTitle }) => {
  const { id_rae } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [dataListRAB, setDataListRAB] = useState([]);
  const [dataListLC, setDataListLC] = useState([]);
  const [dataListMP, setDataListMP] = useState([]);

  const [dataRAB, setDataRAB] = useState({});
  const [dataBarangJadi, setDataBarangJadi] = useState([]);

  const [profit, setProfit] = useState(10);
  const [prelim, setPrelim] = useState(10);

  const [totalRAB, setTotalRAB] = useState(0);
  const [totalLC, setTotalLC] = useState(0);
  const [totalMP, setTotalMP] = useState(0);
  const [processedData, setProcessedData] = useState({});

  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });

  const tableStyling = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle',
  };

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    //GET SINGLE DETAIL RAB
    Axios.all([RencanaAnggaranBiayaApi.getSingle({ id_rae })])
      .then(
        Axios.spread((data) => {
          setDataRAB(data.data.data);
          setDataListRAB(data.data.data.detail_barang_jadi);
          setDataBarangJadi(data?.data?.data?.detail_barang_jadi ?? []);
          setDataListLC(data.data.data.detail_upah);
          setDataListMP(data.data.data.detail_alat_mesin);
          setProfit(parseInt(data.data.data.profit));
          setPrelim(parseInt(data.data.data.prelim));
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    return (
      <Row>
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Tgl. Peluang"
                value={
                  dataRAB.tgl_peluang
                    ? DateConvert(new Date(dataRAB.tgl_peluang)).detail
                    : '-'
                }
              />
              <InfoItem
                title="No. Peluang"
                value={dataRAB.no_peluang ? dataRAB.no_peluang : '-'}
              />
              <InfoItem
                title="Tgl. RAE"
                value={
                  dataRAB.tgl_rae
                    ? DateConvert(new Date(dataRAB.tgl_rae)).detail
                    : '-'
                }
              />
              <InfoItem
                title="No. RAE"
                value={dataRAB.no_rae ? dataRAB.no_rae : '-'}
              />
            </tbody>
          </table>
        </Col>
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Customer"
                value={dataRAB.nama_customer ? dataRAB.nama_customer : '-'}
              />
              <InfoItem title="ATT" value={dataRAB.att ? dataRAB.att : '-'} />
              <InfoItem
                title="Tanggal Selesai"
                value={
                  dataRAB.tgl_selesai
                    ? DateConvert(new Date(dataRAB.tgl_selesai)).detail
                    : '-'
                }
              />
              <InfoItem
                title="Peringkat Peluang"
                value={
                  dataRAB.nama_peringkat_peluang
                    ? dataRAB.nama_peringkat_peluang
                    : '-'
                }
              />
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const GrandTotalSection = ({ totalRAB, totalLC, totalMP }) => {
    const hitungGrandTotal = () => {
      let grandTotal = totalRAB + totalLC + totalMP;
      return RupiahConvert(String(grandTotal)).detail;
    };

    useEffect(() => {
      hitungGrandTotal();
    }, [totalRAB, totalLC, totalMP]);

    return (
      <div className="p-2 bg-light text-right border">
        <b className="text-nowrap pr-3" style={{ fontSize: '14px' }}>
          Grand Total : {hitungGrandTotal()}
        </b>
      </div>
    );
  };

  const TableRAB = ({ dataListRAB, setTotalRAB }) => {
    // FUNGSI HITUNG TOTAL KESELURUHAN LC
    const totalRAB = () => {
      let totalRAB = dataListRAB.reduce(function (accumulator, { subtotal }) {
        return accumulator + subtotal;
      }, 0);

      setTotalRAB(totalRAB);
      return RupiahConvert(String(totalRAB)).detail;
    };
    return (
      <>
        <div className="p-2 mt-3">
          <b>List Barang Jadi</b>
        </div>

        <div>
          <table className="table table-bordered bg-white table-sm">
            <thead className="text-center bg-light">
              <tr key="head1">
                <th
                  className="align-middle"
                  style={{ ...tableStyling, width: '20px' }}
                >
                  Kode Barang Jadi
                </th>
                <th className="align-middle" style={tableStyling}>
                  Barang Jadi
                </th>
                <ThFixed style={tableStyling}>Qty</ThFixed>
                <ThFixed style={tableStyling}>Satuan</ThFixed>
                <th
                  className="align-middle"
                  style={{ ...tableStyling, width: '140px' }}
                >
                  Harga Satuan
                </th>
                <th
                  className="align-middle"
                  style={{ ...tableStyling, width: '140px' }}
                >
                  Sub Total (Rp)
                </th>
              </tr>
            </thead>
            <tbody>
              {dataListRAB &&
                dataListRAB.length > 0 &&
                dataListRAB.map((val, index) => {
                  val.subtotal = val.qty_rae * val.harga_satuan_rae;
                  return (
                    <tr>
                      <td style={tableStyling} className="px-2">
                        {val.kode_item}
                      </td>
                      <td style={tableStyling} className="px-2">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setProcessedData({
                              ...val,
                              index: index,
                            });
                            setModalConfig({
                              show: true,
                              type: 'analisa',
                              title: (
                                <span className="text-primary">
                                  Analisa Barang Jadi RAE
                                </span>
                              ),
                            });
                          }}
                        >
                          {val.nama_item}
                        </a>
                      </td>
                      <td style={tableStyling} className="text-right">
                        {val.qty_rae}
                      </td>
                      <td style={tableStyling} className="px-2">
                        {val.nama_satuan}
                      </td>
                      <td style={tableStyling} className="text-right">
                        {
                          RupiahConvert(String(parseInt(val.harga_satuan_rae)))
                            .detail
                        }
                      </td>
                      <td
                        style={{ ...tableStyling, width: '124px' }}
                        className="text-right"
                      >
                        {RupiahConvert(String(val.subtotal)).detail}
                      </td>
                    </tr>
                  );
                })}

              <tr className="bg-light">
                <td colSpan={5} className="text-right p-2" style={tableStyling}>
                  <b>Total: </b>
                </td>
                <td className="text-right text-nowrap" style={tableStyling}>
                  <b>{totalRAB()}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const ModalSection = ({
    processedData,
    dataBarangJadi,
    setDataBarangJadi,
    alertConfig,
    setAlertConfig,
    modalConfig,
    setModalConfig,
  }) => {
    const AnalisaBarangJadiModal = ({
      processedData,
      dataBarangJadi,
      setDataBarangJadi,
      alertConfig,
      setAlertConfig,
      modalConfig,
      setModalConfig,
    }) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadi
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            modalConfig={modalConfig}
            setAlertConfig={setAlertConfig}
            setModalConfig={setModalConfig}
          />
        </Modal.Body>
      );
    };

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === 'analisa' ? 'xl' : 'md'}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <AnalisaBarangJadiModal
          processedData={processedData}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      </Modal>
    );
  };

  useEffect(() => {
    setNavbarTitle('Rencana Anggaran Biaya');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data RAE</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              <InfoSection />
              <hr />
            </>
          )}

          {!isPageLoading && (
            <TableRAB setTotalRAB={setTotalRAB} dataListRAB={dataListRAB} />
          )}

          {!isPageLoading && (
            <TableLC
              dataListLC={dataListLC}
              totalLC={totalLC}
              setTotalLC={setTotalLC}
            />
          )}

          {/* {!isPageLoading && <TableMP dataListMP={dataListMP} totalMP={totalMP} setTotalMP={setTotalMP} />}

                    {!isPageLoading && <GrandTotalSection totalRAB={totalRAB} totalLC={totalLC} totalMP={totalMP} />} */}

          <ModalSection
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            setAlertConfig={setAlertConfig}
            modalConfig={modalConfig}
            setModalConfig={setModalConfig}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default DetailRAE;

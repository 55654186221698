import Logo from '../assets/image/LogoSadhana.png';

import {
  IoServerOutline,
  IoCashOutline,
  IoSpeedometerOutline,
  IoDocumentTextOutline,
} from 'react-icons/io5';

// IMPORT PAGE
import {
  // MASTER
  PeringkatPeluang,
  RegistrasiBarangJadi,
  DetailRegistrasiBarangJadi,
  TambahRegistrasiBarangJadi,
  UpdateRegistrasiBarangJadi,

  // TRANSAKSI
  RegistrasiPeluang,
  TambahRegistrasiPeluang,
  UbahRegistrasiPeluang,
  DetailRegistrasiPeluang,
  RencanaAnggaranBiaya,
  ListRAE,
  TambahRAB,
  UbahRAB,
  DetailRAB,
  Penawaran,
  ListRAB,
  TambahPenawaran,
  UbahPenawaran,
  DetailPenawaran,
  DetailRAE,
  SPK,
  PenawaranSPK,
  DetailSPK,
  TambahSPK,
  UbahSPK,

  // LAPORAN
  LaporanHargaPerkiraanSendiri,
  LogProgressProduksi,
  ProgressProduksi,
  LaporanPeluang,
  LaporanPenawaran,
  LaporanSPK,
  LaporanGeneralMarketing,
  LaporanHargaKubikasiKayu,
} from '../pages/MKT';

import { Proyek } from 'pages/MKT/Master';

export default {
  LOGO: Logo,
  MODUL: 'Marketing',

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: 'Dashboard',
      link: '/',
      type: 'menu',
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ['MKT'],
    },
    {
      text: 'Master Data',
      type: 'dropdown',
      icon: <IoServerOutline />,
      hak: ['MKT'],
      menu: [
        {
          text: 'Peringkat Peluang',
          link: '/master/peringkat-peluang',
          hak: ['SUPA', 'MKT_MAS_PKP'],
        },
        {
          text: 'Proyek',
          link: '/master/proyek',
          hak: ['SUPA', 'MKT_MAS_PKP'],
        },
      ],
    },
    {
      text: 'Transaksi',
      type: 'dropdown',
      icon: <IoCashOutline />,
      hak: ['MKT', 'MKT_TRN_RP', 'MKT_TRN_RAB', 'MKT_TRN_PNW', 'MKT_TRN_SPK'],
      menu: [
        {
          text: 'Peluang',
          link: '/transaksi/registrasi-peluang',
          hak: ['SUPA', 'MKT_TRN_RP'],
        },
        {
          text: 'Rencana Anggaran Biaya',
          link: '/transaksi/rencana-anggaran-biaya',
          hak: ['SUPA', 'MKT_TRN_RAB'],
        },
        {
          text: 'Penawaran',
          link: '/transaksi/penawaran',
          hak: ['SUPA', 'MKT_TRN_PNW'],
        },
        {
          text: 'Surat Perjanjian Kerja',
          link: '/transaksi/spk',
          hak: ['SUPA', 'MKT_TRN_SPK'],
        },
      ],
    },
    {
      text: 'Laporan',
      type: 'dropdown',
      icon: <IoDocumentTextOutline />,
      hak: ['MKT'],
      menu: [
        {
          text: 'Harga Perkiraan Sendiri',
          link: '/laporan/harga-perkiraan-sendiri',
          hak: ['SUPA', 'MKT_RPT_HPS'],
        },
        {
          text: 'Progress Produksi',
          link: '/laporan/progress-produksi',
          hak: ['SUPA', 'MKT_RPT_PRP'],
        },
        {
          text: 'Laporan Peluang',
          link: '/laporan/peluang',
          hak: ['SUPA', 'MKT_RPT_PLG'],
        },
        {
          text: 'Laporan Penawaran',
          link: '/laporan/penawaran',
          hak: ['SUPA', 'MKT_RPT_PNW'],
        },
        {
          text: 'Laporan SPK',
          link: '/laporan/spk',
          hak: ['SUPA', 'MKT_RPT_SPK'],
        },
        {
          text: 'Laporan General Marketing',
          link: '/laporan/general-marketing',
          hak: ['SUPA', 'MKT_RPT_GMKT'],
        },
        {
          text: 'Laporan Harga Kubikasi Kayu',
          link: '/laporan/harga-kubikasi-kayu',
          hak: ['SUPA', 'MKT_RPT_HKK'],
        },
      ],
    },

    // {
    //   text: "Profil",
    //   type: "dropdown",
    //   icon: <IoPersonCircleOutline />,
    //   hak: ["MKT"],
    //   menu: [
    //     {
    //       text: "Akun Saya",
    //       link: "/profil",
    //       hak: ["MKT"],
    //     },
    //     {
    //       text: "Ubah Akun",
    //       link: "/profil/ubah",
    //       hak: ["MKT"],
    //     },
    //     {
    //       text: "Ganti Password",
    //       link: "/profil/ganti-password",
    //       hak: ["MKT"],
    //     },
    //   ],
    // },
  ],

  ROUTES: [
    // MASTER
    // PERINGKAT PELUANG
    {
      exact: true,
      path: '/master/peringkat-peluang',
      page: PeringkatPeluang,
      hak: ['SUPA', 'MKT_MAS_PKP'],
    },
    {
      exact: true,
      path: '/master/proyek',
      page: Proyek,
      hak: ['SUPA', 'MKT_MAS_PKP'],
    },

    // REGISTRASI BARANG JADI
    // {
    //   exact: true,
    //   path: "/master/registrasi-barang-jadi",
    //   page: RegistrasiBarangJadi,
    //   hak: ["SUPA", "MKT_MAS_BRJ"],
    // },
    // {
    //   exact: true,
    //   path: "/master/registrasi-barang-jadi/tambah",
    //   page: TambahRegistrasiBarangJadi,
    //   hak: ["SUPA", "MKT_MAS_BRJ"],
    // },
    // {
    //   exact: true,
    //   path: "/master/registrasi-barang-jadi/update/:id_item_buaso",
    //   page: UpdateRegistrasiBarangJadi,
    //   hak: ["SUPA", "MKT_MAS_BRJ"],
    // },
    // {
    //   exact: true,
    //   path: "/master/registrasi-barang-jadi/detail/:id_item_buaso",
    //   page: DetailRegistrasiBarangJadi,
    //   hak: ["SUPA", "MKT_MAS_BRJ"],
    // },
    // /REGISTRASI BARANG JADI
    // /MASTER

    // TRANSAKSI
    // REGISTRASI PELUANG
    {
      exact: true,
      path: '/transaksi/registrasi-peluang',
      page: RegistrasiPeluang,
      hak: ['SUPA', 'MKT_TRN_RP'],
    },
    {
      exact: true,
      path: '/transaksi/registrasi-peluang/tambah',
      page: TambahRegistrasiPeluang,
      hak: ['SUPA', 'MKT_TRN_RP'],
    },
    {
      exact: true,
      path: '/transaksi/registrasi-peluang/ubah/:id',
      page: UbahRegistrasiPeluang,
      hak: ['SUPA', 'MKT_TRN_RP'],
    },
    {
      exact: true,
      path: '/transaksi/registrasi-peluang/detail/:id',
      page: DetailRegistrasiPeluang,
      hak: ['SUPA', 'MKT_TRN_RP'],
    },

    // RENCANA ANGGARAN BIAYA
    {
      exact: true,
      path: '/transaksi/rencana-anggaran-biaya',
      page: RencanaAnggaranBiaya,
      hak: ['SUPA', 'MKT_TRN_RAB'],
    },

    {
      exact: true,
      path: '/transaksi/rencana-anggaran-biaya/list-rae',
      page: ListRAE,
      hak: ['SUPA', 'MKT_TRN_RAB'],
    },
    {
      exact: true,
      path: '/transaksi/rencana-anggaran-biaya/tambah/:id',
      page: TambahRAB,
      hak: ['SUPA', 'MKT_TRN_RAB', 'MKT_TRN_PNW'],
    },
    {
      exact: true,
      path: '/transaksi/rencana-anggaran-biaya/ubah/:id',
      page: UbahRAB,
      hak: ['SUPA', 'MKT_TRN_RAB', 'MKT_TRN_PNW'],
    },
    {
      exact: true,
      path: '/transaksi/rencana-anggaran-biaya/detail/:id',
      page: DetailRAB,
      hak: ['SUPA', 'MKT_TRN_RAB', 'MKT_TRN_PNW'],
    },
    {
      exact: true,
      path: '/transaksi/rencana-anggaran-biaya/detail-rae/:id',
      page: DetailRAE,
      hak: ['SUPA', 'MKT_TRN_RAB', 'MKT_TRN_PNW'],
    },

    // PENAWARAN
    {
      exact: true,
      path: '/transaksi/penawaran/',
      page: Penawaran,
      hak: ['SUPA', 'MKT_TRN_PNW'],
    },
    {
      exact: true,
      path: '/transaksi/penawaran/list-rab',
      page: ListRAB,
      hak: ['SUPA', 'MKT_TRN_PNW'],
    },
    {
      exact: true,
      path: '/transaksi/penawaran/tambah/:id_rab',
      page: TambahPenawaran,
      hak: ['SUPA', 'MKT_TRN_PNW'],
    },
    {
      exact: true,
      path: '/transaksi/penawaran/ubah/:id_penawaran',
      page: UbahPenawaran,
      hak: ['SUPA', 'MKT_TRN_PNW'],
    },
    {
      exact: true,
      path: '/transaksi/penawaran/detail/:id_penawaran',
      page: DetailPenawaran,
      hak: ['SUPA', 'MKT', 'MKT_TRN_PNW'],
    },

    // SURAT PENAWARAN KERJA
    {
      exact: true,
      path: '/transaksi/spk',
      page: SPK,
      hak: ['SUPA', 'MKT_TRN_SPK'],
    },
    {
      exact: true,
      path: '/transaksi/spk/penawaran',
      page: PenawaranSPK,
      hak: ['SUPA', 'MKT_TRN_SPK'],
    },
    {
      exact: true,
      path: '/transaksi/spk/detail/:id',
      page: DetailSPK,
      hak: ['SUPA', 'MKT_TRN_SPK'],
    },
    {
      exact: true,
      path: '/transaksi/spk/tambah/:id',
      page: TambahSPK,
      hak: ['SUPA', 'MKT_TRN_SPK'],
    },
    {
      exact: true,
      path: '/transaksi/spk/ubah/:id',
      page: UbahSPK,
      hak: ['SUPA', 'MKT_TRN_SPK'],
    },

    // LAPORAN
    {
      exact: true,
      path: '/laporan/harga-perkiraan-sendiri',
      page: LaporanHargaPerkiraanSendiri,
      hak: ['SUPA', 'MKT_RPT_HPS'],
    },
    {
      exact: true,
      path: '/laporan/progress-produksi',
      page: ProgressProduksi,
      hak: ['SUPA', 'MKT_RPT_PRP'],
    },
    {
      exact: true,
      path: '/laporan/progress-produksi/log',
      page: LogProgressProduksi,
      hak: ['SUPA', 'MKT_RPT_PRP'],
    },
    {
      exact: true,
      path: '/laporan/peluang',
      page: LaporanPeluang,
      hak: ['SUPA', 'MKT_RPT_PLG'],
    },
    {
      exact: true,
      path: '/laporan/penawaran',
      page: LaporanPenawaran,
      hak: ['SUPA', 'MKT_RPT_PNW'],
    },
    {
      exact: true,
      path: '/laporan/spk',
      page: LaporanSPK,
      hak: ['SUPA', 'MKT_RPT_SPK'],
    },
    {
      exact: true,
      path: '/laporan/general-marketing',
      page: LaporanGeneralMarketing,
      hak: ['SUPA', 'MKT_RPT_GMKT'],
    },
    {
      exact: true,
      path: '/laporan/harga-kubikasi-kayu',
      page: LaporanHargaKubikasiKayu,
      hak: ['SUPA', 'MKT_RPT_HKK'],
    },
  ],
};

import { Services } from 'services';

class LaporanHargaKubikasiKayuApi {
  getPage(params) {
    return Services.get('/harga_kubikasi_kayu/page', { params });
  }
  dropdown(params) {
    return Services.get('/harga_kubikasi_kayu/dropdown', { params });
  }
  export(params) {
    return Services.get('/penerimaan_barang/export', { params });
  }
}

export default new LaporanHargaKubikasiKayuApi();

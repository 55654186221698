import { Services } from '../../../services';

class SuratPerjanjianKerja {
  get(params) {
    return Services.get('/spk/page', { params });
  }

  getSinglePenawaran(params) {
    return Services.get('/spk/single_penawaran', { params });
  }

  getPenawaran(params) {
    return Services.get('/spk/list_penawaran', { params });
  }

  getSingle(params) {
    return Services.get('/spk/single', { params });
  }

  getNomor(params) {
    return Services.get('/spk/no_baru', { params });
  }

  getDropdownBarangJadi() {
    return Services.get('/dropdown/barang_jadi');
  }

  getDropdownMachineProcess() {
    return Services.get('/dropdown/alat_mesin');
  }

  getDropdownLabourCost() {
    return Services.get('/dropdown/upah');
  }

  getDetailPeluangSPK(params) {
    // Get Modal Peluang Penawaran
    return Services.get('/spk/detail_peluang/', { params });
  }

  getDropdownSegmentasiPasar() {
    return Services.get('/dropdown/segmentasi_pasar');
  }

  save(values) {
    return Services.post('/spk', values);
  }

  getDropdownCustomer() {
    return Services.get('/dropdown/customer');
  }

  getDropdownProyek() {
    return Services.get('/dropdown/proyek');
  }
}

export default new SuratPerjanjianKerja();

import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Formik } from 'formik';
import { FilterModal, DatePicker, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { RencanaAnggaranBiayaApi } from 'api';

const ModalFilter = ({ show, setShow, data, setData }) => {
  const [loading, setLoading] = useState({
    allDropdown: true,
    proyek: true,
  });
  const [dropdown, setDropdown] = useState({
    peringkatPeluang: [{ label: 'Semua', value: undefined }],
    customer: [{ label: 'Semua', value: undefined }],
    proyek: [{ label: 'Semua', value: undefined }],
    kelompokProyek: [
      { value: undefined, label: 'Semua' },
      { value: 'proyek', label: 'Proyek' },
      { value: 'umum', label: 'Umum' },
      { value: 'tender', label: 'Tender' },
    ],
    instalasi: [
      { label: 'Semua', value: undefined },
      { label: 'Dengan Instalasi', value: true },
      { label: 'Tanpa Instalasi', value: false },
    ],
  });

  const getDataDropdown = () => {
    Axios.all([
      RencanaAnggaranBiayaApi.getDropdownPeringkatPeluang(),
      RencanaAnggaranBiayaApi.getDropdownCustomerRAE(),
      RencanaAnggaranBiayaApi.getDropdownProyek(),
    ])
      .then(
        Axios.spread((peringkat, customer, proyek) => {
          const mapDataPeringkat =
            peringkat?.data?.data?.map((val) => ({
              label: val?.nama_peringkat_peluang,
              value: val?.id_peringkat_peluang,
            })) ?? [];

          const mapDataCustomer =
            customer?.data?.data?.map((val) => ({
              label: val?.nama_customer,
              value: val?.id_customer,
            })) ?? [];

          const mapDataProyek =
            proyek?.data?.data?.map((val) => ({
              label: val?.nama_proyek,
              value: val?.id_proyek,
            })) ?? [];

          setDropdown((prev) => ({
            ...prev,
            peringkatPeluang: prev.peringkatPeluang.concat(mapDataPeringkat),
            customer: prev.customer.concat(mapDataCustomer),
            proyek: prev.proyek.concat(mapDataProyek),
          }));
        })
      )
      .finally(() =>
        setLoading({
          ...loading,
          allDropdown: false,
          proyek: false,
        })
      );
  };

  const formInitialValues = {
    tgl_peluang_mulai: data?.tgl_peluang_mulai,
    tgl_peluang_selesai: data?.tgl_peluang_selesai,
    peringkat_peluang: data?.peringkat_peluang,
    customer: data?.customer,
    proyek: data?.proyek,
    kelompok_proyek: data?.kelompok_proyek,
    target_penawaran_mulai: data?.target_penawaran_mulai,
    target_penawaran_selesai: data?.target_penawaran_selesai,
    realisasi_penawaran_mulai: data?.realisasi_penawaran_mulai,
    realisasi_penawaran_selesai: data?.realisasi_penawaran_selesai,
    instalasi: data?.instalasi,
  };

  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData((prev) => ({
        filter: { ...values, active: true },
        pagination: { ...prev.pagination, page: '1' },
      }));
    } else {
      setData((prev) => ({
        filter: { ...values, active: false },
        pagination: { ...prev.pagination, page: '1' },
      }));
    }
    setShow(false);
  };

  const getProyek = (data) => {
    setLoading({ ...loading, proyek: true });

    RencanaAnggaranBiayaApi.getDropdownProyek({ id_customer: data })
      .then((proyek) => {
        const mapDataProyek =
          proyek?.data?.data?.map((val) => ({
            label: val.nama_proyek,
            value: val.id_proyek,
          })) ?? [];

        setDropdown((prev) => ({
          ...prev,
          proyek: [{ label: 'Semua', value: undefined }].concat(mapDataProyek),
        }));
      })
      .finally(() =>
        setLoading({
          ...loading,
          proyek: false,
        })
      );
  };

  const onTanggalRAEChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_rae_awal: startDate,
      tgl_rae_akhir: endDate,
    });
  };

  const onTargetPenawaranChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      target_penawaran_mulai: startDate,
      target_penawaran_selesai: endDate,
    });
  };

  const onRealisasiPenawaranChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      realisasi_penawaran_mulai: startDate,
      realisasi_penawaran_selesai: endDate,
    });
  };

  const onResetButtonClick = (values, setValues, handleSubmit) => {
    setValues({
      tgl_rae_awal: undefined,
      tgl_rae_akhir: undefined,
      peringkat_peluang: undefined,
      customer: undefined,
      proyek: undefined,
      kelompok_proyek: undefined,
      target_penawaran_mulai: undefined,
      target_penawaran_selesai: undefined,
      realisasi_penawaran_mulai: undefined,
      realisasi_penawaran_selesai: undefined,
      instalasi: undefined,
    });

    handleSubmit();
  };

  useEffect(() => getDataDropdown(), []);

  useEffect(() => data.customer && getProyek(data.customer), [data.customer]);

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() =>
            onResetButtonClick(values, setValues, handleSubmit)
          }
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. RAE"
            placeholderText="Pilih tanggal RAE"
            startDate={values.tgl_rae_awal ? new Date(values.tgl_rae_awal) : ''}
            endDate={values.tgl_rae_akhir ? new Date(values.tgl_rae_akhir) : ''}
            onChange={(dates) => onTanggalRAEChange(dates, values, setValues)}
            monthsShown={2}
          />

          <SelectSearch
            label="Peringkat Peluang"
            placeholder="Pilih peringkat peluang"
            defaultValue={dropdown.peringkatPeluang.find(
              (val) => val.value === values.peringkat_peluang
            )}
            option={dropdown.peringkatPeluang}
            onChange={(val) => setFieldValue('peringkat_peluang', val.value)}
            loading={loading.allDropdown}
          />

          <SelectSearch
            label="Customer"
            placeholder="Pilih customer"
            defaultValue={dropdown.customer.find(
              (val) => val.value === values.customer
            )}
            option={dropdown.customer}
            onChange={(val) => {
              setValues({
                ...values,
                customer: val.value,
                proyek: undefined,
              });

              getProyek(val.value, values, setValues);
            }}
            loading={loading.allDropdown}
          />

          <SelectSearch
            label="Proyek"
            placeholder="Pilih proyek untuk memilih proyek"
            defaultValue={dropdown.proyek.find(
              (val) => val.value === values.proyek
            )}
            option={dropdown.proyek}
            onChange={(val) => setFieldValue('proyek', val.value)}
            loading={loading.proyek}
          />

          <SelectSearch
            label="Kelompok Proyek"
            placeholder="Pilih kelompok proyek"
            defaultValue={dropdown.kelompokProyek.find(
              (val) => val.value === values.kelompok_proyek
            )}
            option={dropdown.kelompokProyek}
            onChange={(val) => setFieldValue('kelompok_proyek', val.value)}
            loading={loading.allDropdown}
          />

          <DatePicker
            selectsRange
            label="Target Penawaran"
            placeholderText="Pilih target penawaran"
            startDate={
              values.target_penawaran_mulai
                ? new Date(values.target_penawaran_mulai)
                : ''
            }
            endDate={
              values.target_penawaran_selesai
                ? new Date(values.target_penawaran_selesai)
                : ''
            }
            onChange={(dates) =>
              onTargetPenawaranChange(dates, values, setValues)
            }
            monthsShown={2}
          />

          <DatePicker
            selectsRange
            label="Realisasi Penawaran"
            placeholderText="Pilih realisasi penawaran"
            startDate={
              values.realisasi_penawaran_mulai
                ? new Date(values.realisasi_penawaran_mulai)
                : ''
            }
            endDate={
              values.realisasi_penawaran_selesai
                ? new Date(values.realisasi_penawaran_selesai)
                : ''
            }
            onChange={(dates) =>
              onRealisasiPenawaranChange(dates, values, setValues)
            }
            monthsShown={2}
          />

          <SelectSearch
            label="Instalasi"
            placeholder="Pilih instalasi"
            defaultValue={dropdown.instalasi.find(
              (val) => val.value === values.instalasi
            )}
            option={dropdown.instalasi}
            onChange={(val) => setFieldValue('instalasi', val.value)}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;

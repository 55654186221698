import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Modal } from 'react-bootstrap';
import {
  IoAlertCircleOutline,
  IoCheckboxOutline,
  IoCloseCircleOutline,
} from 'react-icons/io5';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  CreateButton,
  ReadButton,
  UpdateButton,
  TextArea,
  FilterButton,
  ApprovalStatusButton,
} from 'components';
import { DateConvert, TableNumber } from 'utilities';
import { PenawaranApi } from 'api';
import { useIsGuest } from 'hooks';
import { ModalFilter, BarangJadiCollapse } from './Section';

const Penawaran = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const isGuest = useIsGuest();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [dataListRAB, setDataListRAB] = useState([]);
  const [modalStatusDealConfig, setModalStatusDealConfig] = useState({
    show: false,
    data: {},
  });
  const [modalConfirmConfig, setModalConfirmConfig] = useState({
    show: false,
    data: {},
  });
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.penawaran?.filter?.active,
      tgl_penawaran_mulai:
        location?.state?.penawaran?.filter?.tgl_penawaran_mulai,
      tgl_penawaran_selesai:
        location?.state?.penawaran?.filter?.tgl_penawaran_selesai,
      customer: location?.state?.penawaran?.filter?.customer,
      proyek: location?.state?.penawaran?.filter?.proyek,
      realisasi_penawaran_mulai:
        location?.state?.penawaran?.filter?.realisasi_penawaran_mulai,
      realisasi_penawaran_selesai:
        location?.state?.penawaran?.filter?.realisasi_penawaran_selesai,
      instalasi: location?.state?.penawaran?.filter?.instalasi,
      status_deal: location?.state?.penawaran?.filter?.status_deal,
    },
    pagination: {
      page: location?.state?.penawaran?.filter?.page ?? '1',
      dataLength: location?.state?.penawaran?.filter?.dataLength ?? '10',
      totalPage: location?.state?.penawaran?.filter?.totalPage ?? '1',
      dataCount: location?.state?.penawaran?.filter?.dataCount ?? '0',
    },
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PenawaranApi.getPagePenawaran({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_penawaran_mulai: dataFilter?.filter?.tgl_penawaran_mulai,
      tgl_penawaran_selesai: dataFilter?.filter?.tgl_penawaran_selesai,
      customer: dataFilter?.filter?.customer,
      proyek: dataFilter?.filter?.proyek,
      realisasi_penawaran_mulai: dataFilter?.filter?.realisasi_penawaran_mulai,
      realisasi_penawaran_selesai:
        dataFilter?.filter?.realisasi_penawaran_selesai,
      instalasi: dataFilter?.filter?.instalasi,
      status_deal: dataFilter?.filter?.status_deal,
    })
      .then((data) => {
        setDataListRAB(data?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          },
        });
        searchConfig.status &&
          setAlertConfig({
            // SHOW HIDE ALERT CONFIG
            show: searchConfig.key.length > 0 ? true : false,
            variant: 'primary',
            text: `Hasil dari pencarian: ${searchConfig.key}`,
          });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const getStatusApprovalLabel = (status_approval) => {
    switch (status_approval) {
      case 'APP':
        return {
          variant: 'outline-success',
          label: 'APPROVED',
        };
      case 'REJ':
        return {
          variant: 'outline-danger',
          label: 'REJECTED',
        };
      case 'REV':
        return {
          variant: 'outline-warning',
          label: 'REVISION',
        };
      case 'VER':
        return {
          variant: 'outline-success',
          label: 'VERIFIED',
        };
      default:
        return {
          variant: 'outline-secondary',
          label: 'PENDING',
        };
    }
  };

  useEffect(() => {
    setNavbarTitle('Penawaran');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.status,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_penawaran_mulai,
    dataFilter?.filter?.tgl_penawaran_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.proyek,
    dataFilter?.filter?.realisasi_penawaran_mulai,
    dataFilter?.filter?.realisasi_penawaran_selesai,
    dataFilter?.filter?.instalasi,
    dataFilter?.filter?.status_deal,
  ]);

  const StatusDealBtn = ({ dataItem }) => {
    if (dataItem.status_deal === 'belum_deal') {
      return (
        <ActionButton
          disable={dataItem.status_approval !== 'APP' || isGuest}
          className="btn-block text-nowrap"
          text="Belum Deal"
          size="sm"
          onClick={() => {
            if (dataItem.status_approval === 'APP' && !isGuest) {
              setModalStatusDealConfig({ show: true, data: dataItem });
            }
          }}
        />
      );
    }
    if (dataItem.status_deal === 'tidak_deal') {
      return (
        <ActionButton
          disable
          className="btn-block text-nowrap"
          variant="danger"
          text="Tidak Deal"
          size="sm"
        />
      );
    }

    if (dataItem.status_deal === 'deal') {
      return (
        <ActionButton
          disable
          className="btn-block text-nowrap"
          // style={{ padding: '10px 0px' }}
          variant="success"
          text="Deal"
          size="sm"
        />
      );
    }
  };

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    return (
      <Row>
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Tgl. Penawaran"
                value={
                  modalStatusDealConfig?.data?.tgl_penawaran
                    ? DateConvert(
                        new Date(modalStatusDealConfig.data.tgl_penawaran)
                      ).custom
                    : '-'
                }
              />
              <InfoItem
                title="No. Penawaran"
                value={modalStatusDealConfig?.data?.no_penawaran ?? '-'}
              />
              <InfoItem
                title="Customer"
                value={modalStatusDealConfig?.data?.nama_customer ?? '-'}
              />
              <InfoItem
                title="ATT"
                value={modalStatusDealConfig?.data?.att ?? '-'}
              />
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const ModalStatusDeal = () => {
    const [value, setValue] = useState({});

    const formSubmitHandler = (val) => {
      const finalValues = {
        keterangan: value.keterangan ?? '-',
        status_deal: val,
      };
      if (val) {
        setModalConfirmConfig({
          show: true,
          data: finalValues,
        });
      }
    };

    return (
      <Modal
        closeButton
        show={modalStatusDealConfig.show}
        title="Status Deal"
        onHide={() => setModalStatusDealConfig({ show: false })}
      >
        <Modal.Header>
          <b>Ubah Status Deal?</b>
        </Modal.Header>
        <Modal.Body>
          <InfoSection />
          <TextArea
            label="Keterangan"
            placeholder="Masukan Keterangan"
            onChange={(e) => setValue({ ...value, keterangan: e.target.value })}
          />
          <br />
          <div className="d-flex justify-content-center">
            <ActionButton
              variant="danger"
              text="Tidak Deal"
              className="m-1 px-4"
              onClick={() => {
                formSubmitHandler('tidak_deal');
                setModalStatusDealConfig({
                  ...modalStatusDealConfig,
                  show: false,
                });
              }}
            />
            <ActionButton
              variant="primary"
              text="Deal"
              className="m-1 px-4"
              onClick={() => {
                formSubmitHandler('deal');
                formSubmitHandler();
                setModalStatusDealConfig({
                  ...modalStatusDealConfig,
                  show: false,
                });
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const InfoSectionConfirm = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    return (
      <Row>
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Tgl. Penawaran"
                value={
                  modalStatusDealConfig?.data?.tgl_penawaran
                    ? DateConvert(
                        new Date(modalStatusDealConfig.data.tgl_penawaran)
                      ).custom
                    : '-'
                }
              />
              <InfoItem
                title="No. Penawaran"
                value={modalStatusDealConfig?.data?.no_penawaran ?? '-'}
              />
              <InfoItem
                title="Customer"
                value={modalStatusDealConfig?.data?.nama_customer ?? '-'}
              />
              <InfoItem
                title="ATT"
                value={modalStatusDealConfig?.data?.att ?? '-'}
              />
              <InfoItem
                title="Keterangan"
                value={modalConfirmConfig.data.keterangan ?? '-'}
              />
              <InfoItem
                title="Status Deal"
                value={
                  modalConfirmConfig.data.status_deal === 'deal'
                    ? 'Deal'
                    : 'Tidak Deal'
                }
              />
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const ModalConfirm = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmitHandler = () => {
      setIsSubmitting(true);

      const finalValues = {
        id_penawaran: modalStatusDealConfig.data.id_penawaran,
        status_deal: modalConfirmConfig.data.status_deal,
        keterangan: modalConfirmConfig.data.keterangan ?? '-',
      };

      // KIRIM DATA KE SERVER
      PenawaranApi.statusDeal(finalValues)
        .then(() => {
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Data berhasil disimpan!',
          });
          setModalConfirmConfig({
            show: false,
            data: {},
          });
          getInitialData();
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal disimpan!',
          });
          setModalConfirmConfig({
            show: false,
            data: {},
          });
        })
        .finally(() => setIsSubmitting(false));
    };

    return (
      <Modal
        closeButton
        show={modalConfirmConfig.show}
        onHide={() => {
          setModalConfirmConfig({
            show: false,
            data: {},
          });
        }}
        title="Konfirmasi Status Deal"
      >
        <Modal.Body>
          <div className="text-center">
            <IoAlertCircleOutline size={80} className="text-danger mb-3" />
          </div>
          <div className="text-center">
            <b className="text-center">
              Apakah anda yakin akan menyimpan Status Deal data ini?
            </b>
          </div>
          <div className="container mx-3">
            <InfoSectionConfirm />
          </div>
          <div className="text-center">
            <small className="text-danger">
              Data yang telah disimpan tidak dapat diedit!
            </small>
          </div>
          <br />
          <div className="d-flex justify-content-center">
            <ActionButton
              variant="outline-danger"
              text="Batal"
              className="m-1 px-4"
              onClick={() => {
                setModalConfirmConfig({
                  show: false,
                  data: {},
                });
              }}
            />
            <ActionButton
              variant="primary"
              text="Simpan"
              className="m-1 px-4"
              loading={isSubmitting}
              onClick={onSubmitHandler}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const PageContent = () => {
    const DataTable = () => (
      <>
        <span style={{ fontSize: '14px' }}>
          <b>List Data Penawaran</b>
        </span>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Penawaran</ThFixed>
              <Th>Customer</Th>
              <Th>Proyek</Th>
              <Th>ATT</Th>
              <ThFixed>Instalasi</ThFixed>
              <ThFixed>Tgl. Realisasi Penawaran</ThFixed>
              <Th>Barang Jadi</Th>
              <Th>Keterangan</Th>
              <ThFixed>Baseline</ThFixed>
              <ThFixed>Status Deal</ThFixed>
              <ThFixed>Status Approval</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dataListRAB.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>
                  {TableNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-start">
                    <ReadButton
                      onClick={() =>
                        history.push(
                          `/transaksi/penawaran/detail/${val.id_penawaran}`,
                          {
                            status_approval: val.status_approval,
                            ...location?.state,
                            penawaran: dataFilter,
                          }
                        )
                      }
                    />

                    {val.status_approval === 'REV' ||
                      (val.status_approval === 'APP' && (
                        <UpdateButton
                          onClick={() =>
                            history.push(
                              `/transaksi/penawaran/ubah/${val.id_penawaran}`,
                              { ...location?.state, penawaran: dataFilter }
                            )
                          }
                        />
                      ))}
                  </div>
                </TdFixed>
                <TdFixed>
                  <div>{DateConvert(new Date(val.tgl_penawaran)).custom}</div>
                  <div>{val?.no_penawaran ?? '-'}</div>
                </TdFixed>
                <Td style={{ minWidth: '280px' }}>
                  {val?.nama_customer ?? '-'}
                </Td>
                <Td style={{ minWidth: '280px' }}>{val?.nama_proyek ?? '-'}</Td>
                <Td style={{ minWidth: '150px' }}>{val.att ?? '-'}</Td>
                <TdFixed textCenter>
                  {Boolean(val.instalasi) ? (
                    <IoCheckboxOutline fontSize="20px" />
                  ) : (
                    <IoCloseCircleOutline fontSize="20px" />
                  )}
                </TdFixed>
                <TdFixed>
                  {val.realisasi_penawaran
                    ? DateConvert(new Date(val.realisasi_penawaran)).custom
                    : '-'}
                </TdFixed>
                <Td style={{ minWidth: '300px' }}>
                  {val?.barang_jadi && val?.barang_jadi.length > 0 ? (
                    <BarangJadiCollapse data={val.barang_jadi} />
                  ) : (
                    '-'
                  )}
                </Td>
                <Td>{val.keterangan ? val.keterangan : '-'}</Td>
                <TdFixed textCenter>{val.baseline ?? '-'}</TdFixed>
                <TdFixed>
                  <StatusDealBtn dataItem={val} />
                </TdFixed>
                <TdFixed>
                  <ApprovalStatusButton
                    variant={
                      getStatusApprovalLabel(val.status_approval).variant
                    }
                  >
                    {val.status_approval
                      ? getStatusApprovalLabel(val.status_approval).label
                      : '-'}
                  </ApprovalStatusButton>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={
            dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength -
            dataFilter?.pagination?.dataLength +
            1
          }
          dataPage={
            dataFilter?.pagination?.dataCount <
            dataFilter?.pagination?.dataLength
              ? dataFilter?.pagination?.dataCount
              : dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: selected + 1,
              },
            })
          }
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: 1,
                dataLength: e.target.value,
              },
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              placeholder="Cari barang jadi"
              value={searchConfig.key}
              onChange={(e) =>
                setSearchConfig({
                  ...searchConfig,
                  status: true,
                  key: e.target.value,
                })
              }
            />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() => {
              history.push(`/transaksi/penawaran/list-rab`, {
                ...location?.state,
                penawaran: dataFilter,
              });
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => {
          setAlertConfig({
            ...alertConfig,
            show: false,
          });
          // CLEAR Alert location.alert
          history.replace('/transaksi/penawaran', { alert: undefined });
        }}
      />
      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataListRAB.length > 0 ? (
        <>
          <PageContent />
          {modalStatusDealConfig.show && <ModalStatusDeal />}
          {modalConfirmConfig.show && <ModalConfirm />}
        </>
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      <ModalFilter
        show={showModalFilter}
        setShow={setShowModalFilter}
        data={dataFilter}
        setData={setDataFilter}
      />
    </CRUDLayout>
  );
};

export default Penawaran;

import { Services } from 'services';

class RencanaAnggaranBiayaApi {
  getPageRAB(params) {
    return Services.get('/rab/page', { params });
  }

  getNomorRAB(params) {
    return Services.get('/rab/no_baru', { params });
  }

  getPageRAE(params) {
    return Services.get('/rab/list_rae', { params });
  }

  // DROPDOWN RAB
  getDropdownCustomer() {
    return Services.get('/peluang/customer');
  }

  getDropdownProyek(params) {
    return Services.get(`/dropdown/proyek`, { params });
  }

  // DROPDOWN RAE
  getDropdownPeringkatPeluang(params) {
    return Services.get(`/dropdown/peringkat_peluang`, { params });
  }

  getDropdownCustomerRAE(params) {
    return Services.get(`/dropdown/customer`, { params });
  }

  // GET SINGLE RAE DAN RAB (UBAH PARAMS)
  getSingle(params) {
    return Services.get('/rab/single', { params });
  }

  getSingleRAE(params) {
    return Services.get('/rab/detail_rae', { params });
  }

  getDetailPeluangRAB(params) {
    // Get Modal Peluang RAB
    return Services.get('/rab/detail_peluang', { params });
  }

  getDetailDataRAE(params) {
    return Services.get('/rab/detail_rae', { params });
  }

  save(data) {
    return Services.post('/rab', data);
  }

  exportPrelim(params) {
    return Services.get('/rab_report/prelim', { params });
  }
}

export default new RencanaAnggaranBiayaApi();

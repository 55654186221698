import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
import { InfoItemHorizontal } from 'components';
import { DateConvert } from 'utilities';
import { TableSPK } from '../Content';
import LogoMGP from 'assets/image/LogoMGP.jpg';

const Signature = ({ label, nama }) => {
  return (
    <div className="text-center">
      <div>{label}</div>
      <div style={{ height: 100 }}></div>
      <div>{nama}</div>
    </div>
  );
};

const PrintSection = forwardRef(
  ({ ref, formik, dataInfo, dataSPK, dataPrelim }) => {
    const { values } = formik;

    return (
      <div ref={ref}>
        <div className="position-relative py-2 mb-3">
          <div className="position-absolute">
            <img src={LogoMGP} alt="logo-mgp" height={100} />
          </div>
          <div className="text-center">
            <p>
              <b>PT. MARDIKA GRIYA PRASTA</b>
              <br />
              <span>
                Contractor & Supplier, Perdagangan & Jasa Produksi Kayu Olahan{' '}
              </span>
              <br />
              <span>
                Jl. Sekar Sari No. 30, Desa Kesiman Kertalangu, Denpasar Timur -
                Bali
              </span>{' '}
              <br />
              <span>Telp. (0361) 467342, 467343. Fax, (0361) 467301</span>
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-around">
          <div>
            <InfoItemHorizontal
              label="Nama"
              text={dataInfo?.nama_customer ?? '-'}
            />
            <InfoItemHorizontal label="Alamat" text={dataInfo?.alamat ?? '-'} />
            <InfoItemHorizontal
              label="Tanggal"
              text={
                dataInfo?.tgl_spk
                  ? DateConvert(new Date(dataInfo.tgl_spk)).detail
                  : '-'
              }
            />
            <InfoItemHorizontal
              label="Tanggal Selesai Barang"
              text={
                dataInfo?.tgl_selesai_spk
                  ? DateConvert(new Date(dataInfo.tgl_selesai_spk)).detail
                  : '-'
              }
            />
          </div>
          <div>
            <InfoItemHorizontal
              label="Telephone"
              text={dataInfo?.telepon ?? '-'}
            />
            <InfoItemHorizontal label="Email" text={dataInfo?.email ?? '-'} />
            <InfoItemHorizontal
              label="Proyek"
              text={dataInfo?.nama_proyek ?? '-'}
            />
            <InfoItemHorizontal
              label="Sekmen Pasar"
              text={dataInfo?.nama_segmentasi_pasar ?? '-'}
            />
          </div>
        </div>
        <div className="text-center pt-5">
          <p>
            <b>
              <u>SURAT PERJANJIAN KERJA </u>
            </b>
            <br />
            <span>{values?.no_spk ?? '-'}</span>
          </p>
        </div>
        <TableSPK
          print
          formik={formik}
          dataPrelim={dataPrelim}
          dataSPK={dataSPK}
        />
        <Card>
          <Card.Header>
            <b>Catatan</b>
          </Card.Header>
          <Card.Body>{HTMLReactParser(values.catatan)}</Card.Body>
        </Card>
        <div className="mt-3">
          <b>PENTING!!!</b>
          <p className="p-2 border">
            <span>
              Periksalah barang anda dengan order pengiriman disertai dengan
              faktur penyerahan barang dalam rangkap empat (4)
            </span>
            <br />
            <span>
              Barang - barang yang sudah diterima tidak dapat dikembali
            </span>
          </p>
        </div>
        <div className="d-flex justify-content-around mt-5 pt-4">
          <Signature label="Disetujui (I)" nama={dataInfo?.att ?? '-'} />
          <Signature label="Disetujui (II)" nama="A. A Ngurah Temaja Putra " />
          <Signature label="Disetujui (III)" nama="Ketut Puriarjana" />
        </div>
      </div>
    );
  }
);

class Print extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <PrintSection
        formik={this.props.formik}
        dataInfo={this.props.dataInfo}
        dataPrelim={this.props.dataPrelim}
        dataSPK={this.props.dataSPK}
      />
    );
  }
}

export default Print;

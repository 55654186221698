import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { TextEditor, ActionButton } from 'components';

const TextEditorSection = ({ formik }) => {
  const [catatan, setCatatan] = useState(EditorState.createEmpty());
  const { isSubmitting, setFieldValue, handleSubmit } = formik;

  // Memperbaharui nilai catatan & submit
  const submitHandler = () => {
    const convertData = convertToRaw(catatan.getCurrentContent());
    setFieldValue('catatan', draftToHtml(convertData));
    handleSubmit();
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <TextEditor
        label="Catatan"
        placeholder="Masukan catatan"
        editorState={catatan}
        onEditorStateChange={(val) => setCatatan(val)}
      />
      <div className="d-flex justify-content-end mt-3">
        <ActionButton
          text="Simpan Data SPK"
          onClick={submitHandler}
          loading={isSubmitting}
        />
      </div>
    </>
  );
};

export default TextEditorSection;

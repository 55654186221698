import { useRef, useState, useEffect } from 'react';
import { Card, Nav } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import { ActionButton, Alert, BackButton, DataStatus } from 'components';
import { RegistrasiPeluangApi } from 'api';
import { TabInfo, TabGambar, TabFile } from './Section';

const TambahRegistrasiPeluang = ({ setNavbarTitle }) => {
  const history = useHistory();
  const tabsRef = useRef();
  const { state } = useLocation();
  const [dataInfo, setDataInfo] = useState({});
  const [dataGambar, setDataGambar] = useState([]);
  const [dataFile, setDataFile] = useState([]);
  const [dataBarangJadi, setDataBarangJadi] = useState([]);
  const [listBarangJadi, setListBarangJadi] = useState([]);
  const [dropdown, setDropdown] = useState({
    customer: [],
    peringkat_peluang: [],
    barang_jadi: [],
    kelompok_proyek: [
      {
        value: 'proyek',
        label: 'Proyek',
      },
      {
        value: 'umum',
        label: 'Umum',
      },
      {
        value: 'tender',
        label: 'Tender',
      },
    ],
  });

  const [defaultTabs, setDefaultTabs] = useState('info');
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: '',
    text: '',
  });

  // Konfigurasi komponen tabs
  const tabsConfig = [
    {
      tab: 'info',
      label: 'Informasi Umum',
      component: ({ ...rest }) => <TabInfo {...rest} />,
    },
    {
      tab: 'gambar',
      label: 'Gambar',
      component: ({ ...rest }) => <TabGambar {...rest} action="CREATE" />,
    },
    {
      tab: 'file',
      label: 'File',
      component: ({ ...rest }) => <TabFile {...rest} action="CREATE" />,
    },
  ];

  // Nilai awal pada form
  const formInitialValues = {
    tgl_peluang: '',
    no_peluang: '',
    id_customer: '',
    id_proyek: '',
    att: '',
    id_peringkat_peluang: '',
    tgl_selesai: '',
    realisasi_penawaran: '',
    keterangan_pengiriman: '',
    instalasi: false,
    lokasi_pengiriman: '',
    lain_lain: '',
    schedule_kebutuhan_proyek: '',
    link_referensi: '',
    kelompok_proyek: 'umum',
  };

  // Validasi pada form
  const formValidationSchema = Yup.object().shape({
    tgl_peluang: Yup.date().required('Tanggal Peluang wajib diisi'),
    id_customer: Yup.string().required('Customer wajib diisi'),
    id_proyek: Yup.string().required('Proyek wajib diisi'),
    id_peringkat_peluang: Yup.string().required(
      'Peringkat Peluang wajib diisi'
    ),
    tgl_selesai: Yup.date().required('Tanggal Selesai wajib diisi'),
    realisasi_penawaran: Yup.date().required('Realisasi Penawaran wajib diisi'),
    link_referensi: Yup.string().required('Link Referensi wajib diisi'),
    schedule_kebutuhan_proyek: Yup.string().required(
      'Schedule kebutuhan proyek'
    ),
  });

  // Menangani submit form
  const formSubmitHandler = (values, { setSubmitting }) => {
    const uploadLink = RegistrasiPeluangApi.upload;
    const mergeFile = [...dataGambar, ...dataFile];
    const fileData = new FormData();

    mergeFile.map((val, index) => {
      fileData.append(`file_${index}`, val.data);
    });

    RegistrasiPeluangApi.upload(fileData)
      .then((res) => {
        const fileResponse = res.data.data;
        const filePath = Object.values(fileResponse);
        const finalValues = {
          ...values,
          detail: listBarangJadi,
          path_gambar: filePath,
        };
        return RegistrasiPeluangApi.create(finalValues)
          .then(() => {
            history.push('/transaksi/registrasi-peluang', {
              registerAlertConfig: {
                variant: 'primary',
                text: 'Tambah data berhasil!',
              },
              ...state,
            });
          })
          .catch(() => {
            setAlertConfig({
              show: true,
              variant: 'danger',
              text: 'Data gagal disimpan!',
            });
          });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data & file gagal disimpan!',
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  // Menangani perubahan pada tabs
  const onTabsChangeHandler = (e, tabs) => {
    e.preventDefault();
    setDefaultTabs(tabs);
  };

  // Fetch data pada saat page pertama kali dibuka
  const fetchInitialData = async () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    return await Axios.all([
      RegistrasiPeluangApi.getCustomer(),
      RegistrasiPeluangApi.getPeringkatPeluang(),
      RegistrasiPeluangApi.getBarangJadi(),
    ])
      .then(
        Axios.spread((customer, peringkat, barang) => {
          const dataCustomer = customer?.data?.data?.map((val) =>
            Object({ ...val, value: val.id_customer, label: val.nama_customer })
          );
          const dataPeringkat = peringkat?.data?.data?.map((val) =>
            Object({
              ...val,
              value: val.id_peringkat_peluang,
              label: val.nama_peringkat_peluang,
            })
          );
          const dataBarangJadi = barang?.data?.data?.map((val) =>
            Object({
              ...val,
              value: val.id_item_buaso,
              label: val.nama_item,
            })
          );

          setDropdown((prevState) =>
            Object({
              ...prevState,
              customer: dataCustomer ?? [],
              peringkat: dataPeringkat ?? [],
            })
          );

          setDataBarangJadi(dataBarangJadi ?? []);

          setFetchingStatus({
            loading: false,
            success: true,
          });
        })
      )
      .catch(() => {
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  useEffect(async () => {
    setNavbarTitle('Registrasi Peluang');
    await fetchInitialData();
    return () => {};
  }, []);

  return (
    <>
      {/* Title */}
      <div className="d-flex justify-content-between align-items-center pb-2">
        <h6 className="p-1">
          <b>Tambah Data Peluang</b>
        </h6>
        <BackButton
          size="sm"
          onClick={() =>
            history.push('/transaksi/registrasi-peluang', { ...state })
          }
        />
      </div>

      {/* Alert */}
      <Alert
        showCloseButton
        show={alertConfig.show}
        text={alertConfig.text}
        variant={alertConfig.variant}
        onClose={() =>
          setAlertConfig({
            show: false,
            variant: '',
            text: '',
          })
        }
      />

      <Card>
        <Card.Header>
          {/* Tab Header */}
          <Nav variant="tabs" defaultActiveKey={`#${defaultTabs}`}>
            {tabsConfig.map((val, index) => (
              <Nav.Item key={index}>
                <Nav.Link
                  ref={tabsRef}
                  href={`#${val.tab}`}
                  onClick={(e) => onTabsChangeHandler(e, val.tab)}
                >
                  {val.label}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {fetchingStatus.loading ? (
            <DataStatus loading text="Memuat data . . ." />
          ) : !fetchingStatus.success ? (
            <DataStatus text="Data gagal dimuat!" />
          ) : (
            <Formik
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={formSubmitHandler}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  {/* Tab Body */}
                  {tabsConfig.map(
                    ({ tab, component: Component }, index) =>
                      tab === defaultTabs && (
                        <Component
                          key={index}
                          type="CREATE"
                          formik={formik}
                          dataGambar={dataGambar}
                          dataFile={dataFile}
                          dropdown={dropdown}
                          listBarangJadi={listBarangJadi}
                          dataBarangJadi={dataBarangJadi}
                          alertConfig={alertConfig}
                          setListBarangJadi={setListBarangJadi}
                          setDataBarangJadi={setDataBarangJadi}
                          setDropdown={setDropdown}
                          setDataGambar={setDataGambar}
                          setDataFile={setDataFile}
                          setAlertConfig={setAlertConfig}
                        />
                      )
                  )}
                  <hr />

                  {/* Submit Button */}
                  <div className="text-right">
                    <ActionButton
                      type="submit"
                      text="Simpan Peluang"
                      loading={formik.isSubmitting}
                    />
                  </div>
                </form>
              )}
            </Formik>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default TambahRegistrasiPeluang;

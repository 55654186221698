import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Card, Nav } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import htmlParser from 'html-react-parser';
import Axios from 'axios';
import ReactToPrint from 'react-to-print';
import {
  Alert,
  ActionButton,
  BackButton,
  DataStatus,
  SelectSearch,
} from 'components';
import { RupiahConvert } from 'utilities';
import { AnalisaBarangJadiApi } from 'api';
import {
  TableListHardwood,
  TableListPlywood,
  TableListTPFS,
  TableListTPLC,
  TableListTPMP,
  TableListTPBOP,
  TableListFNBP,
  TableListFNSC,
  TableListFNLC,
  TableListLCOS,
  TableListMPOS,
  TableListPrelim,
} from './Table';
// import PrintAnalisaBarangJadi from "../PrintAnalisaBarangJadi";
import FilesUpload from './FilesUpload';

const FormAnalisaBarangJadi = ({
  processedData,
  dataBarangJadi,
  setDataBarangJadi,
  alertConfig,
  setAlertConfig,
  modalConfig,
  setModalConfig,
}) => {
  const history = useHistory();
  const id = processedData.id_barang_jadi;
  const ref_uid = processedData.ref_uid;
  const componentRef = useRef();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataAnalisaBarangJadi, setDataAnalisaBarangJadi] = useState({});
  const [dataBahanBaku, setDataBahanBaku] = useState({});
  const [dataBahanPenunjang, setDataBahanPenunjang] = useState({});
  const [dataFinishing, setDataFinishing] = useState({});
  const [dataOnSite, setDataOnSite] = useState({});
  const [dataPrint, setDataPrint] = useState({});
  const [dataKayu, setDataKayu] = useState([]);
  const [dataKelompokTipeBarangJadi, setDataKelompokTipeBarangJadi] = useState(
    []
  );
  const [dataTipeBarangJadi, setDataTipeBarangJadi] = useState([]);
  const [dataFinishingBarangJadi, setDataFinishingBarangJadi] = useState([]);
  const [filesUpload, setFilesUpload] = useState([]);
  const [dropdownTipeSisi, setDropdownTipeSisi] = useState([]);
  const [dropdownTipeFinishing, setDropdownTipeFinishing] = useState([]);
  const [dropdownSpekKayu, setDropdownSpekKayu] = useState([]);
  const [dropdownSpekPlywood, setDropdownSpekPlywood] = useState([]);

  const getBahanBaku = () =>
    AnalisaBarangJadiApi.getSingle({
      id_barang_jadi: id,
      ref_uid: ref_uid ? ref_uid : '',
      tipe: 'baku',
    })
      .then((res) => {
        setDataBahanBaku(res.data.data);
      })
      .catch((res) => {})
      .finally((res) => {});

  const getPenunjangProduksi = () =>
    AnalisaBarangJadiApi.getSingle({
      id_barang_jadi: id,
      ref_uid: ref_uid ? ref_uid : '',
      tipe: 'penunjang',
    })
      .then((res) => {
        setDataBahanPenunjang(res.data.data);
      })
      .catch((res) => {})
      .finally((res) => {});

  const getFinishing = () =>
    AnalisaBarangJadiApi.getSingle({
      id_barang_jadi: id,
      ref_uid: ref_uid ? ref_uid : '',
      tipe: 'fin',
    })
      .then((res) => {
        setDataFinishing(res.data.data);
      })
      .catch((res) => {})
      .finally((res) => {});

  // const getOnSite = () =>
  //   AnalisaBarangJadiApi.getSingle({ id_barang_jadi: id, tipe: "os" })
  //     .then((res) => {
  //       setDataOnSite(res.data.data);
  //     })
  //     .catch((res) => {})
  //     .finally((res) => {});

  const getInitialData = () => {
    setIsPageLoading(true);

    Axios.all([
      AnalisaBarangJadiApi.getSingle({
        id_barang_jadi: id,
        ref_uid: ref_uid ? ref_uid : '',
        tipe: 'umum',
      }),
      // AnalisaBarangJadiApi.getKayu(),
      AnalisaBarangJadiApi.getKelompokTipeBarangJadi(),
      AnalisaBarangJadiApi.getTipeBarangJadi(),
      AnalisaBarangJadiApi.getFinishingBarangJadi(),
      AnalisaBarangJadiApi.getSingle({ id_barang_jadi: id, tipe: 'gambar' }),
      AnalisaBarangJadiApi.getDropdownTipeSisi(),
      AnalisaBarangJadiApi.getDropdownTipeFinishing(),
      AnalisaBarangJadiApi.getDropdownSpekKayu(),
      AnalisaBarangJadiApi.getDropdownSpekPlywood(),
    ])
      .then(
        Axios.spread(
          (
            barang,
            kelompokTipe,
            tipeBarang,
            finishing,
            img,
            tipeSisi,
            tipeFinishing,
            spekKayu,
            spekPlywood
          ) => {
            setDataAnalisaBarangJadi(barang.data.data);
            // setDataKayu(kayu.data.data);
            setDataKelompokTipeBarangJadi(kelompokTipe.data.data);
            setDataTipeBarangJadi(tipeBarang.data.data);
            setDataFinishingBarangJadi(finishing.data.data);
            setFilesUpload(img?.data?.data?.gambar ?? []);

            setDropdownTipeSisi(tipeSisi?.data?.data);
            setDropdownTipeFinishing(tipeFinishing?.data.data);
            setDropdownSpekKayu(spekKayu.data.data);
            setDropdownSpekPlywood(spekPlywood.data.data);
          }
        )
      )
      .catch(() => {
        setIsFetchingFailed(false);
      })
      .finally(() => getBahanBaku())
      .finally(() => getPenunjangProduksi())
      .finally(() => {
        getFinishing();
        setIsPageLoading(false);
      });
    // .finally(() => {
    //   getOnSite();
    //   setIsPageLoading(false);
    // });
  };

  // const DataPrint = () => {
  //   setDataPrint({
  //     ...dataAnalisaBarangJadi,
  //     analisa_hardwood: dataBahanBaku.analisa_hardwood.map((val) => ({
  //       id_barang_jadi: val.id_barang_jadi,
  //       urutan_item: val.urutan_item,
  //       deskripsi: val.deskripsi,
  //       is_header: val.is_header,
  //       id_kayu: val.id_kayu,
  //       qty_raw: val.qty_raw,
  //       t_raw: val.t_raw,
  //       w_raw: val.w_raw,
  //       l_raw: val.l_raw,
  //       qty_final: val.qty_final,
  //       t_final: val.t_final,
  //       w_final: val.w_final,
  //       l_final: val.l_final,
  //       unit_price: val.unit_price,
  //       konstanta: val.konstanta,
  //     })),
  //   });
  // };

  useEffect(() => {
    getInitialData();

    return () => {};
  }, []);

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <b>{value}</b>
      </div>
    );

    const InfoItemUraian = ({ title, value }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <div>{value}</div>
      </div>
    );

    const InfoItemLink = ({ title, value, text }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <b>
          <a href={value} target="_blank">
            {text}
          </a>
        </b>
      </div>
    );

    return (
      <>
        <Row>
          <Col>
            <InfoItem
              title="Kode Barang Jadi"
              value={
                dataAnalisaBarangJadi.kode_item
                  ? dataAnalisaBarangJadi.kode_item
                  : '-'
              }
            />
            <InfoItem
              title="Nama Barang Jadi"
              value={
                dataAnalisaBarangJadi.nama_item
                  ? dataAnalisaBarangJadi.nama_item
                  : '-'
              }
            />
            <InfoItem
              title="Jenis Barang Jadi"
              value={
                dataAnalisaBarangJadi.nama_jenis
                  ? dataAnalisaBarangJadi.nama_jenis
                  : '-'
              }
            />
            <InfoItem
              title="Satuan Jual"
              value={
                dataAnalisaBarangJadi.nama_satuan
                  ? dataAnalisaBarangJadi.nama_satuan
                  : '-'
              }
            />
            {dataAnalisaBarangJadi.link_referensi ? (
              <InfoItemLink
                title="Link Referensi"
                value={dataAnalisaBarangJadi.link_referensi}
                text="Lihat Referensi"
              />
            ) : (
              <InfoItem title="Link Referensi" value="-" />
            )}
          </Col>
          <Col>
            <InfoItemUraian
              title="Uraian"
              value={
                dataAnalisaBarangJadi.uraian
                  ? htmlParser(dataAnalisaBarangJadi.uraian)
                  : '-'
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FilesUpload
              filesUpload={filesUpload}
              setFilesUpload={setFilesUpload}
              action="detail"
            />
          </Col>
        </Row>
      </>
    );
  };

  const TableSection = () => {
    const [dataHardwood, setDataHardwood] = useState([]);
    const [dataPlywood, setDataPlywood] = useState([]);
    const [dataTipe, setDataTipe] = useState([]);
    const [dataTPFS, setDataTPFS] = useState([]);
    const [dataTPLC, setDataTPLC] = useState([]);
    const [dataTPMP, setDataTPMP] = useState([]);
    const [dataTPBOP, setDataTPBOP] = useState([]);
    const [dataPrelim, setDataPrelim] = useState([]);
    const [dataKelompokPrelim, setDataKelompokPrelim] = useState([]);
    const [dataSelectTPFS, setDataSelectTPFS] = useState([]);
    const [dataSelectTPLC, setDataSelectTPLC] = useState([]);
    const [dataSelectTPMP, setDataSelectTPMP] = useState([]);
    const [dataSelectTPBOP, setDataSelectTPBOP] = useState([]);
    const [dataFNBP, setDataFNBP] = useState([]);
    const [dataFNSC, setDataFNSC] = useState([]);
    const [dataFNLC, setDataFNLC] = useState([]);
    const [dataSelectFNBP, setDataSelectFNBP] = useState([]);
    const [dataSelectFNSC, setDataSelectFNSC] = useState([]);
    const [dataSelectFNLC, setDataSelectFNLC] = useState([]);
    const [kelompokTipe, setKelompokTipe] = useState({});
    const [tipeBarang, setTipeBarang] = useState({});
    const [finishingBarang, setFinishingBarang] = useState({});
    const [dataMPOS, setDataMPOS] = useState([]);
    const [dataLCOS, setDataLCOS] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState({
      tipe: false,
      finishing: false,
      prelim: false,
    });
    const [tabs, setTabs] = useState('bahan_baku'); //State untuk menampung tabs yang aktif

    // fungsi mengambil id tipe finishing yang unique
    const getUniqueTipeFinishing = () => {
      const listBahanBaku = [...dataHardwood, ...dataPlywood];
      let uniqueTipeFinishing = []; // menyimpan obj unique id tipe finishing

      // cari unique id tipe finishing
      listBahanBaku.map((item) => {
        if (
          item.id_finishing_barang_jadi !== '0' &&
          Boolean(!uniqueTipeFinishing.includes(item.id_finishing_barang_jadi))
        ) {
          uniqueTipeFinishing.push(item.id_finishing_barang_jadi);
        }
      });

      uniqueTipeFinishing = uniqueTipeFinishing.map((item) => {
        return dropdownTipeFinishing.find(
          (val) => val.id_finishing_barang_jadi === item
        );
      });

      return uniqueTipeFinishing;
    };

    // fungsi mengambil total luas berdasarkan id tipe finishing yang unique (digunaka sbg konst pada tab 3)
    // fungsi mengambil total luas berdasarkan id tipe finishing yang unique (digunaka sbg konst pada tab 3)
    const getTotalLuasTipeFinishing = () => {
      const uniqueTipeFinishing = getUniqueTipeFinishing();
      const listBahanBaku = [...dataHardwood, ...dataPlywood];
      let totalLuasTipeFinishing = []; // menyimpan total luas tiap tipe finishing

      // hitung total luas tiap tipe finishing
      uniqueTipeFinishing.map((val) => {
        const totalLuas = listBahanBaku
          .filter(
            (item) =>
              item?.id_finishing_barang_jadi === val?.id_finishing_barang_jadi
          )
          .reduce(
            (acc, { total_luas }) => acc + parseFloat(total_luas ?? 0),
            0
          );
        totalLuasTipeFinishing.push({
          ...val,
          total_luas_tipe_finishing: parseFloat(totalLuas).toFixed(6),
        });
      });
      return totalLuasTipeFinishing;
    };

    const getDetailDataTipe = (id_tipe) => {
      setLoading({
        ...loading,
        tipe: true,
      });

      AnalisaBarangJadiApi.getSingleTipeBarangJadi({
        id_tipe_barang_jadi: id_tipe,
      })
        .then((val) => {
          const filterData = (data) => {
            const newData = [];
            data &&
              data.length > 0 &&
              data.map((val, index) => {
                newData.push({
                  id_barang_jadi: id,
                  id_item_buaso: val.id_item_buaso,
                  qty: val.qty,
                  unit_price: val.harga_satuan ? parseInt(val.harga_satuan) : 0,
                  konstanta: val.koefisien,
                  kode_item_bahan: val.kode_item_bahan,
                  nama_item: val.nama_item_bahan,
                  nama_satuan: val.nama_satuan,
                  urutan_item: index + 1,
                });
              });
            return newData;
          };

          const data = val.data.data.detail;
          const newDataTPFS = filterData(data.fs);
          const newDataTPLC = filterData(data.lc);
          const newDataTPMP = filterData(data.mp);
          const newDataTPBOP = filterData(data.bop);

          setDataTPFS(newDataTPFS);
          setDataTPLC(newDataTPLC);
          setDataTPMP(newDataTPMP);
          setDataTPBOP(newDataTPBOP);
        })
        .catch(() => {
          window.alert('Gagal memuat data sub tipe produksi');
          setDataTPFS([]);
          setDataTPLC([]);
          setDataTPMP([]);
          setDataTPBOP([]);
        })
        .finally(() =>
          setLoading({
            ...loading,
            tipe: false,
          })
        );
    };

    const getDetailDataFinishing = (id_tipe) => {
      setLoading({
        ...loading,
        finishing: true,
      });

      AnalisaBarangJadiApi.getSingleFinishingBarangJadi({
        id_finishing_barang_jadi: id_tipe,
      })
        .then((val) => {
          const filterData = (data) => {
            const newData = [];
            data &&
              data.length > 0 &&
              data.map((val, index) => {
                newData.push({
                  id_barang_jadi: id,
                  id_item_bahan: val.id_item_bahan,
                  qty: val.qty,
                  unit_price: val.harga_satuan ? parseInt(val.harga_satuan) : 0,
                  konstanta: val.koefisien,
                  kode_item_bahan: val.kode_item_bahan,
                  nama_item: val.nama_item,
                  nama_satuan: val.nama_satuan,
                  urutan_item: index + 1,
                });
              });
            return newData;
          };

          const data = val.data.data.detail;
          const newDataFNBP = filterData(data.bp);
          const newDataFNLC = filterData(data.lc);
          const newDataFNSC = filterData(data.sc);

          setDataFNBP(newDataFNBP);
          setDataFNLC(newDataFNLC);
          setDataFNSC(newDataFNSC);
        })
        .catch(() => {
          window.alert('Gagal memuat data tipe finishing');
          setDataFNBP([]);
          setDataFNLC([]);
          setDataFNSC([]);
        })
        .finally(() =>
          setLoading({
            ...loading,
            finishing: false,
          })
        );
    };

    const filterDataHardwood = () => {
      let finalData = [];
      const data = dataBahanBaku.analisa_hardwood;
      const sorting = data.sort((a, b) => {
        if (a.urutan_item < b.urutan_item) return -1;
        if (a.urutan_item > b.urutan_item) return 1;

        return 0;
      });

      sorting.map((val) => {
        finalData.push({
          id_barang_jadi: id,
          urutan_item: val.urutan_item,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          // nama_kayu: val.nama_kayu,
          // id_kayu: val.id_kayu,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          qty_final: val.qty_final,
          t_final: val.t_final,
          w_final: val.w_final,
          l_final: val.l_final,
          unit_price: val.harga_satuan,
          konstanta: val.koefisien,
          id_jenis_kayu: val.id_jenis_kayu,
          nama_jenis_kayu: val.nama_jenis_kayu,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
          nama_finishing_barang_jadi: val.nama_finishing_barang_jadi,
          id_tipe_sisi: val.id_tipe_sisi,
          nama_tipe_sisi: val.nama_tipe_sisi,
        });
      });

      setDataHardwood(finalData);
    };

    const filterDataPlywood = () => {
      let finalData = [];
      const data = dataBahanBaku.analisa_plywood;
      const sorting = data.sort((a, b) => {
        if (a.urutan_item < b.urutan_item) return -1;
        if (a.urutan_item > b.urutan_item) return 1;

        return 0;
      });

      sorting.map((val) => {
        finalData.push({
          id_barang_jadi: id,
          urutan_item: val.urutan_item,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          // nama_kayu: val.nama_kayu,
          // id_kayu: val.id_kayu,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          unit_price: val.harga_satuan,
          konstanta: val.koefisien,
          kode_item_bahan: val.kode_item_bahan,
          nama_satuan: val.nama_satuan,
          id_item_buaso: val.id_plywood,
          nama_item: val.nama_plywood,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
          nama_finishing_barang_jadi: val.nama_finishing_barang_jadi,
          id_tipe_sisi: val.id_tipe_sisi,
          nama_tipe_sisi: val.nama_tipe_sisi,
        });
      });

      setDataPlywood(finalData);
    };

    const filterDataTipeBarangJadi = () => {
      const filterData = (data) => {
        const newData = [];
        data &&
          data.length > 0 &&
          data.map((val, index) => {
            newData.push({
              id_barang_jadi: val.id_barang_jadi,
              id_item_buaso: val.id_item_bahan,
              qty: val.qty,
              unit_price: val.harga_satuan,
              konstanta: val.koefisien,
              kode_item_bahan: val.kode_item_bahan,
              nama_item: val.nama_item,
              nama_satuan: val.nama_satuan,
              urutan_item: index + 1,
            });
          });
        return newData;
      };

      const newDataTPFS = filterData(dataBahanPenunjang.analisa_fin_tp_fs);
      const newDataTPLC = filterData(dataBahanPenunjang.analisa_fin_tp_lc);
      const newDataTPMP = filterData(dataBahanPenunjang.analisa_fin_tp_mp);
      const newDataTPBOP = filterData(dataBahanPenunjang.analisa_fin_tp_bop);
      const newDataLCOS = filterData(dataOnSite.analisa_fin_os_lc);
      const newDataMPOS = filterData(dataOnSite.analisa_fin_os_mp);

      setDataTPFS(newDataTPFS);
      setDataTPLC(newDataTPLC);
      setDataTPMP(newDataTPMP);
      setDataTPBOP(newDataTPBOP);
      setDataLCOS(newDataLCOS);
      setDataMPOS(newDataMPOS);
    };

    const filterDataFinishingBarangJadi = () => {
      const filterData = (data) => {
        const newData = [];
        data &&
          data.length > 0 &&
          data.map((val, index) => {
            newData.push({
              id_analisa_barang_jadi_tipe_fs:
                val.id_analisa_barang_jadi_tipe_fs,
              id_barang_jadi: val.id_barang_jadi,
              id_item_bahan: val.id_item_bahan,
              id_finishing_barang_jadi: val.id_finishing_barang_jadi,
              qty: val.qty,
              unit_price: val.harga_satuan,
              konstanta: val.koefisien,
              kode_item_bahan: val.kode_item_bahan,
              nama_item: val.nama_item,
              nama_satuan: val.nama_satuan,
              nama_finishing_barang_jadi: val.nama_finishing_barang_jadi,
              urutan_item: index + 1,
            });
          });
        return newData;
      };

      const newDataFNBP = filterData(dataFinishing.analisa_fin_fn_bp);
      const newDataFNSC = filterData(dataFinishing.analisa_fin_fn_sc);
      const newDataFNLC = filterData(dataFinishing.analisa_fin_fn_lc);

      setDataFNBP(newDataFNBP);
      setDataFNSC(newDataFNSC);
      setDataFNLC(newDataFNLC);
    };

    const filterDataPrelim = (data) =>
      data.map((val, index) => {
        return {
          id_kelompok: val.id_kelompok,
          id_item: val.id_item_buaso,
          kode_item: val.kode_item,
          nama_item: val.nama_item,
          qty_item: val.qty_analisa,
          id_unit: val.id_satuan,
          nama_unit: val.nama_satuan,
          harga: val.unit_price,
          qty_durasi: val.qty_durasi,
          konstanta: val.konstanta,
          order: index,
        };
      });

    const mappingHardwood = () => {
      const final = [];

      dataHardwood.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          urutan_item: val.urutan_item,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          // id_kayu: val.id_kayu,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          qty_final: val.qty_final,
          t_final: val.t_final,
          w_final: val.w_final,
          l_final: val.l_final,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
          id_jenis_kayu: val.id_jenis_kayu,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
          id_tipe_sisi: val.id_tipe_sisi ? val.id_tipe_sisi : null,
        });
      });

      return final;
    };

    const mappingPlywood = () => {
      const final = [];

      dataPlywood.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          urutan_item: val.urutan_item,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          // id_kayu: val.id_kayu,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
          id_item_buaso: val.id_item_buaso,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
          id_tipe_sisi: val.id_tipe_sisi,
        });
      });

      return final;
    };

    const mappingTP = (value) => {
      const final = [];
      value.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          id_item_buaso: val.id_item_buaso,
          qty: val.qty,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
        });
      });

      return final;
    };

    const mappingFN = (value) => {
      const final = [];
      value.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          id_item_buaso: val.id_item_bahan,
          qty: val.qty,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi ?? null,
        });
      });

      return final;
    };

    const mappingSubmitPrelim = (data) =>
      data.map((val) => {
        return {
          id_barang_jadi: id,
          id_item_buaso: val.id_item,
          qty_analisa: val.qty_item,
          unit_price: val.harga,
          qty_durasi: val.qty_durasi,
          id_satuan_durasi: val.id_unit,
          konstanta: val.konstanta,
        };
      });

    const getGrandTotal = () => {
      let totalHardwood = 0;
      let totalPlywood = 0;
      let totalTPFS = 0;
      let totalTPLC = 0;
      let totalTPMP = 0;
      let totalTPBOP = 0;
      let totalFNBP = 0;
      let totalFNSC = 0;
      let totalFNLC = 0;
      let totalLCOS = 0;
      let totalMPOS = 0;

      const totalHW = dataHardwood.reduce((prev, val) => {
        const countTotalUnit = parseFloat(
          (parseFloat(val.qty_raw).toFixed(6) *
            parseFloat(val.t_raw).toFixed(6) *
            parseFloat(val.w_raw).toFixed(6) *
            parseFloat(val.l_raw).toFixed(6)) /
            1000000
        ).toFixed(6);

        const totalUnit =
          parseFloat(countTotalUnit) < 0.000001
            ? '0.000001'
            : parseFloat(countTotalUnit);
        const totalPrice = Math.round(
          parseFloat(totalUnit) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta)
        );
        const checkPrice = val.is_header ? 0 : totalPrice;
        totalHardwood = parseInt(checkPrice);

        return prev + totalHardwood;
      }, 0);

      // const totalPW = dataPlywood.reduce((prev, val) => {
      //   const countTotalUnit = parseFloat(
      //     (parseFloat(val.qty_raw).toFixed(6) *
      //       parseFloat(val.t_raw).toFixed(6) *
      //       parseFloat(val.w_raw).toFixed(6) *
      //       parseFloat(val.l_raw).toFixed(6)) /
      //     1000000
      //   ).toFixed(6);
      //   const totalUnit =
      //     parseFloat(countTotalUnit) < 0.000001 ? "0.000001" : parseFloat(countTotalUnit);
      //   const totalPrice = Math.round(
      //     parseFloat(totalUnit) * parseInt(val.unit_price) * parseFloat(val.konstanta)
      //   );
      //   const checkPrice = val.is_header ? 0 : totalPrice;
      //   totalPlywood = parseInt(checkPrice);
      //   return prev + totalPlywood;
      // }, 0);

      dataPlywood.map((val) => {
        const total = Math.round(
          parseFloat(val.qty_raw).toFixed(6) *
            parseInt(val.unit_price ?? 0) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalPlywood = parseInt(totalPlywood) + parseInt(total || 0);
      });

      dataTPFS.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalTPFS = parseInt(totalTPFS) + parseInt(total);
      });

      dataTPLC.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalTPLC = parseInt(totalTPLC) + parseInt(total);
      });

      dataTPMP.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalTPMP = parseInt(totalTPMP) + parseInt(total);
      });

      dataTPBOP.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalTPBOP = parseInt(totalTPBOP) + parseInt(total);
      });

      dataFNBP.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalFNBP = parseInt(totalFNBP) + parseInt(total);
      });

      dataFNSC.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalFNSC = parseInt(totalFNSC) + parseInt(total);
      });

      dataFNLC.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalFNLC = parseInt(totalFNLC) + parseInt(total);
      });

      dataLCOS.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalLCOS = parseInt(totalLCOS) + parseInt(total);
      });

      dataMPOS.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalMPOS = parseInt(totalMPOS) + parseInt(total);
      });

      const totalPrelim = dataPrelim.reduce((prev, current) => {
        const newQtyItem = current.qty_item ? parseFloat(current.qty_item) : 0;
        const newHarga = current.harga ? parseFloat(current.harga) : 0;
        const newQtyDurasi = current.qty_durasi
          ? parseFloat(current.qty_durasi)
          : 0;
        const newKonts = current.konstanta ? parseFloat(current.konstanta) : 0;
        const subTotal = newQtyItem * newHarga;
        const grandTotal = subTotal * newQtyDurasi * newKonts;

        return prev + grandTotal;
      }, 0);

      const grandTotal =
        parseInt(totalHW) +
        parseInt(totalPlywood) +
        parseInt(totalTPFS) +
        parseInt(totalTPLC) +
        parseInt(totalTPMP) +
        parseInt(totalTPBOP) +
        parseInt(totalFNBP) +
        parseInt(totalFNSC) +
        parseInt(totalFNLC) +
        parseInt(totalPrelim);

      return RupiahConvert(grandTotal.toString()).detail;
    };

    const getSelectData = async () => {
      setLoading({
        tipe: true,
        finishing: true,
        prelim: true,
      });

      return await Axios.all([
        AnalisaBarangJadiApi.getDropdownTipeBarangJadi({ tipe: 'fs' }),
        AnalisaBarangJadiApi.getDropdownTipeBarangJadi({ tipe: 'lc' }),
        AnalisaBarangJadiApi.getDropdownTipeBarangJadi({ tipe: 'mp' }),
        AnalisaBarangJadiApi.getDropdownTipeBarangJadi({ tipe: 'bop' }),
        AnalisaBarangJadiApi.getDropdownFinishingBarangJadi({ tipe: 'bp' }),
        AnalisaBarangJadiApi.getDropdownFinishingBarangJadi({ tipe: 'sc' }),
        AnalisaBarangJadiApi.getDropdownFinishingBarangJadi({ tipe: 'lc' }),
      ])
        .then(
          Axios.spread((tpfs, tplc, tpmp, tpbop, fnbp, fnsc, fnlc) => {
            setDataSelectTPFS(tpfs?.data?.data ?? []);
            setDataSelectTPLC(tplc?.data?.data ?? []);
            setDataSelectTPMP(tpmp?.data?.data ?? []);
            setDataSelectTPBOP(tpbop?.data?.data ?? []);
            setDataSelectFNBP(fnbp?.data?.data ?? []);
            setDataSelectFNSC(fnsc?.data?.data ?? []);
            setDataSelectFNLC(fnlc?.data?.data ?? []);
          })
        )
        .catch(() =>
          window.alert(
            'Data dropdown tipe produksi dan finishing gagal diperoleh!'
          )
        )
        .finally(() =>
          setLoading({
            ...loading,
            tipe: false,
            finishing: false,
          })
        );
    };

    const getPrelim = async () => {
      setLoading({ ...loading, prelim: true });

      return await Axios.all([
        AnalisaBarangJadiApi.getSingle({
          id_barang_jadi: id,
          ref_uid: ref_uid ? ref_uid : '',
          tipe: 'prelim',
        }),
        AnalisaBarangJadiApi.getKelompokPrelim(),
      ])
        .then(
          Axios.spread((prelim, kelompokPrelim) => {
            const mapDataPrelim = filterDataPrelim(
              prelim?.data?.data?.analisa_prelim ?? []
            );

            setDataPrelim(mapDataPrelim);
            setDataKelompokPrelim(kelompokPrelim?.data?.data ?? []);
          })
        )
        .catch(() =>
          window.alert('Data kelompok prelim dan prelim gagal dimuat!')
        )
        .finally(() => {
          setLoading({ ...loading, prelim: false });
        });
    };

    const submitDataHandler = () => {
      setIsSubmitting(true);

      const analisaHardwood = mappingHardwood();
      const analisaPlywood = mappingPlywood();
      const analisaFinTpFs = mappingTP(dataTPFS);
      const analisaFinTpLc = mappingTP(dataTPLC);
      const analisaFinTpMp = mappingTP(dataTPMP);
      const analisaFinTpBop = mappingTP(dataTPBOP);
      const analisaFinFnBp = mappingFN(dataFNBP);
      const analisaFinFnSc = mappingFN(dataFNSC);
      const analisaFinFnLc = mappingFN(dataFNLC);
      const analisaFinOsLc = mappingTP(dataLCOS);
      const analisaFinOsMp = mappingTP(dataMPOS);
      const analisaPrelim = mappingSubmitPrelim(dataPrelim);

      const finalValues = ref_uid
        ? {
            ref_uid: ref_uid,
            id_barang_jadi: id,
            analisa_hardwood: analisaHardwood,
            analisa_plywood: analisaPlywood,
            analisa_fin_tp_fs: analisaFinTpFs,
            analisa_fin_tp_lc: analisaFinTpLc,
            analisa_fin_tp_mp: analisaFinTpMp,
            analisa_fin_tp_bop: analisaFinTpBop,
            analisa_fin_fn_bp: analisaFinFnBp,
            analisa_fin_fn_sc: analisaFinFnSc,
            analisa_fin_fn_lc: analisaFinFnLc,
            analisa_fin_os_mp: analisaFinOsMp,
            analisa_fin_os_lc: analisaFinOsLc,
            analisa_prelim: analisaPrelim,
          }
        : {
            id_barang_jadi: id,
            analisa_hardwood: analisaHardwood,
            analisa_plywood: analisaPlywood,
            analisa_fin_tp_fs: analisaFinTpFs,
            analisa_fin_tp_lc: analisaFinTpLc,
            analisa_fin_tp_mp: analisaFinTpMp,
            analisa_fin_tp_bop: analisaFinTpBop,
            analisa_fin_fn_bp: analisaFinFnBp,
            analisa_fin_fn_sc: analisaFinFnSc,
            analisa_fin_fn_lc: analisaFinFnLc,
            analisa_fin_os_mp: analisaFinOsMp,
            analisa_fin_os_lc: analisaFinOsLc,
            analisa_prelim: analisaPrelim,
          };

      AnalisaBarangJadiApi.save(finalValues)
        .then((res) => {
          const ref = res.data.data.ref_uid;
          const addRef = {
            ...processedData,
            harga_satuan: RupiahConvert(getGrandTotal().toString()).default,
            ref_uid: ref,
          };
          const finalData = dataBarangJadi.map((val, index) =>
            index.toString() === processedData.index.toString() ? addRef : val
          );

          setDataBarangJadi(finalData);

          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Referensi analisa barang jadi berhasil disimpan!',
          });
        })
        .catch(() =>
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Referensi analisa barang jadi gagal disimpan!',
          })
        )
        .finally(() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        );
    };

    useEffect(() => {
      getSelectData().finally(async () => await getPrelim());
      filterDataHardwood();
      filterDataPlywood();
      filterDataTipeBarangJadi();
      filterDataFinishingBarangJadi();
    }, []);

    useEffect(() => {
      getTotalLuasTipeFinishing();
    }, [dataPlywood, dataHardwood]);

    const SelectKelompokTipeBarangJadi = ({
      kelompokTipe,
      setKelompokTipe,
      dataTipe,
      setDataTipe,
      tipeBarang,
      setTipeBarang,
    }) => {
      return (
        <div className="d-flex justify-content-end mt-3">
          <div className="row col-md-7">
            <div className="col-md-6">
              <SelectSearch
                label="Tipe Produksi"
                placeholder="Pilih tipe produksi"
                className="m-1"
                defaultValue={kelompokTipe.value ? kelompokTipe : ''}
                option={dataKelompokTipeBarangJadi.map((val) => {
                  return {
                    value: val.id_kelompok_tipe_barang_jadi,
                    label: val.nama_kelompok_barang_jadi,
                  };
                })}
                onChange={(val) => {
                  const id = val.value;
                  setKelompokTipe(val);
                  const filter = dataTipeBarangJadi.filter(
                    (val) => val.id_kelompok_tipe_barang_jadi === id
                  );
                  setDataTipe(filter);
                  setTipeBarang([]);
                }}
              />
            </div>
            <div className="col-md-6">
              <SelectSearch
                label="Sub Tipe Produksi"
                placeholder="Pilih sub tipe produksi"
                className="m-1"
                defaultValue={tipeBarang.value ? tipeBarang : ''}
                option={dataTipe.map((val) => {
                  return {
                    value: val.id_tipe_barang_jadi,
                    label: val.nama_tipe_barang_jadi,
                  };
                })}
                onChange={(val) => {
                  setTipeBarang(val);
                  getDetailDataTipe(val.value);
                }}
                isDisabled={kelompokTipe.value ? false : true}
              />
            </div>
          </div>
        </div>
      );
    };

    const SelectFinishingBarangJadi = ({
      finishingBarang,
      setFinishingBarang,
      dataFinishingBarangJadi,
    }) => {
      return (
        <div className="d-flex justify-content-end mt-3">
          <div className="col-md-5">
            <SelectSearch
              label="Tipe Finishing"
              placeholder="Pilih tipe finishing"
              className="m-1"
              defaultValue={finishingBarang.value ? finishingBarang : ''}
              option={dataFinishingBarangJadi.map((val) => {
                return {
                  value: val.id_finishing_barang_jadi,
                  label: val.nama_finishing_barang_jadi,
                };
              })}
              onChange={(val) => {
                const id = val.value;
                setFinishingBarang(val);
                getDetailDataFinishing(id);
              }}
            />
          </div>
        </div>
      );
    };

    const GrandTotalSection = () => (
      <div className="p-2 bg-white text-right border">
        <b className="text-nowrap pr-3" style={{ fontSize: '14px' }}>
          Grand Total : {getGrandTotal()}
        </b>
      </div>
    );

    // Konfigurasi komponen tabs
    const tabsConfig = [
      {
        tab: 'bahan_baku',
        label: 'Analisa Bahan Baku',
        component: () => (
          <>
            <TableListHardwood
              dataKayu={dataKayu.filter(
                (kayu) => kayu.tipe_kayu.toUpperCase() === 'HARDWOOD'
              )}
              dataHardwood={dataHardwood}
              setDataHardwood={setDataHardwood}
              dropdownTipeSisi={dropdownTipeSisi}
              dropdownTipeFinishing={dropdownTipeFinishing}
              dropdownSpekKayu={dropdownSpekKayu}
            />
            <TableListPlywood
              dataKayu={dataKayu.filter(
                (kayu) => kayu.tipe_kayu.toUpperCase() === 'PLYWOOD'
              )}
              dataPlywood={dataPlywood}
              setDataPlywood={setDataPlywood}
              dropdownTipeSisi={dropdownTipeSisi}
              dropdownTipeFinishing={dropdownTipeFinishing}
              dropdownSpekPlywood={dropdownSpekPlywood}
            />
            <GrandTotalSection />
          </>
        ),
      },
      {
        tab: 'penunjang',
        label: 'Analisa Penunjang Produksi',
        component: () => (
          <>
            <SelectKelompokTipeBarangJadi
              dataTipe={dataTipe}
              setDataTipe={setDataTipe}
              kelompokTipe={kelompokTipe}
              setKelompokTipe={setKelompokTipe}
              tipeBarang={tipeBarang}
              setTipeBarang={setTipeBarang}
            />
            {loading.tipe ? (
              <div style={{ height: '800px' }}>
                <DataStatus
                  loading={true}
                  text="Memuat data sub tipe produksi"
                />
              </div>
            ) : (
              <>
                <TableListTPFS
                  dataSelectTPFS={dataSelectTPFS}
                  dataTPFS={dataTPFS}
                  setDataTPFS={setDataTPFS}
                  dataHardwood={dataHardwood}
                />
                <TableListTPLC
                  dataSelectTPLC={dataSelectTPLC}
                  dataTPLC={dataTPLC}
                  setDataTPLC={setDataTPLC}
                  dataHardwood={dataHardwood}
                />
                <TableListTPMP
                  dataSelectTPMP={dataSelectTPMP}
                  dataTPMP={dataTPMP}
                  setDataTPMP={setDataTPMP}
                  dataHardwood={dataHardwood}
                />
                <TableListTPBOP
                  dataSelectTPBOP={dataSelectTPBOP}
                  dataTPBOP={dataTPBOP}
                  setDataTPBOP={setDataTPBOP}
                  dataHardwood={dataHardwood}
                />
                <GrandTotalSection />
              </>
            )}
          </>
        ),
      },
      {
        tab: 'finishing',
        label: 'Analisa Finshing',
        component: () => {
          let totalLuasTipeFinishing = getTotalLuasTipeFinishing();
          return (
            <>
              {totalLuasTipeFinishing.map((val) => {
                return (
                  <div className="mt-2">
                    <h5 className="font-weight-bold pl-2">
                      {val.nama_finishing_barang_jadi}
                    </h5>
                    <TableListFNBP
                      id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                      dataSelectFNBP={dataSelectFNBP}
                      dataFNBP={dataFNBP}
                      setDataFNBP={setDataFNBP}
                      defaultKonst={val.total_luas_tipe_finishing}
                    />
                    <TableListFNSC
                      id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                      dataSelectFNSC={dataSelectFNSC}
                      dataFNSC={dataFNSC}
                      setDataFNSC={setDataFNSC}
                      defaultKonst={val.total_luas_tipe_finishing}
                    />
                    <TableListFNLC
                      id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                      dataSelectFNLC={dataSelectFNLC}
                      dataFNLC={dataFNLC}
                      setDataFNLC={setDataFNLC}
                      defaultKonst={val.total_luas_tipe_finishing}
                    />
                    <hr />
                  </div>
                );
              })}
              <GrandTotalSection />
            </>
          );
        },
      },
      {
        tab: 'prelim',
        label: 'Analisa Prelim',
        component: () => (
          <>
            {loading.prelim ? (
              <DataStatus loading text="Memuat data . . . " />
            ) : (
              dataKelompokPrelim.map((val) => (
                <TableListPrelim
                  dataKelompokPrelim={val}
                  dataPrelim={dataPrelim}
                  setDataPrelim={setDataPrelim}
                />
              ))
            )}
            <GrandTotalSection />
          </>
        ),
      },
    ];

    // Menangani perubahan pada tabs
    const onTabsChangeHandler = (e, newPage) => {
      e.preventDefault();
      setTabs(newPage);
    };

    useEffect(() => {
      setTabs(tabsConfig[0].tab);
    }, []);

    const TabsNav = ({ tab, label }) => (
      <Nav.Item>
        <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
          {label}
        </Nav.Link>
      </Nav.Item>
    );

    return (
      <Card>
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
            {tabsConfig.map((val, index) => (
              <TabsNav key={index} tab={val.tab} label={val.label} />
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {tabsConfig.map(
            ({ tab, component: Component }, index) =>
              tab === tabs && <Component key={index} />
          )}
          {/* <div className="col d-flex justify-content-end p-0">
            <div className="d-flex flex-column">
              <ActionButton
                text="Simpan Analisa Barang jadi"
                className="px-4 mt-4"
                onClick={submitDataHandler}
                loading={isSubmitting}
              />
            </div>
          </div> */}
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : isFetchingFailed ? (
        <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
      ) : (
        <>
          <InfoSection />
        </>
      )}
      {!isPageLoading && !isFetchingFailed && (
        <TableSection
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
        />
      )}
      {/* {!isPageLoading && !isFetchingFailed && (
        <div style={{ display: "none" }}>
          <PrintAnalisaBarangJadi
            ref={componentRef}
            dataAnalisaBarangJadi={dataAnalisaBarangJadi}
          />
        </div>
      )} */}
    </>
  );
};

export default FormAnalisaBarangJadi;

import { Services } from 'services';

class AnalisaBarangJadi {
  getSingle(params) {
    return Services.get('/analisa_barang_jadi_rab/single', { params });
  }

  getKayu(params) {
    return Services.get('/analisa_barang_jadi_rab/dropdown_kayu', { params });
  }

  getKelompokTipeBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rab/dropdown_kelompok_tipe', {
      params,
    });
  }

  getTipeBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rab/dropdown_tipe', { params });
  }

  getDropdownTipeBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rab/dropdown_tipe', { params });
  }

  getSingleTipeBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rab/dropdown_tipe', { params });
  }

  getFinishingBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rab/dropdown_finishing', {
      params,
    });
  }

  getDropdownFinishingBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rab/dropdown_finishing', {
      params,
    });
  }

  getSingleFinishingBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rab/dropdown_finishing', {
      params,
    });
  }

  getKelompokPrelim(params) {
    return Services.get('/analisa_barang_jadi_rab/list_kelompok_prelim', {
      params,
    });
  }

  getDropdownTipeSisi() {
    return Services.get('/analisa_barang_jadi_rab/d_tipe_sisi');
  }

  getItemPrelim(params) {
    return Services.get('/analisa_barang_jadi_rab/dropdown_item_prelim', {
      params,
    });
  }

  getDropdownTipeFinishing() {
    return Services.get('/analisa_barang_jadi_rab/d_finishing');
  }

  getDropdownSpekKayu() {
    return Services.get('/analisa_barang_jadi_rab/d_jenis_kayu');
  }

  getDropdownSpekPlywood() {
    return Services.get('/analisa_barang_jadi_rab/d_plywood');
  }

  getHargaKubikasiKayu(params) {
    return Services.get('/analisa_barang_jadi_rab/harga_kubikasi', { params });
  }

  getKelompokPrelim(params) {
    return Services.get('/analisa_barang_jadi_rae/list_kelompok_prelim', {
      params,
    });
  }

  getItemPrelim(params) {
    return Services.get('/analisa_barang_jadi_rae/dropdown_item_prelim', {
      params,
    });
  }

  save(data) {
    return Services.post('/analisa_barang_jadi_rab', data);
  }

  uploadFile(data) {
    return Services.post('/analisa_barang_jadi_rab/gambar', data);
  }

  deleteFile(data) {
    return Services.post('/analisa_barang_jadi_rab/gambar_delete', data);
  }
}

export default new AnalisaBarangJadi();

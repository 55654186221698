import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { Alert, Td } from 'components';
import { RupiahConvert } from 'utilities';
import AnalisaBarangJadiPenawaran from '../AnalisaBarangJadiPenawaran';
import { ModalPrelim } from '../Section';

const TablePenawaran = ({
  dataBarangJadi,
  dataPrelim,
  setDataBarangJadi,
  setTotalPenawaran,
  totalPenawaran,
  ppn,
  diskon,
  setFieldValue,
  type: TYPE,
}) => {
  const [processedData, setProcessedData] = useState({});
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });
  const [modalPrelim, setModalPrelim] = useState({
    show: false,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const tableStyling = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle',
  };

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '');
    const convert = newValue.substring(0, 5);
    return convert;
  };

  const getGrandTotalPrelim = dataPrelim.reduce((prev, current) => {
    const newQtyItem = current.qty_item ? parseFloat(current.qty_item) : 0;
    const newHarga = current.harga ? parseFloat(current.harga) : 0;
    const newQtyDurasi = current.qty_durasi
      ? parseFloat(current.qty_durasi)
      : 0;
    const newKonts = current.konstanta ? parseFloat(current.konstanta) : 0;
    const subTotal = newQtyItem * newHarga;
    // const total = subTotal * newQtyDurasi * newKonts;
    const total = subTotal;

    return prev + total;
  }, 0);

  // FUNGSI UNTUK HITUNG SUBTOTAL
  const hitungSubTotal = (index) => {
    let rounded = dataBarangJadi[index].rounded ?? 0;
    let qty = dataBarangJadi[index].qty_rab;
    let subTotal = rounded * qty;

    // SET HASIL SUBTOTAL KE DALAM OBJECT
    dataBarangJadi[index].subTotal = subTotal;
    return RupiahConvert(String(subTotal)).detail;
  };

  // FUNGSI HITUNG JUMLAH KESELURUHAN SUBTOTAL
  const hitungTotalPenawaran = () => {
    if (dataBarangJadi !== undefined) {
      let totalPenawaran = dataBarangJadi.reduce(function (
        accumulator,
        { subTotal }
      ) {
        return accumulator + subTotal;
      },
      0);

      totalPenawaran = totalPenawaran + getGrandTotalPrelim;

      // Hitung Dioskon
      let totalSetelahDiskon = (diskon * totalPenawaran) / 100;
      totalSetelahDiskon = parseInt(totalSetelahDiskon);

      let totalPenawaranDiskon =
        totalPenawaran - (totalPenawaran * diskon) / 100;
      totalPenawaranDiskon = parseInt(totalPenawaranDiskon);

      // Hitung PPN
      let totalSetelahPPN = (ppn * totalPenawaranDiskon) / 100;
      totalSetelahPPN = parseInt(totalSetelahPPN);

      let totalPenawaranPPN =
        totalPenawaranDiskon + (totalPenawaranDiskon * ppn) / 100;
      totalPenawaranPPN = parseInt(totalPenawaranPPN);

      // SET TOTAL SETELAH PAJAK
      setTotalPenawaran(totalPenawaranPPN);

      return {
        totalPenawaran,
        totalSetelahDiskon,
        totalPenawaranDiskon,
        totalSetelahPPN,
        totalPenawaranPPN,
      };
    }
  };

  const ModalSection = ({
    processedData,
    dataBarangJadi,
    setDataBarangJadi,
    alertConfig,
    setAlertConfig,
    modalConfig,
    setModalConfig,
  }) => {
    const AnalisaBarangJadiModal = ({
      processedData,
      dataBarangJadi,
      setDataBarangJadi,
      alertConfig,
      setAlertConfig,
      modalConfig,
      setModalConfig,
    }) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadiPenawaran
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            modalConfig={modalConfig}
            setAlertConfig={setAlertConfig}
            setModalConfig={setModalConfig}
          />
        </Modal.Body>
      );
    };

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size="xl"
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <AnalisaBarangJadiModal
          processedData={processedData}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      </Modal>
    );
  };

  return (
    <>
      <div className="p-2 mt-3">
        <b>List Item Penawaran</b>
      </div>
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />
      <div>
        <table className="table table-bordered bg-white table-sm">
          <thead className="text-center bg-light">
            <tr key="head1">
              <th rowSpan={2} className="align-middle" style={tableStyling}>
                No.
              </th>
              <th
                rowSpan={2}
                className="align-middle"
                style={{ ...tableStyling, width: '20px' }}
              >
                Kode Item
              </th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>
                Nama Item
              </th>
              <th colSpan={2} className="align-middle" style={tableStyling}>
                Volume
              </th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>
                Harga Satuan
              </th>
              <th
                rowSpan={2}
                className="align-middle"
                style={{ ...tableStyling, width: '121px' }}
              >
                Sub Total (Rp)
              </th>
            </tr>
            <tr key="head2">
              <th style={tableStyling}>Qty</th>
              <th style={tableStyling}>Unit</th>
            </tr>
          </thead>
          <tbody>
            {dataBarangJadi &&
              dataBarangJadi.length > 0 &&
              dataBarangJadi.map((val, index) => {
                return (
                  <tr key={index}>
                    <Td textCenter>{index + 1}</Td>
                    <Td>{val.kode_item}</Td>
                    <Td>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setProcessedData({
                            ...val,
                            index: index,
                          });
                          setModalConfig({
                            show: true,
                            type: 'analisa',
                            title: (
                              <span className="text-primary">
                                Analisa Barang Jadi Penawaran
                              </span>
                            ),
                          });
                        }}
                      >
                        {val.nama_item}
                      </a>
                    </Td>
                    <Td textRight>{parseFloat(val.qty_rab)}</Td>
                    <Td>{val.nama_satuan}</Td>
                    <Td textRight>
                      {RupiahConvert(String(parseInt(val.rounded))).detail}
                    </Td>
                    <Td textRight>{hitungSubTotal(index)}</Td>
                  </tr>
                );
              })}
            <tr>
              <Td textCenter className="pb-2">
                {dataBarangJadi && dataBarangJadi.length > 0
                  ? dataBarangJadi.length + 1
                  : 1}
              </Td>
              <Td>-</Td>
              <Td>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalPrelim({ show: true });
                  }}
                >
                  <b>PRELIM</b>
                </a>
              </Td>
              <Td className="text-right">1</Td>
              <Td>-</Td>
              <Td textRight>
                {RupiahConvert(String(parseInt(getGrandTotalPrelim))).detail}
              </Td>
              <Td textRight>
                {RupiahConvert(String(parseInt(getGrandTotalPrelim))).detail}
              </Td>
            </tr>
            <tr className="bg-light">
              <td colSpan={6} className="text-right p-2" style={tableStyling}>
                <b>Total: </b>
              </td>
              <td className="text-right text-nowrap" style={tableStyling}>
                {dataBarangJadi !== undefined ? (
                  <b>
                    {
                      RupiahConvert(
                        String(parseInt(hitungTotalPenawaran().totalPenawaran))
                      ).detail
                    }
                  </b>
                ) : (
                  ''
                )}
              </td>
            </tr>

            {/* Diskon */}
            <tr>
              <td colSpan={6} className="text-right p-2" style={tableStyling}>
                <b>Diskon (%) </b>
              </td>

              <td className="text-right text-nowrap" style={tableStyling}>
                {TYPE === 'DETAIL' ? (
                  <div>{diskon}</div>
                ) : (
                  <input
                    name="diskon"
                    className={`form-control form-control-sm no-spinner`}
                    type="number"
                    value={diskon}
                    min={0}
                    step="0.001"
                    presicion={2}
                    style={{ textAlign: 'right' }}
                    onChange={(e) => {
                      const value = e.target.value;
                      const convert = decimalConvert(value);
                      setFieldValue('diskon', convert);
                      // setPPn(convert)
                    }}
                  />
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={6} className="text-right p-2" style={tableStyling}>
                <b>Total Diskon</b>
              </td>
              <td className="text-right text-nowrap" style={tableStyling}>
                {dataBarangJadi !== undefined ? (
                  <b>
                    {
                      RupiahConvert(
                        String(
                          parseInt(hitungTotalPenawaran().totalSetelahDiskon)
                        )
                      ).detail
                    }
                  </b>
                ) : (
                  ''
                )}
              </td>
            </tr>
            <tr className="bg-light">
              <td colSpan={6} className="text-right p-2" style={tableStyling}>
                <b>Total Setelah Diskon: </b>
              </td>
              <td className="text-right text-nowrap" style={tableStyling}>
                {dataBarangJadi !== undefined ? (
                  <b>
                    {
                      RupiahConvert(
                        String(
                          parseInt(hitungTotalPenawaran().totalPenawaranDiskon)
                        )
                      ).detail
                    }
                  </b>
                ) : (
                  ''
                )}
              </td>
            </tr>

            {/* PPN */}
            <tr>
              <td colSpan={6} className="text-right p-2" style={tableStyling}>
                <b>PPN (%) </b>
              </td>

              <td className="text-right text-nowrap" style={tableStyling}>
                {TYPE === 'DETAIL' ? (
                  <div>{ppn}</div>
                ) : (
                  <input
                    name="ppn"
                    className={`form-control form-control-sm no-spinner`}
                    type="number"
                    value={ppn}
                    min={0}
                    style={{ textAlign: 'right' }}
                    onChange={(e) => {
                      const value = e.target.value;
                      const convert = decimalConvert(value);
                      setFieldValue('ppn', convert);
                      // setPPn(convert)
                    }}
                  />
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={6} className="text-right p-2" style={tableStyling}>
                <b>Total PPN</b>
              </td>
              <td className="text-right text-nowrap" style={tableStyling}>
                {dataBarangJadi !== undefined ? (
                  <b>
                    {
                      RupiahConvert(
                        String(parseInt(hitungTotalPenawaran().totalSetelahPPN))
                      ).detail
                    }
                  </b>
                ) : (
                  ''
                )}
              </td>
            </tr>
            <tr className="bg-light">
              <td colSpan={6} className="text-right p-2" style={tableStyling}>
                <b>Total Setelah PPN: </b>
              </td>
              <td className="text-right text-nowrap" style={tableStyling}>
                {dataBarangJadi !== undefined ? (
                  <b>
                    {
                      RupiahConvert(
                        String(
                          parseInt(hitungTotalPenawaran().totalPenawaranPPN)
                        )
                      ).detail
                    }
                  </b>
                ) : (
                  ''
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ModalSection
        processedData={processedData}
        dataBarangJadi={dataBarangJadi}
        setDataBarangJadi={setDataBarangJadi}
        alertConfig={alertConfig}
        setAlertConfig={setAlertConfig}
        modalConfig={modalConfig}
        setModalConfig={setModalConfig}
      />

      {/* Modal Prelim */}
      <ModalPrelim
        show={modalPrelim.show}
        onHide={() => setModalPrelim({ show: false })}
        dataPrelim={dataPrelim}
        onSubmit={(val) => {
          setModalPrelim({ show: false });
        }}
      />
    </>
  );
};

export default TablePenawaran;

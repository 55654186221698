import { useRef, useMemo, useState, useCallback } from 'react';
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  IoDownloadOutline,
  IoImageOutline,
  IoTrashOutline,
} from 'react-icons/io5';
import ImageViever from 'react-simple-image-viewer';
import FileSaver from 'file-saver';
import { ActionButton, DataStatus, DeleteModal, Alert } from 'components';

const TabGambar = ({ type: TYPE, dataGambar, setDataGambar, action }) => {
  const { REACT_APP_SHOW_FILE_BASE_URL, REACT_APP_DOWNLOAD_FILE_BASE_URL } =
    process.env;
  const inputFileRef = useRef();
  const [modalDeleteConfig, setModalDeleteConfig] = useState({
    index: '',
    show: false,
    loading: false,
  });
  const [viewImageConfig, setViewImageConfig] = useState({
    show: false,
    index: '',
  });

  // Filter data gambar agar array hanya terisi link gambar
  const filterImageHandler = useMemo(() => {
    return dataGambar.map((val) =>
      action === 'READ'
        ? REACT_APP_SHOW_FILE_BASE_URL + val.link
        : val.data
        ? val.link
        : REACT_APP_SHOW_FILE_BASE_URL + val.link
    );
  }, [dataGambar]);

  // Menampilkan ImageViewer
  const setIndexImageHandler = useCallback(
    (index) => {
      setViewImageConfig({
        show: true,
        index: index,
      });
    },
    [viewImageConfig]
  );

  // Trigger input file agar terclick
  const onClickFilesHandler = () =>
    TYPE !== 'DETAIL' && inputFileRef.current.click();

  // Menangani saat file dipilih
  const onChangeFileHandler = (e) => {
    const file = [...e.target.files];
    // // Check apakah ada file yang dipilih
    if (!file) return null;

    const getFileType = file.map((val) => val.name.split('.').pop());
    // Check jika type file sudah benar
    if (getFileType.some((e) => ['jpg', 'png', 'gif', 'jpeg'].includes(e))) {
      const finalValue = file.map((item) => {
        const generateName = item.name;
        const generateLink = URL.createObjectURL(item);
        return { data: item, nama: generateName, link: generateLink };
      });

      setDataGambar([...dataGambar, ...finalValue]);
    } else {
      window.alert(
        'File tidak valid! harap masukan gambar (.jpg, .png, .gif, .jpeg)'
      );
    }
  };

  // Menangani hapus file
  const onDeleteHandler = useCallback(() => {
    setModalDeleteConfig({ ...modalDeleteConfig, loading: true });
    const filterData = dataGambar.filter(
      (val, index) => index !== modalDeleteConfig.index
    );

    setTimeout(() => {
      setDataGambar(filterData);
      setModalDeleteConfig({
        index: '',
        show: false,
        loading: false,
      });
    }, 300);
  }, [modalDeleteConfig]);

  // Menampilkan komponen foto
  const Image = ({ imageText, imageUrl, onView, onDownload, onDelete }) => {
    const [isHover, setIsHover] = useState(false);

    return (
      <OverlayTrigger
        placement="bottom"
        flip={true}
        overlay={
          <Tooltip style={{ zIndex: 1 }}>
            {imageText ?? 'Foto Barang Jadi'}
          </Tooltip>
        }
      >
        <Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
          className="my-3"
          style={{ cursor: 'zoom-in', objectFit: 'cover' }}
          onClick={onView}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {/* Download Button */}
          <ActionButton
            size="sm"
            variant="primary"
            style={{ position: 'absolute', zIndex: 1, right: 20 }}
            className="m-2 shadow"
            onClick={onDownload}
          >
            <IoDownloadOutline />
          </ActionButton>

          {/* Delete Button */}
          {TYPE !== 'DETAIL' && (
            <ActionButton
              size="sm"
              variant="danger"
              style={{ position: 'absolute', zIndex: 1, top: 35, right: 20 }}
              className="m-2 shadow"
              onClick={onDelete}
            >
              <IoTrashOutline />
            </ActionButton>
          )}
          <img
            className={`img-fluid rounded border ${
              isHover ? 'shadow' : 'shadow-sm'
            }`}
            style={{
              height: 200,
              width: '100%',
              objectFit: 'cover',
              transform: isHover ? 'scale(1.03)' : 'none',
              transition: 'all 0.1s ease-in-out',
            }}
            src={imageUrl}
          />
        </Col>
      </OverlayTrigger>
    );
  };

  return (
    <div>
      {/* Button Section */}
      <div className="mb-3 text-right">
        <input
          ref={inputFileRef}
          type="file"
          accept="image/png, image/gif, image/jpeg, image/jpg"
          style={{ display: 'none' }}
          multiple
          onChange={onChangeFileHandler}
        />
        {TYPE !== 'DETAIL' && (
          <ActionButton text="Tambah Gambar" onClick={onClickFilesHandler} />
        )}
      </div>

      {/* Image Section */}

      <Container
        fluid
        className="bg-light rounded overflow-auto border"
        onClick={onClickFilesHandler}
        style={{ cursor: 'pointer', maxHeight: '60vh' }}
      >
        {dataGambar && dataGambar.length > 0 ? (
          <Row>
            {dataGambar.map((val, index) => {
              const showImage =
                action === 'READ'
                  ? REACT_APP_SHOW_FILE_BASE_URL + val.link
                  : val.data
                  ? val.link
                  : REACT_APP_SHOW_FILE_BASE_URL + val.link;
              const downloadImage =
                action === 'READ'
                  ? REACT_APP_DOWNLOAD_FILE_BASE_URL + val.link
                  : val.data
                  ? val.link
                  : REACT_APP_DOWNLOAD_FILE_BASE_URL + val.link;

              console.log(dataGambar);

              return (
                <Image
                  key={index}
                  imageText={val.nama}
                  imageUrl={showImage}
                  onView={(e) => {
                    e.stopPropagation();
                    setIndexImageHandler(index);
                  }}
                  onDownload={(e) => {
                    e.stopPropagation();

                    FileSaver.saveAs(downloadImage, val.nama);
                  }}
                  onDelete={(e) => {
                    e.stopPropagation();
                    setModalDeleteConfig({
                      show: true,
                      loading: false,
                      index: index,
                    });
                  }}
                />
              );
            })}
          </Row>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center py-5">
            <IoImageOutline size={60} />
            <b className="mt-2">Tidak ada gambar</b>
          </div>
        )}
      </Container>

      {/* Modal */}
      <DeleteModal
        show={modalDeleteConfig.show}
        title="Gambar"
        loading={modalDeleteConfig.loading}
        onConfirm={onDeleteHandler}
        onHide={() =>
          setModalDeleteConfig({
            show: false,
            index: '',
          })
        }
      />

      {/*Image Viewer  */}
      {viewImageConfig.show && (
        <div style={{ zIndex: 1000 }}>
          <ImageViever
            closeOnClickOutside
            disableScroll
            src={filterImageHandler}
            currentIndex={viewImageConfig.index}
            onClose={() => setViewImageConfig({ show: false, index: 0 })}
            backgroundStyle={{ zIndex: 1000 }}
          />
        </div>
      )}
    </div>
  );
};

export default TabGambar;

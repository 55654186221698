import { useFormik } from 'formik';
import { SelectSearch, FilterModal } from 'components';

const ModalFilterHargaKubikasiKayu = ({
  dropdown,
  modalConfig,
  setModalConfig,
  data,
  setData,
}) => {
  const filter = data?.filter;

  const formInitialValues = {
    jenis_kayu: filter.jenis_kayu,
    part_kayu: filter.part_kayu,
  };

  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData((prev) => ({
        ...prev,
        filter: { ...values, active: true },
      }));
    } else {
      setData((prev) => ({ ...prev, filter: { ...values, active: false } }));
    }
    setModalConfig(false);
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: formSubmitHandler,
  });

  const { values, setFieldValue, handleSubmit } = formik;

  const onResetButtonClick = () => {
    setData((prev) => ({
      ...prev,
      filter: {
        jenis_kayu: undefined,
        part_kayu: undefined,
      },
    }));

    setModalConfig(false);
  };

  return (
    <FilterModal
      show={modalConfig?.show}
      setShow={setModalConfig}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}
    >
      <SelectSearch
        label="Jenis Kayu"
        placeholder="Pilih jenis Kayu"
        defaultValue={dropdown?.jenis_kayu?.find(
          (val) => val.value === values.jenis_kayu
        )}
        option={dropdown.jenis_kayu ?? []}
        onChange={(val) => setFieldValue('jenis_kayu', val.value)}
      />

      <SelectSearch
        label="Part Kayu"
        placeholder="Pilih part kayu"
        defaultValue={dropdown?.part_kayu?.find(
          (val) => val.value === values.part_kayu
        )}
        option={dropdown.part_kayu ?? []}
        onChange={(val) => setFieldValue('part_kayu', val.value)}
      />
    </FilterModal>
  );
};

export default ModalFilterHargaKubikasiKayu;

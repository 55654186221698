import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import { IoCheckboxOutline, IoCloseCircleOutline } from 'react-icons/io5';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  BackButton,
  ReadButton,
  FilterButton,
  ActionButton,
} from 'components';
import { TableNumber, DateConvert } from 'utilities';
import { RencanaAnggaranBiayaApi } from 'api';
import { ModalFilter, ModalDetail, BarangJadiCollapse } from './Section';

const ListRAE = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const state = location?.state;
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataListRAE, setDataListRAE] = useState([]);
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [processedData, setProcessedData] = useState({});
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: state?.dataFilter?.active,
      tgl_rae_awal: state?.dataFilter?.tgl_rae_awal,
      tgl_rae_akhir: state?.dataFilter?.tgl_rae_akhir,
      peringkat_peluang: state?.dataFilter?.peringkat_peluang,
      customer: state?.dataFilter?.customer,
      proyek: state?.dataFilter?.proyek,
      kelompok_proyek: state?.dataFilter?.kelompok_proyek,
      target_penawaran_mulai: state?.dataFilter?.target_penawaran_mulai,
      target_penawaran_selesai: state?.dataFilter?.target_penawaran_selesai,
      realisasi_penawaran_mulai: state?.dataFilter?.realisasi_penawaran_mulai,
      realisasi_penawaran_selesai:
        state?.dataFilter?.realisasi_penawaran_selesai,
      instalasi: state?.dataFilter?.instalasi,
    },
    pagination: {
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    },
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    RencanaAnggaranBiayaApi.getPageRAE({
      q: searchConfig.key,

      page: dataFilter.pagination.page,
      per_page: dataFilter.pagination.dataLength,

      tgl_rae_awal: dataFilter?.filter?.tgl_rae_awal,
      tgl_rae_akhir: dataFilter?.filter?.tgl_rae_akhir,
      peringkat_peluang: dataFilter?.filter?.peringkat_peluang,
      customer: dataFilter?.filter?.customer,
      proyek: dataFilter?.filter?.proyek,
      kelompok_proyek: dataFilter?.filter?.kelompok_proyek,
      target_penawaran_mulai: dataFilter?.filter?.target_penawaran_mulai,
      target_penawaran_selesai: dataFilter?.filter?.target_penawaran_selesai,
      realisasi_penawaran_mulai: dataFilter?.filter?.realisasi_penawaran_mulai,
      realisasi_penawaran_selesai:
        dataFilter?.filter?.realisasi_penawaran_selesai,
      instalasi: dataFilter?.filter?.instalasi,
    })
      .then(({ data }) => {
        setDataListRAE(data.data);
        setDataFilter((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            dataCount: data.data_count,
            totalPage: data.total_page,
          },
        }));

        searchConfig.status &&
          setAlertConfig({
            show: searchConfig.key.length > 0 ? true : false,
            variant: 'primary',
            text: `Hasil dari pencarian: ${searchConfig.key}`,
          });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  useEffect(() => {
    setNavbarTitle('Rencana Anggaran Biaya (RAB)');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.status,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_rae_awal,
    dataFilter?.filter?.tgl_rae_akhir,
    dataFilter?.filter?.peringkat_peluang,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.proyek,
    dataFilter?.filter?.kelompok_proyek,
    dataFilter?.filter?.target_penawaran_mulai,
    dataFilter?.filter?.target_penawaran_selesai,
    dataFilter?.filter?.realisasi_penawaran_mulai,
    dataFilter?.realisasi_penawaran_selesai,
    dataFilter?.filter?.instalasi,
  ]);

  const PageContent = () => {
    const style = { minWidth: '260px' };

    return (
      <>
        <span style={{ fontSize: '14px' }}>
          <b>RAE Yang Siap Didaftarkan Menjadi RAB</b>
        </span>

        <Table>
          <THead>
            <Tr>
              <Th width={30}>No</Th>
              <ThFixed>Informasi RAE</ThFixed>
              <ThFixed>Informasi Peluang</ThFixed>
              <Th>Customer</Th>
              <Th>Proyek</Th>
              <Th>Kelompok Proyek</Th>
              <ThFixed>Instalasi</ThFixed>
              <Th>ATT</Th>
              <ThFixed>Tgl. Selesai</ThFixed>
              <Th>Barang Jadi</Th>
              <Th>Baseline</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dataListRAE.map((val, index) => (
              <Tr key={index}>
                <Td textCenter width={30}>
                  {TableNumber(
                    dataFilter.pagination.page,
                    dataFilter.pagination.dataLength,
                    index
                  )}
                </Td>
                <TdFixed>
                  <div>{DateConvert(new Date(val.tgl_rae)).custom}</div>
                  <div>{val.no_rae}</div>
                </TdFixed>
                <TdFixed>
                  <div>{DateConvert(new Date(val.tgl_peluang)).custom}</div>
                  <div>{val.no_peluang}</div>
                </TdFixed>
                <Td style={style}>{val.nama_customer ?? '-'}</Td>
                <Td style={style}>{val.nama_proyek ?? '-'}</Td>
                <Td style={style} className="text-capitalize">
                  {val.kelompok_proyek ?? '-'}
                </Td>
                <TdFixed textCenter>
                  {val.instalasi ? (
                    <IoCheckboxOutline size="20" />
                  ) : (
                    <IoCloseCircleOutline size="20" />
                  )}
                </TdFixed>
                <Td style={style}>{val.att ?? '-'}</Td>
                <TdFixed isAlignTop>
                  {DateConvert(new Date(val.tgl_selesai)).custom}
                </TdFixed>
                <Td style={{ minWidth: '300px' }}>
                  {val?.barang_jadi?.length > 0 && (
                    <BarangJadiCollapse data={val.barang_jadi} />
                  )}
                </Td>
                <Td>{val.baseline}</Td>
                <TdFixed isAlignTop>
                  <div className="d-flex">
                    <ReadButton
                      onClick={() => {
                        setProcessedData(val);
                        setShowModalDetail(true);
                      }}
                    />
                    <ActionButton
                      variant="success"
                      size="sm"
                      className="my-1"
                      onClick={() =>
                        history.push(
                          `/transaksi/rencana-anggaran-biaya/tambah/${val.id_rae}`
                        )
                      }
                    >
                      <AiOutlinePlus />
                    </ActionButton>
                  </div>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>

        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={
            dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength -
            dataFilter?.pagination?.dataLength +
            1
          }
          dataPage={
            dataFilter?.pagination?.dataCount <
            dataFilter?.pagination?.dataLength
              ? dataFilter?.pagination?.dataCount
              : dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter((prev) => ({
              ...prev,
              pagination: { ...prev.pagination, page: selected + 1 },
            }))
          }
          onDataLengthChange={(e) =>
            setDataFilter((prev) => ({
              ...prev,
              pagination: { ...prev.pagination, dataLength: e.target.value },
            }))
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <div>
              <InputSearch
                placeholder="Cari barang jadi"
                value={searchConfig.key}
                onChange={(e) => {
                  setSearchConfig({
                    ...searchConfig,
                    status: true,
                    key: e.target.value,
                  });
                }}
              />
            </div>
            <div style={{ width: '200px' }}>
              <FilterButton onClick={() => setShowModalFilter(true)} />
            </div>
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton size="md" onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataListRAE?.length > 0 ? (
        <PageContent />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      <ModalFilter
        show={showModalFilter}
        setShow={setShowModalFilter}
        data={dataFilter}
        setData={setDataFilter}
      />

      {showModalDetail && (
        <ModalDetail
          show={showModalDetail}
          processedData={processedData}
          onHide={setShowModalDetail}
        />
      )}
    </CRUDLayout>
  );
};

export default ListRAE;

import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IoCheckboxOutline, IoCloseCircleOutline } from 'react-icons/io5';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  BackButton,
  FilterButton,
} from 'components';
import { TableNumber, DateConvert } from 'utilities';
import { PenawaranApi } from 'api';
import { IoAdd } from 'react-icons/io5';
import { ModalFilter } from './Section';

const ListRAB = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataListRAB, setDataListRAB] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.rab?.filter?.active,
      tgl_rab_mulai: location?.state?.rab?.filter?.tgl_rab_mulai,
      tgl_rab_selesai: location?.state?.rab?.filter?.tgl_rab_selesai,
      customer: location?.state?.rab?.filter?.customer,
      proyek: location?.state?.rab?.filter?.proyek,
      realisasi_penawaran_mulai:
        location?.state?.rab?.filter?.realisasi_penawaran_mulai,
      realisasi_penawaran_selesai:
        location?.state?.rab?.filter?.realisasi_penawaran_selesai,
      instalasi: location?.state?.rab?.filter?.instalasi,
    },
    pagination: {
      page: location?.state?.rab?.filter?.page ?? '1',
      dataLength: location?.state?.rab?.filter?.dataLength ?? '10',
      totalPage: location?.state?.rab?.filter?.totalPage ?? '1',
      dataCount: location?.state?.rab?.filter?.dataCount ?? '0',
    },
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PenawaranApi.getPageRAB({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_rab_mulai: dataFilter?.filter?.tgl_rab_mulai,
      tgl_rab_selesai: dataFilter?.filter?.tgl_rab_selesai,
      customer: dataFilter?.filter?.customer,
      proyek: dataFilter?.filter?.proyek,
      realisasi_penawaran_mulai: dataFilter?.filter?.realisasi_penawaran_mulai,
      realisasi_penawaran_selesai:
        dataFilter?.filter?.realisasi_penawaran_selesai,
      instalasi: dataFilter?.filter?.instalasi,
    })
      .then((data) => {
        setDataListRAB(data?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          },
        });

        searchConfig.status &&
          setAlertConfig({
            show: searchConfig.key.length > 0 ? true : false,
            variant: 'primary',
            text: `Hasil dari pencarian: ${searchConfig.key}`,
          });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const date = new Date(inputDate);
      return DateConvert(date).custom;
    }
    return '-';
  };

  // UNTUK MENAMPILKAN LIST PADA KOLOM BARANG JADI
  const BarangJadi = ({ listBarangJadi = [] }) => {
    return (
      <ul style={{ paddingLeft: '1rem' }}>
        {listBarangJadi.map((val, index) => (
          <li key={index}>{`(${val?.kode_item ?? '00.00000'}) ${
            val?.nama_item ?? '-'
          }`}</li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    setNavbarTitle('Penawaran');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.status,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_rab_mulai,
    dataFilter?.filter?.tgl_rab_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.proyek,
    dataFilter?.filter?.realisasi_penawaran_mulai,
    dataFilter?.filter?.realisasi_penawaran_selesai,
    dataFilter?.filter?.instalasi,
  ]);

  const BarangJadiCollapse = ({ data }) => {
    const [isReadMoreClick, setisReadMoreClick] = useState(false);

    return (
      <>
        <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
          {data.map((brg, index) => {
            if (isReadMoreClick) {
              return <li index={index}>{brg.nama_item}</li>;
            } else {
              return index <= 2 && <li index={index}>{brg.nama_item}</li>;
            }
          })}
        </ul>
        {data.length <= 2 ? (
          ''
        ) : (
          <div
            className="text-primary"
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              // fontWeight: "bold",
            }}
            onClick={() => {
              setisReadMoreClick((prev) => !prev);
            }}
          >
            {isReadMoreClick ? 'Sembunyikan' : 'Selengkapnya...'}
          </div>
        )}
      </>
    );
  };

  const PageContent = () => {
    const DataTable = () => (
      <>
        <span style={{ fontSize: '14px' }}>
          <b>RAB Yang Siap Didaftarkan Menjadi Penawaran</b>
        </span>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi RAB</ThFixed>
              <Th>Customer</Th>
              <Th>Proyek</Th>
              <Th>ATT</Th>
              <Th>Instalasi</Th>
              <ThFixed>Tgl. Realisasi Penawaran</ThFixed>
              <Th>Barang Jadi</Th>
              <ThFixed>Baseline</ThFixed>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dataListRAB.map((val, index) => (
              <Tr>
                <TdFixed
                  isAlignTop={val?.barang_jadi?.length > 0 ? true : false}
                  textCenter
                >
                  {TableNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed
                  isAlignTop={val?.barang_jadi?.length > 0 ? true : false}
                >
                  <div>{DateConvert(new Date(val.tgl_rab)).custom}</div>
                  <div>{val.no_rab ?? '-'}</div>
                </TdFixed>
                <Td isAlignTop={val?.barang_jadi?.length > 0 ? true : false}>
                  {val.nama_customer ?? '-'}
                </Td>
                <Td isAlignTop={val?.barang_jadi?.length > 0 ? true : false}>
                  {val.nama_proyek ?? '-'}
                </Td>
                <Td isAlignTop={val?.barang_jadi?.length > 0 ? true : false}>
                  {val.att ?? '-'}
                </Td>
                <TdFixed textCenter>
                  {Boolean(val.instalasi) ? (
                    <IoCheckboxOutline fontSize="20px" />
                  ) : (
                    <IoCloseCircleOutline fontSize="20px" />
                  )}
                </TdFixed>
                <TdFixed
                  isAlignTop={val?.barang_jadi?.length > 0 ? true : false}
                >
                  {DateConvert(new Date(val.realisasi_penawaran)).custom ?? '-'}
                </TdFixed>
                <Td>
                  {val.barang_jadi ? (
                    val.barang_jadi.length > 0 ? (
                      <BarangJadiCollapse data={val.barang_jadi} />
                    ) : (
                      '-'
                    )
                  ) : (
                    '-'
                  )}
                </Td>
                <TdFixed textCenter>{val.baseline ?? '-'}</TdFixed>
                <TdFixed
                  isAlignTop={val?.barang_jadi?.length > 0 ? true : false}
                >
                  <ActionButton
                    size="sm"
                    variant="success"
                    className="col"
                    onClick={() =>
                      history.push(
                        `/transaksi/penawaran/tambah/${val.id_rab}`,
                        { ...location?.state, rab: dataFilter }
                      )
                    }
                  >
                    <IoAdd />
                  </ActionButton>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={
            dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength -
            dataFilter?.pagination?.dataLength +
            1
          }
          dataPage={
            dataFilter?.pagination?.dataCount <
            dataFilter?.pagination?.dataLength
              ? dataFilter?.pagination?.dataCount
              : dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: selected + 1,
              },
            })
          }
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: 1,
                dataLength: e.target.value,
              },
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              placeholder="Cari barang jadi"
              value={searchConfig.key}
              onChange={(e) => {
                setSearchConfig({
                  ...searchConfig,
                  status: true,
                  key: e.target.value,
                });
              }}
            />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            size="md"
            onClick={() =>
              history.push('/transaksi/penawaran', {
                ...location?.state,
                rab: dataFilter,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : // <PageContent />
      dataListRAB ? (
        dataListRAB.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}

      <ModalFilter
        show={showModalFilter}
        setShow={setShowModalFilter}
        data={dataFilter}
        setData={setDataFilter}
      />
    </CRUDLayout>
  );
};

export default ListRAB;

import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Modal } from 'react-bootstrap';
import {
  CRUDLayout,
  Input,
  InputCurrency,
  DataStatus,
  ActionButton,
  SelectSearch,
  THead,
  TBody,
  Tr,
  Th,
  ThFixed,
  DeleteButton,
  Td,
  TdFixed,
} from 'components';
import AnalisaBarangJadi from '../AnalisaBarangJadi';

const TableBarangJadi = ({
  type,
  dataBarangJadi,
  setDataBarangJadi,
  listBarangJadi,
  setListBarangJadi,
  alertConfig,
  setAlertConfig,
}) => {
  const [processedData, setProcessedData] = useState({});
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });

  const formInitialValues = {
    id_item_buaso: '',
    kode_item: '',
    nama_item: '',
    nama_jenis: '',
    nama_satuan: '',
    qty: '',
  };

  const formValidationSchema = Yup.object().shape({
    id_item_buaso: Yup.string().required('Pilih Barang Jadi'),
    qty: Yup.string().required('Qty. harus diisi'),
  });

  const formSubmitHandler = (values, { resetForm }) => {
    setListBarangJadi([...listBarangJadi, values]);
    resetForm();
  };

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '');
    const convert = newValue.substring(0, 5);
    return convert;
  };

  const hapusBarangJadi = (index) =>
    setListBarangJadi(listBarangJadi.filter((item, i) => i !== index));

  const ModalSection = ({
    processedData,
    dataBarangJadi,
    setDataBarangJadi,
    alertConfig,
    setAlertConfig,
    modalConfig,
    setModalConfig,
  }) => {
    const AnalisaBarangJadiModal = ({
      processedData,
      dataBarangJadi,
      setDataBarangJadi,
      alertConfig,
      setAlertConfig,
      modalConfig,
      setModalConfig,
    }) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadi
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            modalConfig={modalConfig}
            setAlertConfig={setAlertConfig}
            setModalConfig={setModalConfig}
          />
        </Modal.Body>
      );
    };

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === 'analisa' ? 'xl' : 'md'}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <AnalisaBarangJadiModal
          processedData={processedData}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      </Modal>
    );
  };

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setValues,
        }) => (
          <>
            {type !== 'DETAIL' && (
              <div className="d-flex justify-content-end align-items-end mb-2">
                <div className="col-md-6 pl-0">
                  <SelectSearch
                    label="Barang Jadi"
                    name="id_item_buaso"
                    placeholder="Pilih Barang Jadi"
                    onChange={(val) => {
                      setValues({
                        ...values,
                        id_item_buaso: val.value,
                        nama_item: val.label,
                        kode_item: val.kode_item,
                        nama_jenis: val.nama_jenis,
                        nama_satuan: val.nama_satuan,
                      });
                    }}
                    option={dataBarangJadi}
                    defaultValue={
                      values.id_item_buaso
                        ? dataBarangJadi?.find(
                            (item) => item.value === values.id_item_buaso
                          )
                        : ''
                    }
                    error={
                      errors.id_item_buaso && touched.id_item_buaso && true
                    }
                    errorText={
                      errors.id_item_buaso &&
                      touched.id_item_buaso &&
                      errors.id_item_buaso
                    }
                  />
                </div>

                <div className="col-md-3">
                  <InputCurrency
                    label="Qty"
                    type="text"
                    name="qty"
                    prefix=""
                    decimalScale={2}
                    value={parseFloat(values.qty)}
                    onChange={(value) => setFieldValue('qty', value)}
                    error={errors.qty && touched.qty && true}
                    errorText={errors.qty && touched.qty && errors.qty}
                  />
                </div>

                <div className="col-md-3">
                  <ActionButton
                    type="button"
                    className="btn btn-primary mb-2"
                    size="md"
                    text="Tambah Data"
                    onClick={() => handleSubmit()}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </Formik>

      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <Th style={{ width: 100 }}>Kode Barang Jadi</Th>
            <Th>Barang Jadi</Th>
            {/* <Th>Jenis Barang Jadi</Th> */}
            <Th>Satuan</Th>
            <Th>Qty</Th>
            {type !== 'DETAIL' && <ThFixed>Aksi</ThFixed>}
          </Tr>
        </THead>
        <TBody>
          {listBarangJadi.length !== 0 ? (
            listBarangJadi.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <TdFixed>{val.kode_item}</TdFixed>
                <Td>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setProcessedData({
                        ...val,
                        index: index,
                        id_barang_jadi: val.id_item_buaso,
                      });

                      setModalConfig({
                        show: true,
                        type: 'analisa',
                        title: (
                          <span className="text-primary">
                            Analisa Barang Jadi Peluang
                          </span>
                        ),
                      });
                    }}
                  >
                    {val.nama_item}
                  </a>
                </Td>
                <Td>{val.nama_satuan}</Td>
                <Td>
                  <div className="text-right">{parseFloat(val.qty)}</div>
                </Td>
                {type !== 'DETAIL' && (
                  <TdFixed>
                    <DeleteButton onClick={() => hapusBarangJadi(index)} />
                  </TdFixed>
                )}
              </Tr>
            ))
          ) : (
            <tr>
              <td colSpan={type !== 'DETAIL' ? 7 : 6} className="text-center">
                <DataStatus text="Tidak Ada Data" />
              </td>
            </tr>
          )}
        </TBody>

        <ModalSection
          processedData={processedData}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      </CRUDLayout.Table>
    </>
  );
};

export default TableBarangJadi;

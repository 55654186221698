import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CRUDLayout,
  InputSearch,
  ActionButton,
  DataStatus,
  Alert,
  Pagination,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  SelectMonth,
  Tr,
  Th,
  Td,
} from 'components';
import _ from 'lodash';
import { ProgressProduksiApi } from 'api';
import { PageNumber, DateConvert } from 'utilities';

const ProgressProduksi = ({ setNavbarTitle }) => {
  const today = new Date();
  // USE HISTORY
  const history = useHistory();
  const title = 'Progress Produksi';

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(false);

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState('');

  // data jenis
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [dataCount, setDataCount] = useState(0);
  const [dateSelected, setDateSelected] = useState({
    month: DateConvert(today).defaultMonth,
    year: DateConvert(today).defaultYear,
  });

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    const date = `${dateSelected.year}-${dateSelected.month}-01`;
    const query =
      dateSelected.month.toString() === '13'
        ? {
            q: searchKey,
            page: page,
            per_page: dataLength,
            tahun: dateSelected.year,
          }
        : {
            q: searchKey,
            page: page,
            per_page: dataLength,
            tanggal: date,
          };

    // request data ke server
    ProgressProduksiApi.getPage(query)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch((err) => {
        setAlertConfig({
          variant: 'danger',
          text: err.response.data.message,
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey != '') {
          setAlertConfig({
            variant: 'primary',
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
        setIsLoading(false);
      });
  };

  const getStatusApprovalLabel = (status_approval) => {
    switch (status_approval) {
      case 'APP':
        return 'APPROVED';
      case 'REJ':
        return 'REJECT';
      case 'REV':
        return 'REVISI';
      case 'VER':
        return 'VERIFIED';
      default:
        return 'PENDING';
    }
  };

  const getKeteranganLog = (
    status,
    keterangan,
    nomor,
    referensi,
    nama_referensi,
    nama
  ) => {
    if (status?.toUpperCase() === 'VER') {
      return `${keterangan} no. ${nomor} verifikasi oleh ${nama}.`;
    }

    if (status?.toUpperCase() === 'APP') {
      return `${keterangan} no. ${nomor} diapprove oleh ${nama}.`;
    }

    if (status?.toUpperCase() === 'REV') {
      return `${keterangan} no. ${nomor} diperiksa oleh ${nama} dan diminta untuk direvisi.`;
    }

    if (status?.toUpperCase() === 'REJ') {
      return `${keterangan} no. ${nomor} direject oleh ${nama}.`;
    }

    return `${keterangan} no. ${nomor} didaftarkan oleh ${nama}${
      referensi ? ` dengan referensi ${nama_referensi} no. ${referensi}.` : '.'
    }`;
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(title);
    // jalankan function request data ke server
    getData();

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [
    setNavbarTitle,
    page,
    dataLength,
    searchKey,
    dateSelected.month,
    dateSelected.year,
  ]);

  const Select = (props) => (
    <select
      {...props}
      className={`custom-select custom-select ${props.className}`}
    >
      {props.children}
    </select>
  );

  // Tabel
  const Table = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Tanggal Peluang</ThFixed>
            <ThFixed>No. Peluang</ThFixed>
            <Th>Progress Terakhir</Th>
            <ThFixed>Aksi</ThFixed>
            {/* <ThFixed>Status Approval</ThFixed> */}
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
              <Td className="text-center">
                {DateConvert(new Date(val.tgl_peluang)).custom}
              </Td>
              <Td className="text-center">{val.no_peluang}</Td>
              <Td>
                {val.log
                  ? getKeteranganLog(
                      val.log.status_approval,
                      val.log.keterangan_transaksi,
                      val.log.no_transaksi,
                      val.log.no_transaksi_ref,
                      val.log.keterangan_transaksi_ref,
                      val.log.nama_karyawan
                    )
                  : ''}
              </Td>
              <TdFixed>
                <div className="d-flex justify-content-center">
                  <ActionButton
                    size="sm"
                    className="text-nowrap"
                    text="Detail"
                    onClick={() =>
                      history.push(`/laporan/progress-produksi/log`, {
                        no_peluang: val.no_peluang,
                        baseline: 1,
                      })
                    }
                  />
                </div>
              </TdFixed>
              {/* <Td>{getStatusApprovalLabel(val.status_approval)}</Td> */}
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setPage(1);
            setDataLength(e.target.value);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      )}
    </>
  );

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setPage(1);
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <div className="d-flex align-items-top">
            <SelectMonth
              noMargin
              size="md"
              wrapperClassName="m-1"
              style={{ width: 150 }}
              defaultValue={dateSelected.month}
              onChange={(e) => {
                setPage(1);
                setDateSelected({
                  ...dateSelected,
                  month: e.target.value,
                });
              }}
            >
              <option value={13}>Semua Bulan</option>
            </SelectMonth>

            <Select
              className="ml-2"
              style={{ width: 100 }}
              defaultValue={dateSelected.year}
              onChange={(e) => {
                setPage(1);
                setDateSelected({
                  ...dateSelected,
                  year: e.target.value,
                });
              }}
            >
              {_.range(today.getFullYear(), 1999).map((val) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </Select>
          </div>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {/* Table Section */}
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data ? (
        data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Server error" />
      )}
    </CRUDLayout>
  );
};

export default ProgressProduksi;

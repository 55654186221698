import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IoCheckboxOutline, IoCloseCircleOutline } from 'react-icons/io5';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  CreateButton,
  ReadButton,
  UpdateButton,
  FilterButton,
  ApprovalStatusButton,
} from 'components';
import { DateConvert, TableNumber } from 'utilities';
import { RencanaAnggaranBiayaApi } from 'api';
import { ModalFilter } from './Section';

const RencanaAnggaranBiaya = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  // Tambahan
  const state = location?.state;
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataListRAB, setDataListRAB] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    active: state?.dataFilter?.active,
    tgl_rab_awal: state?.dataFilter?.tgl_rab_awal,
    tgl_rab_akhir: state?.dataFilter?.tgl_rab_akhir,
    customer: state?.dataFilter?.customer,
    proyek: state?.dataFilter?.proyek,
    realisasi_penawaran_awal: state?.dataFilter?.realisasi_penawaran_awal,
    realisasi_penawaran_akhir: state?.dataFilter?.realisasi_penawaran_akhir,
    instalasi: state?.dataFilter?.instalasi,
    status_deal: state?.dataFilter?.status_deal,
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    RencanaAnggaranBiayaApi.getPageRAB({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
      tgl_rab_awal: dataFilter?.tgl_rab_awal,
      tgl_rab_akhir: dataFilter?.tgl_rab_akhir,
      customer: dataFilter?.customer,
      proyek: dataFilter?.proyek,
      realisasi_penawaran_awal: dataFilter?.realisasi_penawaran_awal,
      realisasi_penawaran_akhir: dataFilter?.realisasi_penawaran_akhir,
      instalasi: dataFilter?.instalasi,
      status_deal: dataFilter?.status_deal,
    })
      .then((data) => {
        setDataListRAB(data.data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data.data_count,
          totalPage: data.data.total_page,
        });
        searchConfig.status &&
          setAlertConfig({
            show: searchConfig.key.length > 0 ? true : false,
            variant: 'primary',
            text: `Hasil dari pencarian: ${searchConfig.key}`,
          });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const getStatusApprovalLabel = (status_approval) => {
    switch (status_approval) {
      case 'APP':
        return {
          variant: 'outline-success',
          label: 'APPROVED',
          // return "APPROVED"
        };
      case 'REJ':
        return {
          variant: 'outline-danger',
          label: 'REJECT',
          // return "REJECT";
        };
      case 'REV':
        return {
          variant: 'outline-warning',
          label: 'REVISI',
          // return "REVISI";
        };
      case 'VER':
        return {
          variant: 'outline-success',
          label: 'VERIFIED',
          // return "VERIFIED";
        };
      default:
        return {
          variant: 'outline-secondary',
          label: 'PENDING',
          // return "PENDING";
        };
    }
  };

  useEffect(() => {
    setNavbarTitle('Rencana Anggaran Biaya (RAB)');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.status,
    paginationConfig.page,
    paginationConfig.dataLength,
    searchConfig.key,
    dataFilter?.tgl_rab_awal,
    dataFilter?.tgl_rab_akhir,
    dataFilter?.customer,
    dataFilter?.proyek,
    dataFilter?.realisasi_penawaran_awal,
    dataFilter?.realisasi_penawaran_akhir,
    dataFilter?.instalasi,
    dataFilter?.status_deal,
  ]);

  const BarangJadiCollapse = ({ data }) => {
    const [isReadMoreClick, setisReadMoreClick] = useState(false);

    return (
      <>
        <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
          {data.map((brg, index) => {
            if (isReadMoreClick) {
              return (
                <li key={index} index={index}>
                  {brg.nama_item}
                </li>
              );
            } else {
              return (
                index <= 2 && (
                  <li key={index} index={index}>
                    {brg.nama_item}
                  </li>
                )
              );
            }
          })}
        </ul>
        {data.length <= 2 ? (
          ''
        ) : (
          <div
            className="text-primary"
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              // fontWeight: "bold",
            }}
            onClick={() => {
              setisReadMoreClick((prev) => !prev);
            }}
          >
            {isReadMoreClick ? 'Sembunyikan' : 'Selengkapnya...'}
          </div>
        )}
      </>
    );
  };

  const PageContent = () => {
    const DataTable = () => (
      <>
        <span style={{ fontSize: '14px' }}>
          <b>List Data Rancangan Anggaran Biaya</b>
        </span>
        <Table>
          <THead>
            <Tr>
              <th className="text-center align-middle p-0">No</th>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi RAB</ThFixed>
              <Th>Customer</Th>
              <Th>Proyek</Th>
              <Th>ATT</Th>
              <Th>Tgl. Realisasi Penawaran</Th>
              <ThFixed>Instalasi</ThFixed>
              <Th>Barang Jadi</Th>
              <ThFixed>Baseline</ThFixed>
              <ThFixed>Status Approval</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dataListRAB.map((val, index) => (
              <Tr key={index}>
                <Td textCenter width={30}>
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </Td>
                <TdFixed>
                  <div className="d-flex justify-content-start">
                    {/* CEK STATUS APPROVAL UNTUK MENENTUKAN JIKA RAB BOLEH DIUPDATE */}
                    {val.status_approval === 'REV' ||
                    val.status_approval === 'APP' ? (
                      <>
                        <ReadButton
                          onClick={() => {
                            history.push(
                              `/transaksi/rencana-anggaran-biaya/detail/${val.id_rab}`,
                              {
                                status_approval: val.status_approval,
                              }
                            );
                          }}
                        />
                        <UpdateButton
                          onClick={() => {
                            history.push(
                              `/transaksi/rencana-anggaran-biaya/ubah/${val.id_rab}`
                            );
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <ReadButton
                          onClick={() => {
                            history.push(
                              `/transaksi/rencana-anggaran-biaya/detail/${val.id_rab}`,
                              {
                                status_approval: val.status_approval,
                              }
                            );
                          }}
                        />
                      </>
                    )}
                  </div>
                </TdFixed>
                <TdFixed>
                  {/* {getConvertedDate(val.tgl_rab)} */}
                  <div>{DateConvert(new Date(val.tgl_rab)).custom}</div>
                  <div>{val.no_rab}</div>
                </TdFixed>
                <Td style={{ minWidth: '250px' }}>
                  {val.nama_customer ?? '-'}
                </Td>
                <Td style={{ minWidth: '250px' }}>{val.nama_proyek ?? '-'}</Td>
                <Td style={{ minWidth: '200px' }}>{val.att ?? '-'}</Td>
                <Td>
                  {DateConvert(new Date(val.realisasi_penawaran)).custom ?? '-'}
                </Td>
                <TdFixed textCenter>
                  {Boolean(val.instalasi) ? (
                    <IoCheckboxOutline fontSize="20px" />
                  ) : (
                    <IoCloseCircleOutline fontSize="20px" />
                  )}
                </TdFixed>
                <Td style={{ minWidth: '300px' }}>
                  {val.barang_jadi ? (
                    val.barang_jadi.length > 0 ? (
                      <BarangJadiCollapse data={val.barang_jadi} />
                    ) : (
                      '-'
                    )
                  ) : (
                    '-'
                  )}
                </Td>
                <TdFixed>{val.baseline ?? '-'}</TdFixed>
                <TdFixed>
                  <ApprovalStatusButton
                    variant={
                      getStatusApprovalLabel(val.status_approval).variant
                    }
                  >
                    {getStatusApprovalLabel(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              placeholder="Cari barang jadi"
              value={searchConfig.key}
              onChange={(e) =>
                setSearchConfig({
                  ...searchConfig,
                  status: true,
                  key: e.target.value,
                })
              }
            />
            <FilterButton
              active={dataFilter.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() => {
              history.push(`/transaksi/rencana-anggaran-biaya/list-rae`, {});
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => {
          setAlertConfig({
            ...alertConfig,
            show: false,
          });
          // CLEAR Alert location.alert
          history.replace('/transaksi/rencana-anggaran-biaya', {
            alert: undefined,
          });
        }}
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataListRAB ? (
        dataListRAB.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}

      <ModalFilter
        show={showModalFilter}
        setShow={setShowModalFilter}
        data={dataFilter}
        setData={setDataFilter}
      />
    </CRUDLayout>
  );
};

export default RencanaAnggaranBiaya;

import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import Axios from 'axios';
import { FilterModal, DatePicker, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { RencanaAnggaranBiayaApi } from 'api';

const ModalFilter = ({ show, setShow, data, setData }) => {
  const [loading, setLoading] = useState({
    allDropdown: true,
    proyek: true,
  });
  const [dropdown, setDropdown] = useState({
    customer: [{ label: 'Semua', value: undefined }],
    proyek: [{ label: 'Semua', value: undefined }],
    instalasi: [
      { label: 'Semua', value: undefined },
      { label: 'Dengan Instalasi', value: true },
      { label: 'Tanpa Instalasi', value: false },
    ],
  });

  const getDataDropdown = () => {
    Axios.all([
      RencanaAnggaranBiayaApi.getDropdownCustomer(),
      RencanaAnggaranBiayaApi.getDropdownProyek(),
    ])
      .then(
        Axios.spread((customer, proyek) => {
          const mapDataCustomer =
            customer?.data?.data?.map((val) => ({
              label: val?.nama_customer,
              value: val?.id_customer,
            })) ?? [];

          const mapDataProyek =
            proyek?.data?.data?.map((val) => ({
              label: val?.nama_proyek,
              value: val?.id_proyek,
            })) ?? [];

          setDropdown((prev) => ({
            ...prev,
            customer: prev.customer.concat(mapDataCustomer),
            proyek: prev.proyek.concat(mapDataProyek),
          }));
        })
      )
      .finally(() =>
        setLoading({
          ...loading,
          allDropdown: false,
          proyek: false,
        })
      );
  };

  const getProyek = (data) => {
    setLoading({
      ...loading,
      proyek: true,
    });

    RencanaAnggaranBiayaApi.getDropdownProyek({ id_customer: data })
      .then((proyek) => {
        const mapDataProyek =
          proyek?.data?.data?.map((val) => ({
            label: val.nama_proyek,
            value: val.id_proyek,
          })) ?? [];

        setDropdown((prev) => ({
          ...prev,
          proyek: [{ label: 'Semua', value: undefined }].concat(mapDataProyek),
        }));
      })
      .finally(() =>
        setLoading({
          ...loading,
          proyek: false,
        })
      );
  };

  useEffect(() => {
    getDataDropdown();
  }, []);

  useEffect(() => {
    data.customer && getProyek(data.customer);
  }, [data.customer]);

  const formInitialValues = {
    tgl_rab_awal: data?.tgl_rab_awal,
    tgl_rab_akhir: data?.tgl_rab_akhir,
    customer: data?.customer,
    proyek: data?.proyek,
    realisasi_penawaran_awal: data?.realisasi_penawaran_awal,
    realisasi_penawaran_akhir: data?.realisasi_penawaran_akhir,
    instalasi: data?.instalasi,
  };

  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({ ...values, active: true });
    } else {
      setData({ ...values, active: false });
    }

    setShow(false);
  };

  const onTanggalRABgChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_rab_awal: startDate,
      tgl_rab_akhir: endDate,
    });
  };

  const onRealisasiPenawaranChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      realisasi_penawaran_awal: startDate,
      realisasi_penawaran_akhir: endDate,
    });
  };

  const onResetButtonClick = (values, setValues, handleSubmit) => {
    setValues({
      tgl_rab_awal: undefined,
      tgl_rab_akhir: undefined,
      customer: undefined,
      proyek: undefined,
      realisasi_penawaran_awal: undefined,
      realisasi_penawaran_akhir: undefined,
      instalasi: undefined,
    });

    handleSubmit();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() =>
            onResetButtonClick(values, setValues, handleSubmit)
          }
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. RAB"
            placeholderText="Pilih tanggal RAB"
            startDate={values.tgl_rab_awal ? new Date(values.tgl_rab_awal) : ''}
            endDate={values.tgl_rab_akhir ? new Date(values.tgl_rab_akhir) : ''}
            onChange={(dates) => onTanggalRABgChange(dates, values, setValues)}
            monthsShown={2}
          />
          <SelectSearch
            key={values.customer}
            label="Customer"
            placeholder="Pilih customer"
            defaultValue={dropdown.customer.find(
              (val) => val.value === values.customer
            )}
            option={dropdown.customer}
            onChange={(val) => {
              setValues({
                ...values,
                customer: val.value,
                proyek: undefined,
              });
              getProyek(val.value, values, setValues);
            }}
            loading={loading.allDropdown}
          />
          <SelectSearch
            key={values.proyek}
            label="Proyek"
            placeholder="Pilih proyek untuk memilih proyek"
            defaultValue={dropdown.proyek.find(
              (val) => val.value === values.proyek
            )}
            option={dropdown.proyek}
            onChange={(val) => setFieldValue('proyek', val.value)}
            loading={loading.proyek}
          />
          <DatePicker
            selectsRange
            label="Realisasi Penawaran"
            placeholderText="Pilih tanggal realisasi penawaran"
            startDate={
              values.realisasi_penawaran_awal
                ? new Date(values.realisasi_penawaran_awal)
                : ''
            }
            endDate={
              values.realisasi_penawaran_akhir
                ? new Date(values.realisasi_penawaran_akhir)
                : ''
            }
            onChange={(dates) =>
              onRealisasiPenawaranChange(dates, values, setValues)
            }
            monthsShown={2}
          />
          <SelectSearch
            key={values.instalasi}
            label="Instalasi"
            placeholder="Pilih Instalasi"
            defaultValue={dropdown.instalasi.find(
              (val) => val.value === values.instalasi
            )}
            option={dropdown.instalasi}
            onChange={(val) => setFieldValue('instalasi', val.value)}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;

import { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { IoCheckboxOutline, IoCloseCircleOutline } from 'react-icons/io5';
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlParser from 'html-react-parser';
import {
  DatePicker,
  Input,
  SelectSearch,
  TextEditor,
  TextArea,
} from 'components';
import TableBarangJadi from './TableBarangJadi';
import { DateConvert } from 'utilities';
import { RegistrasiPeluangApi } from 'api';

const TabInfo = ({
  type: TYPE,
  formik,
  dropdown,
  setDropdown,
  listBarangJadi,
  setListBarangJadi,
  dataBarangJadi,
  setDataBarangJadi,
}) => {
  const today = new Date().toISOString().slice(0, 10);
  const { values, errors, touched, setFieldValue, setValues } = formik;
  const [textEditorState, setTextEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(values.lain_lain ?? ''))
    )
  );
  const [isFetchDropdown, setIsFetchDropdown] = useState({ proyek: false });

  // fetch nomor baru peluang
  const getNomorBaru = (date) => {
    RegistrasiPeluangApi.getNoBaru(date)
      .then(({ data }) => {
        formik.setFieldValue('tgl_peluang', date);
        formik.setFieldValue('no_peluang', data.data);
      })
      .catch(() => window.alert('Nomor Peluang gagal di generate!'));
  };

  // fetch data proyek ketika value form customer berubah
  const getDataProyek = (customer) => {
    setIsFetchDropdown({ proyek: true });

    RegistrasiPeluangApi.getProyek(customer)
      .then((res) => {
        const dataProyek = res?.data?.data?.map((val) =>
          Object({ ...val, value: val.id_proyek, label: val.nama_proyek })
        );
        setDropdown((prevState) =>
          Object({ ...prevState, proyek: dataProyek ?? [] })
        );
        setIsFetchDropdown({ proyek: false });
      })
      .catch((err) => alert('gagal memuat data Proyek!'));
  };

  const updateEditorState = (value, setFieldValue) => {
    setTextEditorState(value);
    const convertData = convertToRaw(value.getCurrentContent());
    setFieldValue('lain_lain', draftToHtml(convertData));
  };

  useEffect(() => {
    TYPE === 'CREATE' &&
      (!values.realisasi_penawaran || !values.tgl_selesai) &&
      setValues({
        ...values,
        realisasi_penawaran: today,
        tgl_selesai: today,
      });
    TYPE === 'CREATE' && !values.tgl_peluang && getNomorBaru(today);
  }, []);

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td className="text-capitalize">{value}</td>
      </tr>
    );

    const ShowData = ({ title, text, line }) => (
      <div>
        <small
          className="text-secondary"
          style={{ opacity: 0.9, textTransform: 'capitalize' }}
        >
          {title}
        </small>
        <div className="text-dark">
          <div>{text ? text : '-'}</div>
        </div>

        {line && <hr className="my-2" />}
      </div>
    );

    return (
      <>
        <Row className="mb-4">
          <Col>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItem
                  title="Tgl. Peluang"
                  value={
                    values?.tgl_peluang
                      ? DateConvert(new Date(values.tgl_peluang)).detail
                      : '-'
                  }
                />
                <InfoItem
                  title="No. Peluang"
                  value={values?.no_peluang ?? '-'}
                />
                <InfoItem
                  title="Target Penawaran"
                  value={
                    values?.tgl_selesai
                      ? DateConvert(new Date(values.tgl_selesai)).detail
                      : '-'
                  }
                />
                <InfoItem
                  title="Realisasi Penawaran"
                  value={
                    values?.realisasi_penawaran
                      ? DateConvert(new Date(values.realisasi_penawaran)).detail
                      : '-'
                  }
                />
              </tbody>
            </table>
          </Col>
          <Col>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItem
                  title="Peringkat Peluang"
                  value={values?.nama_peringkat_peluang ?? '-'}
                />
                <InfoItem
                  title="Customer"
                  value={values?.nama_customer ?? '-'}
                />
                <InfoItem title="Proyek" value={values?.nama_proyek ?? '-'} />
                <InfoItem title="ATT" value={values?.att ?? '-'} />
                <InfoItem
                  title="Kelompok Proyek"
                  value={values?.kelompok_proyek ?? '-'}
                />
              </tbody>
            </table>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md>
            <ShowData
              title="Keterangan Pengiriman"
              text={values.keterangan_pengiriman ?? '-'}
              line={true}
            />
            <ShowData
              title="Lokasi Pengiriman"
              text={values.lokasi_pengiriman ?? '-'}
              line={true}
            />
            <ShowData
              title="Link Referensi"
              text={values.link_referensi ?? '-'}
              line={true}
            />
          </Col>
          <Col md>
            <ShowData
              title="Schedule Kebutuhan Proyek (Total Hari)"
              text={values.schedule_kebutuhan_proyek ?? '-'}
              line={true}
            />
            <ShowData
              title="Instalasi"
              text={
                values.instalasi === true ? (
                  <IoCheckboxOutline />
                ) : (
                  <IoCloseCircleOutline />
                )
              }
              line={true}
            />
          </Col>
        </Row>
        <TextEditor
          readOnly
          options={[]}
          label="Informasi Lainya"
          editorState={textEditorState}
        />
      </>
    );
  };

  // Tampilan jika type DETAIL
  if (TYPE === 'DETAIL') {
    return (
      <>
        <InfoSection />
        <hr />

        <h6>List Barang Jadi</h6>
        <TableBarangJadi
          type={TYPE}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          listBarangJadi={listBarangJadi}
          setListBarangJadi={setListBarangJadi}
        />
      </>
    );
  }

  // Tampilan jika type TAMBAH / UBAH
  return (
    <div>
      <Row>
        <Col sm>
          <DatePicker
            label="Tanggal Peluang"
            name="tgl_peluang"
            selected={values.tgl_peluang ? new Date(values.tgl_peluang) : ''}
            onChange={(val) => {
              const value = val.toISOString().slice(0, 10);
              getNomorBaru(value);
            }}
            error={errors.tgl_peluang && touched.tgl_peluang && true}
            errorText={errors.tgl_peluang}
          />
        </Col>
        <Col sm>
          <Input
            label="No Peluang"
            type="text"
            name="no_peluang"
            value={values?.no_peluang ?? ''}
            readOnly={true}
          />
        </Col>
      </Row>
      <hr />

      <Row>
        <Col>
          <SelectSearch
            label="Customer"
            placeholder="Pilih Customer"
            onChange={(val) => {
              setFieldValue('id_customer', val.value);
              getDataProyek(val.value);
            }}
            option={dropdown?.customer}
            defaultValue={
              values.id_customer
                ? dropdown?.customer?.find(
                    (val) => val.value === values.id_customer
                  )
                : ''
            }
            error={errors.id_customer && touched.id_customer && true}
            errorText={
              errors.id_customer && touched.id_customer && errors.id_customer
            }
          />

          <SelectSearch
            label="Proyek"
            isDisabled={!values.id_customer}
            placeholder={
              values.id_customer
                ? 'Pilih Proyek'
                : 'Pilih Customer untuk menentukan Proyek'
            }
            onChange={(val) => setFieldValue('id_proyek', val.value)}
            loading={isFetchDropdown.proyek}
            option={dropdown?.proyek ?? []}
            defaultValue={
              values.id_proyek
                ? dropdown?.proyek?.find(
                    (val) => val.value === values.id_proyek
                  )
                : ''
            }
            error={errors.id_proyek && touched.id_proyek && true}
            errorText={
              errors.id_proyek && touched.id_proyek && errors.id_proyek
            }
          />

          <SelectSearch
            label="Kelompok Proyek"
            placeholder="Pilih Kelompok Proyek"
            onChange={(val) => setFieldValue('kelompok_proyek', val.value)}
            loading={isFetchDropdown.proyek}
            option={dropdown?.kelompok_proyek ?? []}
            defaultValue={
              values.kelompok_proyek
                ? dropdown?.kelompok_proyek?.find(
                    (val) => val.value === values.kelompok_proyek
                  )
                : ''
            }
            error={errors.kelompok_proyek && touched.kelompok_proyek && true}
            errorText={
              errors.kelompok_proyek &&
              touched.kelompok_proyek &&
              errors.kelompok_proyek
            }
          />

          <SelectSearch
            label="Peringkat Peluang"
            placeholder="Pilih Peringkat Peluang"
            onChange={(val) => setFieldValue('id_peringkat_peluang', val.value)}
            option={dropdown?.peringkat}
            defaultValue={
              values.id_peringkat_peluang
                ? dropdown?.peringkat?.find(
                    (val) => val.value === values.id_peringkat_peluang
                  )
                : ''
            }
            error={
              errors.id_peringkat_peluang &&
              touched.id_peringkat_peluang &&
              true
            }
            errorText={
              errors.id_peringkat_peluang &&
              touched.id_peringkat_peluang &&
              errors.id_peringkat_peluang
            }
          />

          <Input
            label="ATT"
            type="text"
            value={values.att}
            onChange={(val) => setFieldValue('att', val.target.value)}
          />

          <div className="row">
            <div className="col-md-6">
              <DatePicker
                label="Target Penawaran"
                name="tgl_selesai"
                selected={
                  values.tgl_selesai ? new Date(values.tgl_selesai) : ''
                }
                onChange={(val) => {
                  const value = val.toISOString().slice(0, 10);
                  setFieldValue('tgl_selesai', value);
                }}
                error={errors.tgl_selesai && touched.tgl_selesai && true}
                errorText={errors.tgl_selesai}
              />
            </div>
            <div className="col-md-6">
              <DatePicker
                label="Realisasi Penawaran"
                name="realisasi_penawaran"
                selected={
                  values.realisasi_penawaran
                    ? new Date(values.realisasi_penawaran)
                    : ''
                }
                onChange={(val) => {
                  const value = val.toISOString().slice(0, 10);
                  setFieldValue('realisasi_penawaran', value);
                }}
                error={
                  errors.realisasi_penawaran &&
                  touched.realisasi_penawaran &&
                  true
                }
                errorText={errors.realisasi_penawaran}
              />
            </div>
          </div>

          <Input
            label="Schedule Kebutuhan Proyek (Total Hari)"
            type="number"
            value={values.schedule_kebutuhan_proyek}
            onChange={(val) =>
              setFieldValue('schedule_kebutuhan_proyek', val.target.value)
            }
            error={
              errors.schedule_kebutuhan_proyek &&
              touched.schedule_kebutuhan_proyek &&
              true
            }
            errorText={errors.schedule_kebutuhan_proyek}
          />

          <Form.Group className="mb-3" id="formGridCheckbox">
            <Form.Check
              type="checkbox"
              label="Instalasi"
              checked={values.instalasi}
              onChange={() => {
                setFieldValue('instalasi', !values.instalasi);
              }}
            />
          </Form.Group>
        </Col>

        <Col>
          <TextArea
            label="Keterangan Pengiriman"
            placeholder="Keterengan Pengiriman"
            value={values.keterangan_pengiriman}
            onChange={(val) =>
              setFieldValue('keterangan_pengiriman', val.target.value)
            }
            error={
              errors.keterangan_pengiriman &&
              touched.keterangan_pengiriman &&
              true
            }
            errorText={errors.keterangan_pengiriman}
          />

          <TextArea
            label="Lokasi Pengiriman"
            placeholder="Lokasi Pengiriman"
            value={values.lokasi_pengiriman}
            onChange={(val) =>
              setFieldValue('lokasi_pengiriman', val.target.value)
            }
            error={
              errors.lokasi_pengiriman && touched.lokasi_pengiriman && true
            }
            errorText={errors.lokasi_pengiriman}
          />
          <TextEditor
            label="Informasi Lainya"
            placeholder="Tuliskan informasi lainnya"
            editorState={textEditorState}
            onEditorStateChange={(val) => {
              updateEditorState(val, setFieldValue, 'lain_lain');
            }}
            editorHeight={480}
            error={errors.lain_lain && touched.lain_lain && true}
            errorText={
              errors.lain_lain && touched.lain_lain && errors.lain_lain
            }
          />
          <Input
            label="Link Referensi"
            placeholder="https://"
            onChange={(e) => setFieldValue('link_referensi', e.target.value)}
            value={values.link_referensi}
            error={Boolean(errors.link_referensi && touched.link_referensi)}
            errorText={
              Boolean(errors.link_referensi && touched.link_referensi) &&
              errors.link_referensi
            }
          />
        </Col>
      </Row>
      <hr />

      <TableBarangJadi
        dataBarangJadi={dataBarangJadi}
        setDataBarangJadi={setDataBarangJadi}
        listBarangJadi={listBarangJadi}
        setListBarangJadi={setListBarangJadi}
      />
    </div>
  );
};

export default TabInfo;

import { useRef, useState, useEffect } from 'react';
import { Row, Col, Card, Nav } from 'react-bootstrap';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  ActionButton,
  Alert,
  Approval,
  BackButton,
  DataStatus,
} from 'components';
import { RegistrasiPeluangApi } from 'api';
import { TabInfo, TabGambar, TabFile } from './Section';

const DetailRegistrasiPeluang = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const tabsRef = useRef();
  const { state } = useLocation();
  const [dataInfo, setDataInfo] = useState({});
  const [dataGambar, setDataGambar] = useState([]);
  const [dataFile, setDataFile] = useState([]);
  const [dataBarangJadi, setDataBarangJadi] = useState([]);
  const [listBarangJadi, setListBarangJadi] = useState([]);
  const [dropdown, setDropdown] = useState({
    customer: [],
    peringkat_peluang: [],
    barang_jadi: [],
  });

  const [defaultTabs, setDefaultTabs] = useState('info');
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: '',
    text: '',
  });

  // Konfigurasi komponen tabs
  const tabsConfig = [
    {
      tab: 'info',
      label: 'Informasi Umum',
      component: ({ ...rest }) => <TabInfo {...rest} />,
    },
    {
      tab: 'gambar',
      label: 'Gambar',
      component: ({ ...rest }) => <TabGambar {...rest} action="READ" />,
    },
    {
      tab: 'file',
      label: 'File',
      component: ({ ...rest }) => <TabFile {...rest} action="READ" />,
    },
  ];

  // Nilai awal pada form
  const formInitialValues = {
    tgl_peluang: dataInfo?.tgl_peluang ?? '',
    no_peluang: dataInfo?.no_peluang ?? '',
    id_customer: dataInfo?.id_customer ?? '',
    nama_customer: dataInfo?.nama_customer ?? '',
    id_proyek: dataInfo?.id_proyek ?? '',
    nama_proyek: dataInfo?.nama_proyek ?? '',
    att: dataInfo?.att ?? '',
    id_peringkat_peluang: dataInfo?.id_peringkat_peluang ?? '',
    nama_peringkat_peluang: dataInfo?.nama_peringkat_peluang ?? '',
    tgl_selesai: dataInfo?.tgl_selesai ?? '',
    realisasi_penawaran: dataInfo?.realisasi_penawaran ?? '',
    keterangan_pengiriman: dataInfo?.keterangan_pengiriman ?? '',
    instalasi: dataInfo?.instalasi ?? false,
    lokasi_pengiriman: dataInfo?.lokasi_pengiriman ?? '',
    lain_lain: dataInfo?.lain_lain ?? '',
    schedule_kebutuhan_proyek: dataInfo?.schedule_kebutuhan_proyek ?? '',
    link_referensi: dataInfo?.link_referensi ?? '',
    kelompok_proyek: dataInfo?.kelompok_proyek ?? '',
  };

  // Menangani perubahan pada tabs
  const onTabsChangeHandler = (e, tabs) => {
    e.preventDefault();
    setDefaultTabs(tabs);
  };

  // Menangani cek type dari file yang dikirim
  const checkTypeAndStoreFile = (data) => {
    let gambar = [];
    let file = [];

    data.map((val) => {
      const map = {
        link: val.path_gambar,
        id: val.id_peluang_gambar,
        nama: '',
        data: null,
      };

      const generalFileType = map.link.split('.').pop();
      const fileName = map.link.split('/').pop();

      // Check apakah data adalah gambar
      if (['jpg', 'png', 'gif', 'jpeg'].includes(generalFileType)) {
        return gambar.push({ ...map, nama: fileName });
      }

      return file.push({ ...map, nama: fileName });
    });

    setDataGambar(gambar);
    setDataFile(file);
  };

  // Fetch data pada saat page pertama kali dibuka
  const fetchInitialData = async () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    return await Axios.all([RegistrasiPeluangApi.getSingle(id)])
      .then(
        Axios.spread((data) => {
          const dataInfo = data?.data?.data ?? {};

          checkTypeAndStoreFile(dataInfo?.gambar ?? []);
          setDataInfo(dataInfo);
          setListBarangJadi(
            dataInfo?.detail?.map((val) =>
              Object({
                ...val,
                nama_satuan: val.nama_satuan_jual,
              })
            ) ?? []
          );

          setFetchingStatus({
            loading: false,
            success: true,
          });
        })
      )
      .catch(() => {
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  useEffect(async () => {
    setNavbarTitle('Registrasi Peluang');
    await fetchInitialData();
    return () => {};
  }, []);

  const FormCard = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Peluang</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataInfo?.stakeholder?.map(
              (val, index) =>
                index !== 0 &&
                val.status_approval !== 'PEN' && (
                  <Col sm>
                    <InfoItem
                      title1={
                        val.status_approval === 'VER' ||
                        val.status_approval === 'REV'
                          ? `Pemeriksa ${
                              val.approval_level !== '0'
                                ? val.approval_level
                                : ''
                            }`
                          : val.status_approval === 'APP'
                          ? 'Pengesah'
                          : 'Di Tolak Oleh'
                      }
                      value1={val.nama_karyawan ?? '-'}
                      title2="Catatan"
                      value2={val.catatan ?? '-'}
                    />
                  </Col>
                )
            )}
          </Row>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {/* Title */}
      <div className="d-flex justify-content-between align-items-center pb-2">
        <h6 className="p-1">
          <b>Detail Data Peluang</b>
        </h6>
        <BackButton
          size="sm"
          onClick={() =>
            history.push('/transaksi/registrasi-peluang', { ...state })
          }
        />
      </div>

      {/* Alert */}
      <Alert
        showCloseButton
        show={alertConfig.show}
        text={alertConfig.text}
        variant={alertConfig.variant}
        onClose={() =>
          setAlertConfig({
            show: false,
            variant: '',
            text: '',
          })
        }
      />

      <Card>
        <Card.Header>
          {/* Tab Header */}
          <Nav variant="tabs" defaultActiveKey={`#${defaultTabs}`}>
            {tabsConfig.map((val, index) => (
              <Nav.Item key={index}>
                <Nav.Link
                  ref={tabsRef}
                  href={`#${val.tab}`}
                  onClick={(e) => onTabsChangeHandler(e, val.tab)}
                >
                  {val.label}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {fetchingStatus.loading ? (
            <DataStatus loading text="Memuat data . . ." />
          ) : !fetchingStatus.success ? (
            <DataStatus text="Data gagal dimuat!" />
          ) : (
            <>
              <Formik initialValues={formInitialValues}>
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    {/* Tab Body */}
                    {tabsConfig.map(
                      ({ tab, component: Component }, index) =>
                        tab === defaultTabs && (
                          <Component
                            key={index}
                            type="DETAIL"
                            formik={formik}
                            dataGambar={dataGambar}
                            dataFile={dataFile}
                            dropdown={dropdown}
                            listBarangJadi={listBarangJadi}
                            dataBarangJadi={dataBarangJadi}
                            alertConfig={alertConfig}
                            setListBarangJadi={setListBarangJadi}
                            setDataBarangJadi={setDataBarangJadi}
                            setDropdown={setDropdown}
                            setDataGambar={setDataGambar}
                            setDataFile={setDataFile}
                            setAlertConfig={setAlertConfig}
                          />
                        )
                    )}
                  </form>
                )}
              </Formik>
              <Approval data={dataInfo} />
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default DetailRegistrasiPeluang;

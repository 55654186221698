import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { IoAddOutline } from 'react-icons/io5';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  BackButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  FilterButton,
} from 'components';
import { DateConvert, PageNumber as TableNumber } from 'utilities';
import { SuratPerjanjianKerjaApi } from 'api';
import { ModalFilter } from './Section';

const PenawaranSPK = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataPenawaranSPK, setDataPenawaranSPK] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.penawaran?.filter?.active,
      tgl_penawaran_mulai:
        location?.state?.penawaran?.filter?.tgl_penawaran_mulai,
      tgl_penawaran_selesai:
        location?.state?.penawaran?.filter?.tgl_penawaran_selesai,
      customer: location?.state?.penawaran?.filter?.customer,
      proyek: location?.state?.penawaran?.filter?.proyek,
      realisasi_penawaran_mulai:
        location?.state?.penawaran?.filter?.realisasi_penawaran_mulai,
      realisasi_penawaran_selesai:
        location?.state?.penawaran?.filter?.realisasi_penawaran_selesai,
    },
    pagination: {
      page: location?.state?.penawaran?.filter?.page ?? '1',
      dataLength: location?.state?.penawaran?.filter?.dataLength ?? '10',
      totalPage: location?.state?.penawaran?.filter?.totalPage ?? '1',
      dataCount: location?.state?.penawaran?.filter?.dataCount ?? '0',
    },
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    SuratPerjanjianKerjaApi.getPenawaran({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_penawaran_mulai: dataFilter?.filter?.tgl_penawaran_mulai,
      tgl_penawaran_selesai: dataFilter?.filter?.tgl_penawaran_selesai,
      customer: dataFilter?.filter?.customer,
      proyek: dataFilter?.filter?.proyek,
      realisasi_penawaran_mulai: dataFilter?.filter?.realisasi_penawaran_mulai,
      realisasi_penawaran_selesai:
        dataFilter?.filter?.realisasi_penawaran_selesai,
    })
      .then((data) => {
        setDataPenawaranSPK(data?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: '1',
        dataLength: '10',
        totalPage: '1',
        dataCount: '0',
      },
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  useEffect(() => {
    setNavbarTitle('Surat Perjanjian Kerja (SPK)');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_penawaran_mulai,
    dataFilter?.filter?.tgl_penawaran_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.proyek,
    dataFilter?.filter?.realisasi_penawaran_mulai,
    dataFilter?.filter?.realisasi_penawaran_selesai,
  ]);

  const BarangJadiCollapse = ({ data }) => {
    const [isReadMoreClick, setisReadMoreClick] = useState(false);

    return (
      <>
        <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
          {data.map((brg, index) => {
            if (isReadMoreClick) {
              return <li index={index}>{brg.nama_item}</li>;
            } else {
              return index <= 2 && <li index={index}>{brg.nama_item}</li>;
            }
          })}
        </ul>
        {data.length <= 2 ? (
          ''
        ) : (
          <div
            className="text-primary"
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              // fontWeight: "bold",
            }}
            onClick={() => {
              setisReadMoreClick((prev) => !prev);
            }}
          >
            {isReadMoreClick ? 'Sembunyikan' : 'Selengkapnya...'}
          </div>
        )}
      </>
    );
  };

  const PageContent = () => {
    const DataTable = () => (
      <>
        <span style={{ fontSize: '14px' }}>
          <b>Penawaran Yang Siap Didaftarkan Menjadi SPK</b>
        </span>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Penawaran</ThFixed>
              <Th>Customer</Th>
              <Th>Proyek</Th>
              <Th>ATT</Th>
              <ThFixed>Tgl. Realisasi Penawaran</ThFixed>
              <Th>Barang Jadi</Th>
              <ThFixed>Baseline</ThFixed>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dataPenawaranSPK.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>
                  {TableNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div>
                    {val.tgl_penawaran
                      ? DateConvert(new Date(val.tgl_penawaran)).custom
                      : '-'}
                  </div>
                  <div>{val.no_penawaran ? val.no_penawaran : '-'}</div>
                </TdFixed>
                <Td style={{ minWidth: '280px' }}>
                  {val.nama_customer ? val.nama_customer : '-'}
                </Td>
                <Td style={{ minWidth: '280px' }}>{val.nama_proyek ?? '-'}</Td>
                <Td style={{ minWidth: '200px' }}>{val.att ? val.att : '-'}</Td>
                <TdFixed>
                  {val.realisasi_penawaran
                    ? DateConvert(new Date(val.realisasi_penawaran)).custom
                    : '-'}
                </TdFixed>
                <Td style={{ minWidth: '300px' }}>
                  {val.barang_jadi ? (
                    val.barang_jadi.length > 0 ? (
                      <BarangJadiCollapse data={val.barang_jadi} />
                    ) : (
                      '-'
                    )
                  ) : (
                    '-'
                  )}
                </Td>
                <TdFixed textCenter>{val.baseline ?? '-'}</TdFixed>
                <TdFixed
                  isAlignTop={val?.barang_jadi?.length > 0 ? true : false}
                >
                  <div className="d-flex">
                    <ReadButton
                      onClick={() =>
                        history.push(
                          '/transaksi/penawaran/detail/' + val.id_penawaran,
                          { ...location?.state, penawaran: dataFilter }
                        )
                      }
                    />
                    <ActionButton
                      size="sm"
                      variant="success"
                      className="m-1"
                      text={<IoAddOutline />}
                      onClick={() =>
                        history.push(
                          '/transaksi/spk/tambah/' + val.id_penawaran,
                          { ...location?.state, penawaran: dataFilter }
                        )
                      }
                      tooltip={true}
                      tooltipText="Register"
                    />
                  </div>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    if (!dataPenawaranSPK || dataPenawaranSPK.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              value={searchConfig.key}
              onChange={onInputSearchChange}
            />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() =>
              history.push('/transaksi/spk', {
                ...location?.state,
                penawaran: dataFilter,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
    </CRUDLayout>
  );
};

export default PenawaranSPK;

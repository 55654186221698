import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import {
  ReadModal,
  InfoItemHorizontal,
  Table,
  THead,
  Th,
  ThFixed,
  TBody,
  Td,
  TdFixed,
  Tr,
} from 'components';
import { RencanaAnggaranBiayaApi } from 'api';
import Axios from 'axios';
import { DateConvert, RupiahConvert } from 'utilities';
import { useHistory, useParams } from 'react-router-dom';

const ModalDetail = ({ show, onHide, processedData }) => {
  const { id } = useParams();
  const [detailRAE, setDetailRAE] = useState({});
  const [dataBarangJadi, setDataBarangJadi] = useState([]);
  const [dataPrelim, setDataPrelim] = useState([]);
  const [fetchConfig, setFetchConfig] = useState({
    loading: true,
    status: false,
  });
  // GET DATA SERVER
  const getInitialData = () => {
    setFetchConfig({ ...fetchConfig, loading: true });

    RencanaAnggaranBiayaApi.getDetailDataRAE({ id_rae: processedData.id_rae })

      .then((rae) => {
        setDetailRAE(rae?.data?.data ?? '-');
        const barangJadi = rae?.data?.data?.detail
          ? rae?.data?.data?.detail
          : [];
        const prelim = rae?.data?.data?.prelim ? rae?.data?.data?.prelim : [];

        const filterBarangJadi = barangJadi.map((val) => ({
          id_rae_detail: val.id_rae_detail,
          id_barang_jadi: val.id_barang_jadi,
          nama_item: val.nama_item,
          kode_item: val.kode_item,
          qty: val.qty_rae,
          satuan: val.nama_satuan,
          harga_satuan: val.harga_satuan_rae,
        }));
        const filterPrelim = prelim.map((val, index) => ({
          id_kelompok: val.id_kelompok,
          id_item: val.id_item_prelim,
          id_satuan_durasi: val.id_satuan_waktu,
          kode_item: val.kode_item_prelim,
          nama_item: val.nama_item_prelim,
          qty_item: val.qty_analisa,
          id_unit: val.id_satuan_prelim,
          nama_unit: val.nama_satuan_prelim,
          satuan_durasi: val.nama_satuan_waktu,
          harga: parseFloat(val.unit_price ?? 0),
          qty_durasi: parseFloat(val.qty_durasi ?? 0),
          konstanta: parseFloat(val.konstanta ?? 0),
          order: index + 1,
        }));

        setDataPrelim(filterPrelim);
        setDataBarangJadi(filterBarangJadi);

        setFetchConfig({
          loading: false,
          success: true,
        });
      })
      .catch(() => {
        setFetchConfig({
          loading: false,
          success: false,
        });
      });
  };
  useEffect(() => {
    getInitialData();
  }, []);
  // TOTAL HARGA
  const grandTotal = () => {
    const totalBarangJadi = dataBarangJadi.reduce((prevValue, currentValue) => {
      const checkQty = currentValue.qty_rae
        ? parseFloat(currentValue.qty_rae)
        : 0;
      const checkSatuan = currentValue.harga_satuan_rae
        ? parseInt(currentValue.harga_satuan_rae)
        : 0;
      const subTotal = parseInt(checkQty * checkSatuan);
      const total = parseInt(prevValue + subTotal);

      return total;
    }, 0);

    const totalPrelim = dataPrelim.reduce((prev, current) => {
      const newQtyItem = current.qty_analisa
        ? parseFloat(current.qty_analisa)
        : 0;
      const newHarga = current.harga_satuan_prelim
        ? parseFloat(current.harga_satuan_prelim)
        : 0;
      const newQtyDurasi = current.qty_durasi
        ? parseFloat(current.qty_durasi)
        : 0;
      const newKonts = current.konstanta ? parseFloat(current.konstanta) : 0;
      const subTotal = newQtyItem * newHarga;
      const total = subTotal * newQtyDurasi * newKonts;

      return prev + total;
    }, 0);

    const grandTotal = totalBarangJadi + totalPrelim;

    return {
      total: totalBarangJadi,
      prelim: totalPrelim,
      grandTotal: grandTotal,
    };
  };
  // DATA TABLE BARANG JADI
  const TableData = ({ val, index }) => {
    const checkQty = val?.qty ? parseFloat(val.qty) : 0;
    const checkSatuan = val?.harga_satuan ? parseInt(val.harga_satuan) : 0;
    const subTotal = parseInt(checkQty * checkSatuan);

    return (
      <tr key={index}>
        <td>{val.kode_item ?? '-'}</td>
        <td>{val.nama_item ?? '-'}</td>
        <td className="text-right">{val.qty ?? '-'}</td>
        <td>{val.satuan ?? '-'}</td>
        <td className="text-right">
          {RupiahConvert(parseInt(val.harga_satuan).toString()).detail}
        </td>
        <td className="text-right">
          {RupiahConvert(subTotal?.toString()).detail}
        </td>
      </tr>
    );
  };

  return (
    <div className="p-4">
      <ReadModal show={show} onHide={onHide} title="RAE" size="xl">
        <Card>
          <Card.Body>
            <Row>
              <Col md={6}>
                <InfoItemHorizontal
                  label="Tgl. Peluang"
                  text={
                    detailRAE.tgl_peluang
                      ? DateConvert(new Date(detailRAE.tgl_peluang)).detail
                      : '-'
                  }
                />
                <InfoItemHorizontal
                  label="No. Peluang"
                  text={detailRAE.no_peluang ? detailRAE.no_peluang : '-'}
                />
                <InfoItemHorizontal
                  label="Peringkat Peluang"
                  text={
                    detailRAE.nama_peringkat_peluang
                      ? detailRAE.nama_peringkat_peluang
                      : '-'
                  }
                />
                <InfoItemHorizontal
                  label="Realisasi Penawaran"
                  text={
                    detailRAE.realisasi_penawaran
                      ? DateConvert(new Date(detailRAE.realisasi_penawaran))
                          .detail
                      : '-'
                  }
                />
              </Col>
              <Col md={6}>
                <InfoItemHorizontal
                  label="Customer"
                  text={detailRAE.nama_customer ? detailRAE.nama_customer : '-'}
                />
                <InfoItemHorizontal
                  label="ATT"
                  text={detailRAE.att ? detailRAE.att : '-'}
                />
                <InfoItemHorizontal
                  label="Kelompok Proyek"
                  text={
                    detailRAE.kelompok_proyek ? detailRAE.kelompok_proyek : '-'
                  }
                />
                <InfoItemHorizontal
                  label="Target Penawaran"
                  text={
                    detailRAE.tgl_selesai
                      ? DateConvert(new Date(detailRAE.tgl_selesai)).detail
                      : '-'
                  }
                />
                <InfoItemHorizontal
                  label="Schedule Kebutuhan Proyek (Total Hari)"
                  text={
                    detailRAE.schedule_kebutuhan_proyek
                      ? detailRAE.schedule_kebutuhan_proyek
                      : '-'
                  }
                />
                <InfoItemHorizontal
                  label="Proyek"
                  text={detailRAE.nama_proyek ? detailRAE.nama_proyek : '-'}
                />
              </Col>
            </Row>
            <hr />
            <div className="py-2 p-1">
              <b>List Barang Jadi</b>
            </div>
            <table
              className="table table-sm table-bordered"
              style={{ fontSize: '14px' }}
            >
              <thead className="text-center bg-light">
                <tr>
                  <th style={{ width: '100px' }}>Kode Barang Jadi</th>
                  <th className="align-middle" style={{ width: '350px' }}>
                    Barang Jadi
                  </th>
                  <th className="align-middle">Qty</th>
                  <th className="align-middle">Satuan</th>
                  <th className="align-middle">Harga Satuan</th>
                  <th className="align-middle">Sub Total</th>
                </tr>
              </thead>
              <tbody>
                {dataBarangJadi.map((val, index) =>
                  val !== null ? <TableData val={val} index={index} /> : null
                )}
                <tr>
                  <td></td>
                  <td>
                    <b>PRELIM</b>
                  </td>
                  <td className="text-right">1</td>
                  <td className="text-right" colSpan={2}>
                    {RupiahConvert(String(grandTotal().prelim)).detail}
                  </td>
                  <td className="text-right">
                    {RupiahConvert(String(grandTotal().prelim)).detail}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                {/* Total */}
                <tr className="bg-light">
                  <td colSpan={5} className="text-right py-2 align-middle">
                    <b>Grand Total :</b>
                  </td>
                  <td className="text-right align-middle">
                    <b>
                      {RupiahConvert(String(grandTotal().grandTotal)).detail}
                    </b>
                  </td>
                </tr>
              </tfoot>
            </table>
          </Card.Body>
        </Card>
      </ReadModal>
    </div>
  );
};
export default ModalDetail;

import React, { useState, useEffect } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { EditorState } from 'draft-js';
import Axios from 'axios';
import { DataStatus, BackButton, Alert, ActionButton } from 'components';
import { SuratPerjanjianKerjaApi } from 'api';
import {
  InfoSection,
  FormSection,
  TableSPK,
  TableLabourCost,
  TableMachineProcess,
  TextEditorAndButtonSection,
  ModalPrelim,
  TablePrelim,
} from './Content';

// View
import AnalisaBarangJadiSPK from '../AnalisaBarangJadiSPK';
import PeluangSPK from '../PeluangSPK';

const TambahSPK = ({ setNavbarTitle }) => {
  const { id } = useParams();
  const history = useHistory();
  const [dataPenawaran, setDataPenawaran] = useState({});
  const [dataSPK, setDataSPK] = useState([]);
  const [dataPrelim, setDataPrelim] = useState([]);
  const [dataLabourCost, setDataLabourCost] = useState([]);
  const [dataMachineProcess, setDataMachineProcess] = useState([]);
  const [dataSegmentPasar, setDataSegmentPasar] = useState([]);
  const [catatan, setCatatan] = useState(EditorState.createEmpty());
  const [processedData, setProcessedData] = useState({});
  const [modalPeluangConfig, setModalPeluangConfig] = useState({
    show: false,
    no_peluang: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });
  const [fetchingStatus, setFetchingStatus] = useState({
    success: true,
    loading: true,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modalPrelim, setModalPrelim] = useState({
    show: false,
  });

  // Mapping data item barang
  const mappingDataBarang = (data) => {
    return data.map((val) => {
      return {
        id_barang_jadi: val.id_barang_jadi,
        id_rab_detail: val.id_rab_detail,
        id_item: val.id_barang_jadi,
        kode_item: val.kode_item,
        nama_item: val.nama_item,
        qty: val.qty_rab,
        unit: val.nama_satuan,
        uraian: '',
        harga_rate: val.rounded ? parseInt(val.rounded) : 0,
      };
    });
  };

  // Mapping data labour cost
  const mappingDataLC = (data) => {
    return data.map((val) => {
      return {
        id_item: val.id_item_buaso,
        kode_item: val.kode_item,
        nama_item: val.nama_item,
        qty: val.qty,
        unit: val.nama_satuan,
        konstanta: val.konstanta ? parseFloat(val.konstanta) : 1,
        unit_price: val.unit_price ? parseInt(val.unit_price) : 0,
      };
    });
  };

  // Mapping data machine process
  const mappingDataMP = (data) => {
    return data.map((val) => {
      return {
        id_item: val.id_item_buaso,
        kode_item: val.kode_item,
        nama_item: val.nama_item,
        qty: val.qty,
        unit: val.nama_satuan,
        konstanta: val.konstanta ? parseFloat(val.konstanta) : 1,
        unit_price: val.unit_price ? parseInt(val.unit_price) : 0,
      };
    });
  };

  // Fetching data pada saat halaman dikunjungi
  const getInitialData = () => {
    setFetchingStatus({
      ...fetchingStatus,
      loading: true,
    });

    Axios.all([
      SuratPerjanjianKerjaApi.getSinglePenawaran({ id_penawaran: id }),
      SuratPerjanjianKerjaApi.getDropdownSegmentasiPasar(),
    ])
      .then(
        Axios.spread((penawaran, dropdown) => {
          const dataInfo = penawaran.data.data;
          const dataAwalSPK = dataInfo.detail_barang_jadi;
          const dataAwalLC = dataInfo.detail_upah;
          const dataAwalMP = dataInfo.detail_alat_mesin;

          const convertDataBarang = mappingDataBarang(dataAwalSPK ?? []);
          const convertDataLC = mappingDataLC(dataAwalLC ?? []);
          const convertDataMP = mappingDataMP(dataAwalMP ?? []);
          const filterPrelim = dataInfo?.detail_prelim?.map((val, index) => ({
            id_kelompok: val.id_kelompok,
            id_item: val.id_item_prelim,
            kode_item: val.kode_item_prelim,
            nama_item: val.nama_item_prelim,
            qty_item: val.qty_analisa,
            id_unit: val.id_satuan_prelim,
            nama_unit: val.nama_satuan_prelim,
            harga: parseFloat(val.unit_price ?? 0),
            qty_durasi: parseFloat(val.qty_durasi ?? 0),
            konstanta: parseFloat(val.konstanta ?? 0),
            order: index + 1,
          }));
          const dropdownSegPasar = dropdown?.data?.data?.map((item) =>
            Object({
              ...item,
              value: item.id_segmentasi_pasar,
              label: item.nama_segmentasi_pasar,
            })
          );

          setDataPenawaran(dataInfo);
          setDataSPK(convertDataBarang);
          setDataLabourCost(convertDataLC);
          setDataMachineProcess(convertDataMP);
          setDataPrelim(filterPrelim);
          setDataSegmentPasar(dropdownSegPasar);
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() =>
        setFetchingStatus({
          ...fetchingStatus,
          loading: false,
        })
      );
  };

  const ModalSection = ({
    processedData,
    dataBarangJadi,
    setDataBarangJadi,
    alertConfig,
    setAlertConfig,
    modalConfig,
    setModalConfig,
  }) => {
    const AnalisaBarangJadiModal = ({
      processedData,
      dataBarangJadi,
      setDataBarangJadi,
      alertConfig,
      setAlertConfig,
      modalConfig,
      setModalConfig,
    }) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadiSPK
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            modalConfig={modalConfig}
            setAlertConfig={setAlertConfig}
            setModalConfig={setModalConfig}
          />
        </Modal.Body>
      );
    };

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === 'analisa' ? 'xl' : 'md'}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <AnalisaBarangJadiModal
          processedData={processedData}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      </Modal>
    );
  };

  // Data awal form
  const formInitialValues = {
    tgl_spk: '',
    no_spk: '',
    tgl_selesai: '',
    catatan: '',
    ppn_spk: dataPenawaran.ppn ? parseFloat(dataPenawaran.ppn) : 0,
    diskon_spk: dataPenawaran.diskon ? parseFloat(dataPenawaran.diskon) : 0,
    id_segmentasi_pasar: '',
    uang_muka: 0,
  };

  // Skema validasi form
  const formValidationSchema = Yup.object().shape({
    tgl_spk: Yup.string().required('Pilih tanggal SPK'),
    tgl_selesai: Yup.string().required('Pilih tanggal selesai'),
    id_segmentasi_pasar: Yup.string().required('Pilih segment pasar'),
  });

  // Menangani submit form
  const formSubmitHandler = (values) => {
    const mappingValuesBarangJadi = dataSPK.map((val) => {
      return {
        id_barang_jadi: val.id_item,
        uraian: val.uraian,
        qty_spk: val.qty,
        harga_rate: val.harga_rate,
      };
    });

    const mappingValuesUpah = dataLabourCost.map((val) => {
      return {
        id_item_buaso: val.id_item,
        qty: val.qty,
        unit_price: val.unit_price,
        konstanta: val.konstanta,
      };
    });

    const mappingValuesAlatMesin = dataMachineProcess.map((val) => {
      return {
        id_item_buaso: val.id_item,
        qty: val.qty,
        unit_price: val.unit_price,
        konstanta: val.konstanta,
      };
    });

    const finalValues = {
      id_penawaran: id,
      id_segmentasi_pasar: values.id_segmentasi_pasar,
      tgl_spk: values.tgl_spk,
      no_spk: values.no_spk,
      tgl_selesai_spk: values.tgl_selesai,
      catatan: values.catatan,
      ppn: values.ppn_spk ? values.ppn_spk : 0,
      detail_barang_jadi: mappingValuesBarangJadi,
      uang_muka: values.uang_muka ? values.uang_muka : 0,
      // detail_upah: mappingValuesUpah,
      // detail_alat_mesin: mappingValuesAlatMesin,
    };

    SuratPerjanjianKerjaApi.save(finalValues)
      .then(() =>
        history.push('/transaksi/spk', {
          alert: {
            show: true,
            variant: 'primary',
            text: 'Data berhasil disimpan!',
          },
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          type: 'danger',
          text: 'Data gagal disimpan!',
        });
      });
  };

  useEffect(() => {
    setNavbarTitle('Surat Perjanjian Kerja (SPK)');
    getInitialData();

    return () => {};
  }, []);

  // Menampilkan loading pada saat fetching data
  if (fetchingStatus.loading) {
    return (
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Registrasi Surat Perjanjian Kerja (SPK)</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <DataStatus loading text="Memuat data . . ." />
        </Card.Body>
      </Card>
    );
  }

  // Menampilkan status ketika data gagal diperoleh
  if (!fetchingStatus.success) {
    return (
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Registrasi Surat Perjanjian Kerja (SPK)</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <DataStatus text="Data gagal dimuat!" />
        </Card.Body>
      </Card>
    );
  }

  // Menampilkan semua isi dari halaman
  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <div>
          <b>Tambah Surat Perjanjian Kerja (SPK)</b>
        </div>
        <div className="d-flex align-items-center">
          {!fetchingStatus.loading && fetchingStatus.success && (
            <ActionButton
              variant="success"
              text="Ubah RAB"
              className="mb-1 mr-2"
              onClick={() =>
                history.push({
                  pathname:
                    '/transaksi/rencana-anggaran-biaya/ubah/' +
                    dataPenawaran?.id_rab,
                  state: { nonBaseline: true },
                })
              }
            />
          )}
          <BackButton onClick={() => history.goBack()} />
        </div>
      </Card.Header>
      <Card.Body>
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <>
              <InfoSection
                dataPenawaran={dataPenawaran}
                setModalPeluangConfig={setModalPeluangConfig}
              />
              <hr />
              <FormSection
                formik={formik}
                dataSegmentPasar={dataSegmentPasar}
              />
              <TableSPK
                formik={formik}
                dataSPK={dataSPK}
                dataPrelim={dataPrelim}
                setDataSPK={setDataSPK}
                processedData={processedData}
                setProcessedData={setProcessedData}
                setModalConfig={setModalConfig}
                setModalPrelim={setModalPrelim}
              />
              <TextEditorAndButtonSection formik={formik} />
              <ModalSection
                processedData={processedData}
                dataBarangJadi={dataSPK}
                setDataBarangJadi={setDataSPK}
                alertConfig={alertConfig}
                setAlertConfig={setAlertConfig}
                modalConfig={modalConfig}
                setModalConfig={setModalConfig}
              />
            </>
          )}
        </Formik>

        {/* Modal Prelim */}
        <ModalPrelim
          show={modalPrelim.show}
          onHide={() => setModalPrelim({ show: false })}
          dataPrelim={dataPrelim}
          onSubmit={(val) => {
            setDataPrelim(val);
            setModalPrelim({ show: false });
          }}
        />

        {/* Modal Peluang */}
        {modalPeluangConfig.show && (
          <PeluangSPK
            type="TAMBAH"
            modalPeluangConfig={modalPeluangConfig}
            setModalPeluangConfig={setModalPeluangConfig}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default TambahSPK;

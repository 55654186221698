import { Services } from 'services';

class LaporanGeneralMarketingApi {
  getPage(params) {
    return Services.get('/laporan_general_marketing', { params });
  }

  getGeneralMarketingExport(params) {
    return Services.get('/general_marketing_report', { params });
  }
}

export default new LaporanGeneralMarketingApi();

import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Card, Nav } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import htmlParser from 'html-react-parser';
import Axios from 'axios';
import ReactToPrint from 'react-to-print';
import {
  Alert,
  ActionButton,
  BackButton,
  DataStatus,
  SelectSearch,
} from 'components';
import { RupiahConvert } from 'utilities';
import { AnalisaBarangJadiApi } from 'api';
import {
  TableListHardwood,
  TableListPlywood,
  TableListTPFS,
  TableListTPLC,
  TableListTPMP,
  TableListTPBOP,
  TableListFNBP,
  TableListFNSC,
  TableListFNLC,
  TableListSummary,
} from './Table';
// import PrintAnalisaBarangJadi from "../PrintAnalisaBarangJadi";
import FilesUpload from './FilesUpload';

const FormAnalisaBarangJadi = ({
  processedData,
  dataBarangJadi,
  setDataBarangJadi,
  alertConfig,
  setAlertConfig,
  modalConfig,
  setModalConfig,
}) => {
  const history = useHistory();
  const id = processedData.id_barang_jadi;
  const ref_uid = processedData.ref_uid;
  const componentRef = useRef();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataAnalisaBarangJadi, setDataAnalisaBarangJadi] = useState({});
  const [dataBahanBaku, setDataBahanBaku] = useState({});
  const [dataBahanPenunjang, setDataBahanPenunjang] = useState({});
  const [dataFinishing, setDataFinishing] = useState({});
  const [dataOnSite, setDataOnSite] = useState({});
  const [dataPrint, setDataPrint] = useState({});
  const [dataKayu, setDataKayu] = useState([]);
  const [dataKelompokTipeBarangJadi, setDataKelompokTipeBarangJadi] = useState(
    []
  );
  const [dataTipeBarangJadi, setDataTipeBarangJadi] = useState([]);
  const [dataFinishingBarangJadi, setDataFinishingBarangJadi] = useState([]);
  const [filesUpload, setFilesUpload] = useState([]);
  const [dropdownTipeSisi, setDropdownTipeSisi] = useState([]);
  const [dropdownTipeFinishing, setDropdownTipeFinishing] = useState([]);
  const [dropdownSpekKayu, setDropdownSpekKayu] = useState([]);
  const [dropdownSpekPlywood, setDropdownSpekPlywood] = useState([]);

  const getBahanBaku = () =>
    AnalisaBarangJadiApi.getSingle({
      id_barang_jadi: id,
      ref_uid: ref_uid ? ref_uid : '',
      tipe: 'baku',
    })
      .then((res) => {
        setDataBahanBaku(res.data.data);
      })
      .catch((res) => {})
      .finally((res) => {});

  const getPenunjangProduksi = () =>
    AnalisaBarangJadiApi.getSingle({
      id_barang_jadi: id,
      ref_uid: ref_uid ? ref_uid : '',
      tipe: 'penunjang',
    })
      .then((res) => {
        setDataBahanPenunjang(res.data.data);
      })
      .catch((res) => {})
      .finally((res) => {});

  const getFinishing = () =>
    AnalisaBarangJadiApi.getSingle({
      id_barang_jadi: id,
      ref_uid: ref_uid ? ref_uid : '',
      tipe: 'fin',
    })
      .then((res) => {
        setDataFinishing(res.data.data);
      })
      .catch((res) => {})
      .finally((res) => {});

  // const getOnSite = () =>
  //   AnalisaBarangJadiApi.getSingle({ id_barang_jadi: id, tipe: "os" })
  //     .then((res) => {
  //       setDataOnSite(res.data.data);
  //     })
  //     .catch((res) => {})
  //     .finally((res) => {});

  const getInitialData = () => {
    setIsPageLoading(true);

    Axios.all([
      AnalisaBarangJadiApi.getSingle({
        id_barang_jadi: id,
        ref_uid: ref_uid ? ref_uid : '',
        tipe: 'umum',
      }),
      // AnalisaBarangJadiApi.getKayu(),
      AnalisaBarangJadiApi.getKelompokTipeBarangJadi(),
      AnalisaBarangJadiApi.getTipeBarangJadi(),
      AnalisaBarangJadiApi.getFinishingBarangJadi(),
      AnalisaBarangJadiApi.getSingle({ id_barang_jadi: id, tipe: 'gambar' }),
      AnalisaBarangJadiApi.getDropdownTipeSisi(),
      AnalisaBarangJadiApi.getDropdownTipeFinishing(),
      AnalisaBarangJadiApi.getDropdownSpekKayu(),
      AnalisaBarangJadiApi.getDropdownSpekPlywood(),
    ])
      .then(
        Axios.spread(
          (
            barang,
            kelompokTipe,
            tipeBarang,
            finishing,
            img,
            tipeSisi,
            tipeFinishing,
            spekKayu,
            spekPlywood
          ) => {
            setDataAnalisaBarangJadi(barang.data.data);
            // setDataKayu(kayu.data.data);
            setDataKelompokTipeBarangJadi(kelompokTipe.data.data);
            setDataTipeBarangJadi(tipeBarang.data.data);
            setDataFinishingBarangJadi(finishing.data.data);
            setFilesUpload(img?.data?.data?.gambar ?? []);

            setDropdownTipeSisi(tipeSisi?.data?.data);
            setDropdownTipeFinishing(tipeFinishing?.data.data);
            setDropdownSpekKayu(spekKayu.data.data);
            setDropdownSpekPlywood(spekPlywood.data.data);
          }
        )
      )
      .catch(() => {
        setIsFetchingFailed(false);
      })
      .finally(() => getBahanBaku())
      .finally(() => getPenunjangProduksi())
      .finally(() => {
        getFinishing();
        setIsPageLoading(false);
      });
    // .finally(() => {
    //   getOnSite();
    //   setIsPageLoading(false);
    // });
  };

  // const DataPrint = () => {
  //   setDataPrint({
  //     ...dataAnalisaBarangJadi,
  //     analisa_hardwood: dataBahanBaku.analisa_hardwood.map((val) => ({
  //       id_barang_jadi: val.id_barang_jadi,
  //       urutan_item: val.urutan_item,
  //       deskripsi: val.deskripsi,
  //       is_header: val.is_header,
  //       id_kayu: val.id_kayu,
  //       qty_raw: val.qty_raw,
  //       t_raw: val.t_raw,
  //       w_raw: val.w_raw,
  //       l_raw: val.l_raw,
  //       qty_final: val.qty_final,
  //       t_final: val.t_final,
  //       w_final: val.w_final,
  //       l_final: val.l_final,
  //       unit_price: val.unit_price,
  //       konstanta: val.konstanta,
  //     })),
  //   });
  // };

  useEffect(() => {
    getInitialData();

    return () => {};
  }, []);

  const InfoSection = () => {
    const [tabs, setTabs] = useState('informasi'); //State untuk menampung tabs yang aktif

    const InfoItem = ({ title, value }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <b>{value}</b>
      </div>
    );

    const InfoItemUraian = ({ title, value }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <div style={{ height: '400px', overflowY: 'auto' }}>{value}</div>
      </div>
    );

    const InfoItemLink = ({ title, value, text }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <b>
          <a href={value} target="_blank">
            {text}
          </a>
        </b>
      </div>
    );

    const tabsConfig = [
      {
        tab: 'informasi',
        label: 'Informasi Umum',
        component: () => (
          <Row>
            <Col>
              <InfoItem
                title="Kode Barang Jadi"
                value={
                  dataAnalisaBarangJadi?.kode_item
                    ? dataAnalisaBarangJadi?.kode_item
                    : '-'
                }
              />
              <InfoItem
                title="Nama Barang Jadi"
                value={
                  dataAnalisaBarangJadi?.nama_item
                    ? dataAnalisaBarangJadi?.nama_item
                    : '-'
                }
              />
              <InfoItem
                title="Sumber Barang Jadi"
                value={
                  dataAnalisaBarangJadi?.nama_kelompok
                    ? dataAnalisaBarangJadi?.nama_kelompok
                    : '-'
                }
              />
              <InfoItem
                title="Satuan Jual"
                value={
                  dataAnalisaBarangJadi?.nama_satuan
                    ? dataAnalisaBarangJadi?.nama_satuan
                    : '-'
                }
              />
              {dataAnalisaBarangJadi?.link_referensi ? (
                <InfoItemLink
                  title="Link Referensi"
                  value={dataAnalisaBarangJadi?.link_referensi}
                  text="Lihat Referensi"
                />
              ) : (
                <InfoItem title="Link Referensi" value="-" />
              )}
            </Col>
            <Col>
              <InfoItemUraian
                title="Uraian"
                value={
                  dataAnalisaBarangJadi.uraian
                    ? htmlParser(dataAnalisaBarangJadi.uraian)
                    : '-'
                }
              />
            </Col>
          </Row>
        ),
      },
      {
        tab: 'gambar',
        label: 'Gambar',
        component: () => (
          <FilesUpload
            filesUpload={filesUpload}
            setFilesUpload={setFilesUpload}
            action="detail"
            section="images"
          />
        ),
      },
      {
        tab: 'file',
        label: 'File',
        component: () => (
          <FilesUpload
            filesUpload={filesUpload}
            setFilesUpload={setFilesUpload}
            action="detail"
            section="documents"
          />
        ),
      },
    ];

    // Menangani perubahan pada tabs
    const onTabsChangeHandler = (e, newPage) => {
      e.preventDefault();
      setTabs(newPage);
    };

    useEffect(() => {
      setTabs(tabsConfig[0].tab);
    }, []);

    const TabsNav = ({ tab, label }) => (
      <Nav.Item>
        <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
          {label}
        </Nav.Link>
      </Nav.Item>
    );

    return (
      <>
        {/* Card Analisa Barang Jadi */}
        <h6 className="font-weight-bold text-uppercase">
          Data Analisa Barang Jadi
        </h6>
        <Card className="mb-4">
          <Card.Header>
            <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
              {tabsConfig.map((val, index) => (
                <TabsNav key={index} tab={val.tab} label={val.label} />
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>
            {tabsConfig.map(
              ({ tab, component: Component }, index) =>
                tab === tabs && <Component key={index} />
            )}
          </Card.Body>
        </Card>
      </>
    );
  };

  const TableSection = () => {
    const [dataHardwood, setDataHardwood] = useState([]);
    const [dataPlywood, setDataPlywood] = useState([]);
    const [dataTipe, setDataTipe] = useState([]);
    const [dataTPFS, setDataTPFS] = useState([]);
    const [dataTPLC, setDataTPLC] = useState([]);
    const [dataTPMP, setDataTPMP] = useState([]);
    const [dataTPBOP, setDataTPBOP] = useState([]);
    const [dataSelectTPFS, setDataSelectTPFS] = useState([]);
    const [dataSelectTPLC, setDataSelectTPLC] = useState([]);
    const [dataSelectTPMP, setDataSelectTPMP] = useState([]);
    const [dataSelectTPBOP, setDataSelectTPBOP] = useState([]);
    const [dataFNBP, setDataFNBP] = useState([]);
    const [dataFNSC, setDataFNSC] = useState([]);
    const [dataFNLC, setDataFNLC] = useState([]);
    const [dataSelectFNBP, setDataSelectFNBP] = useState([]);
    const [dataSelectFNSC, setDataSelectFNSC] = useState([]);
    const [dataSelectFNLC, setDataSelectFNLC] = useState([]);
    const [kelompokTipe, setKelompokTipe] = useState({});
    const [tipeBarang, setTipeBarang] = useState({});
    const [finishingBarang, setFinishingBarang] = useState({});
    const [dataMPOS, setDataMPOS] = useState([]);
    const [dataLCOS, setDataLCOS] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState({
      tipe: false,
      finishing: false,
    });
    const [tabs, setTabs] = useState('bahan_baku'); //State untuk menampung tabs yang aktif

    // fungsi mengambil id tipe finishing yang unique
    const getUniqueTipeFinishing = () => {
      const listBahanBaku = [...dataHardwood, ...dataPlywood];
      let uniqueTipeFinishing = []; // menyimpan obj unique id tipe finishing

      // cari unique id tipe finishing
      listBahanBaku.map((item) => {
        if (
          item.id_finishing_barang_jadi !== '0' &&
          Boolean(!uniqueTipeFinishing.includes(item.id_finishing_barang_jadi))
        ) {
          uniqueTipeFinishing.push(item.id_finishing_barang_jadi);
        }
      });

      uniqueTipeFinishing = uniqueTipeFinishing.map((item) => {
        return dropdownTipeFinishing.find(
          (val) => val.id_finishing_barang_jadi === item
        );
      });

      return uniqueTipeFinishing;
    };

    // fungsi mengambil total luas berdasarkan id tipe finishing yang unique (digunaka sbg konst pada tab 3)
    const getTotalLuasTipeFinishing = () => {
      const uniqueTipeFinishing = getUniqueTipeFinishing();
      const listBahanBaku = [...dataHardwood, ...dataPlywood];
      let totalLuasTipeFinishing = []; // menyimpan total luas tiap tipe finishing

      // hitung total luas tiap tipe finishing
      uniqueTipeFinishing.map((val) => {
        const totalLuas = listBahanBaku
          .filter(
            (item) =>
              item?.id_finishing_barang_jadi === val?.id_finishing_barang_jadi
          )
          .reduce(
            (acc, { total_luas }) => acc + parseFloat(total_luas ?? 0),
            0
          );
        totalLuasTipeFinishing.push({
          ...val,
          total_luas_tipe_finishing: parseFloat(totalLuas).toFixed(6),
        });
      });
      return totalLuasTipeFinishing;
    };

    const getDetailDataTipe = (id_tipe) => {
      setLoading({
        ...loading,
        tipe: true,
      });

      AnalisaBarangJadiApi.getSingleTipeBarangJadi({
        id_tipe_barang_jadi: id_tipe,
      })
        .then((val) => {
          const filterData = (data) => {
            const newData = [];
            data &&
              data.length > 0 &&
              data.map((val, index) => {
                newData.push({
                  id_barang_jadi: id,
                  id_item_buaso: val.id_item_buaso,
                  qty: val.qty,
                  unit_price: val.harga_satuan ? parseInt(val.harga_satuan) : 0,
                  konstanta: val.koefisien,
                  kode_item_bahan: val.kode_item_bahan,
                  nama_item: val.nama_item_bahan,
                  nama_satuan: val.nama_satuan,
                  urutan_item: index + 1,
                });
              });
            return newData;
          };

          const data = val.data.data.detail;
          const newDataTPFS = filterData(data.fs);
          const newDataTPLC = filterData(data.lc);
          const newDataTPMP = filterData(data.mp);
          const newDataTPBOP = filterData(data.bop);

          setDataTPFS(newDataTPFS);
          setDataTPLC(newDataTPLC);
          setDataTPMP(newDataTPMP);
          setDataTPBOP(newDataTPBOP);
        })
        .catch(() => {
          window.alert('Gagal memuat data sub tipe produksi');
          setDataTPFS([]);
          setDataTPLC([]);
          setDataTPMP([]);
          setDataTPBOP([]);
        })
        .finally(() =>
          setLoading({
            ...loading,
            tipe: false,
          })
        );
    };

    const getDetailDataFinishing = (id_tipe) => {
      setLoading({
        ...loading,
        finishing: true,
      });

      AnalisaBarangJadiApi.getSingleFinishingBarangJadi({
        id_finishing_barang_jadi: id_tipe,
      })
        .then((val) => {
          const filterData = (data) => {
            const newData = [];
            data &&
              data.length > 0 &&
              data.map((val, index) => {
                newData.push({
                  id_barang_jadi: id,
                  id_item_bahan: val.id_item_bahan,
                  qty: val.qty,
                  unit_price: val.harga_satuan ? parseInt(val.harga_satuan) : 0,
                  konstanta: val.koefisien,
                  kode_item_bahan: val.kode_item_bahan,
                  nama_item: val.nama_item,
                  nama_satuan: val.nama_satuan,
                  urutan_item: index + 1,
                });
              });
            return newData;
          };

          const data = val.data.data.detail;
          const newDataFNBP = filterData(data.bp);
          const newDataFNLC = filterData(data.lc);
          const newDataFNSC = filterData(data.sc);

          setDataFNBP(newDataFNBP);
          setDataFNLC(newDataFNLC);
          setDataFNSC(newDataFNSC);
        })
        .catch(() => {
          window.alert('Gagal memuat data tipe finishing');
          setDataFNBP([]);
          setDataFNLC([]);
          setDataFNSC([]);
        })
        .finally(() =>
          setLoading({
            ...loading,
            finishing: false,
          })
        );
    };

    const filterDataHardwood = () => {
      let finalData = [];
      const data = dataBahanBaku.analisa_hardwood;
      const sorting = data.sort((a, b) => {
        if (a.urutan_item < b.urutan_item) return -1;
        if (a.urutan_item > b.urutan_item) return 1;

        return 0;
      });

      sorting.map((val) => {
        finalData.push({
          id_barang_jadi: id,
          urutan_item: val.urutan_item,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          // nama_kayu: val.nama_kayu,
          // id_kayu: val.id_kayu,
          nama_part_kayu: val.nama_part_kayu,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          qty_final: val.qty_final,
          t_final: val.t_final,
          w_final: val.w_final,
          l_final: val.l_final,
          unit_price: val.harga_satuan,
          konstanta: val.koefisien,
          id_jenis_kayu: val.id_jenis_kayu,
          nama_jenis_kayu: val.nama_jenis_kayu,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
          nama_finishing_barang_jadi: val.nama_finishing_barang_jadi,
          id_tipe_sisi: val.id_tipe_sisi,
          nama_tipe_sisi: val.nama_tipe_sisi,
        });
      });

      setDataHardwood(finalData);
    };

    const filterDataPlywood = () => {
      let finalData = [];
      const data = dataBahanBaku.analisa_plywood;
      const sorting = data.sort((a, b) => {
        if (a.urutan_item < b.urutan_item) return -1;
        if (a.urutan_item > b.urutan_item) return 1;

        return 0;
      });

      sorting.map((val) => {
        finalData.push({
          id_barang_jadi: id,
          urutan_item: val.urutan_item,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          qty_raw: val.qty_raw,
          qty_final: val.qty_final,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          t_final: val.t_final,
          w_final: val.w_final,
          l_final: val.l_final,
          unit_price: val.harga_satuan,
          konstanta: val.koefisien,
          kode_item_bahan: val.kode_item_bahan,
          nama_satuan: val.nama_satuan,
          id_item_buaso: val.id_plywood,
          nama_item: val.nama_plywood,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
          nama_finishing_barang_jadi: val.nama_finishing_barang_jadi,
          id_tipe_sisi: val.id_tipe_sisi,
          nama_tipe_sisi: val.nama_tipe_sisi,
        });
      });

      setDataPlywood(finalData);
    };

    const filterDataTipeBarangJadi = () => {
      const filterData = (data) => {
        const newData = [];
        data &&
          data.length > 0 &&
          data.map((val, index) => {
            newData.push({
              id_barang_jadi: val.id_barang_jadi,
              id_item_buaso: val.id_item_bahan,
              qty: val.qty,
              unit_price: val.harga_satuan,
              konstanta: val.koefisien,
              kode_item_bahan: val.kode_item_bahan,
              nama_item: val.nama_item,
              nama_satuan: val.nama_satuan,
              urutan_item: index + 1,
            });
          });
        return newData;
      };

      const newDataTPFS = filterData(dataBahanPenunjang.analisa_fin_tp_fs);
      const newDataTPLC = filterData(dataBahanPenunjang.analisa_fin_tp_lc);
      const newDataTPMP = filterData(dataBahanPenunjang.analisa_fin_tp_mp);
      const newDataTPBOP = filterData(dataBahanPenunjang.analisa_fin_tp_bop);
      const newDataLCOS = filterData(dataOnSite.analisa_fin_os_lc);
      const newDataMPOS = filterData(dataOnSite.analisa_fin_os_mp);

      setDataTPFS(newDataTPFS);
      setDataTPLC(newDataTPLC);
      setDataTPMP(newDataTPMP);
      setDataTPBOP(newDataTPBOP);
      setDataLCOS(newDataLCOS);
      setDataMPOS(newDataMPOS);
    };

    const filterDataFinishingBarangJadi = () => {
      const filterData = (data) => {
        const newData = [];
        data &&
          data.length > 0 &&
          data.map((val, index) => {
            newData.push({
              id_analisa_barang_jadi_tipe_fs:
                val.id_analisa_barang_jadi_tipe_fs,
              id_barang_jadi: val.id_barang_jadi,
              id_item_bahan: val.id_item_bahan,
              id_finishing_barang_jadi: val.id_finishing_barang_jadi,
              qty: val.qty,
              unit_price: val.harga_satuan,
              konstanta: val.koefisien,
              kode_item_bahan: val.kode_item_bahan,
              nama_item: val.nama_item,
              nama_satuan: val.nama_satuan,
              nama_finishing_barang_jadi: val.nama_finishing_barang_jadi,
              urutan_item: index + 1,
            });
          });
        return newData;
      };

      const newDataFNBP = filterData(dataFinishing.analisa_fin_fn_bp);
      const newDataFNSC = filterData(dataFinishing.analisa_fin_fn_sc);
      const newDataFNLC = filterData(dataFinishing.analisa_fin_fn_lc);

      setDataFNBP(newDataFNBP);
      setDataFNSC(newDataFNSC);
      setDataFNLC(newDataFNLC);
    };

    const getGrandTotal = () => {
      const uniqueTipe = getUniqueTipeFinishing().map(
        (val) => val?.id_finishing_barang_jadi
      );
      let totalHardwood = 0;
      let totalPlywood = 0;
      let totalTPFS = 0;
      let totalTPLC = 0;
      let totalTPMP = 0;
      let totalTPBOP = 0;
      let totalFNBP = 0;
      let totalFNSC = 0;
      let totalFNLC = 0;

      const totalHW = dataHardwood.reduce((prev, val) => {
        const countTotalUnit = parseFloat(
          (parseFloat(val.qty_raw).toFixed(6) *
            parseFloat(val.t_raw).toFixed(6) *
            parseFloat(val.w_raw).toFixed(6) *
            parseFloat(val.l_raw).toFixed(6)) /
            1000000
        ).toFixed(6);

        const totalUnit =
          parseFloat(countTotalUnit) < 0.000001
            ? '0.000001'
            : parseFloat(countTotalUnit);
        const totalPrice = Math.round(
          parseFloat(totalUnit) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta)
        );
        const checkPrice = val.is_header ? 0 : totalPrice;
        totalHardwood = parseInt(checkPrice);

        return prev + totalHardwood;
      }, 0);

      dataPlywood.map((val) => {
        const total = Math.round(
          parseFloat(val.qty_raw).toFixed(6) *
            parseInt(val.unit_price ?? 0) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalPlywood = parseInt(totalPlywood) + parseInt(total || 0);
      });

      dataTPFS.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalTPFS = parseInt(totalTPFS) + parseInt(total);
      });

      dataTPLC.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalTPLC = parseInt(totalTPLC) + parseInt(total);
      });

      dataTPMP.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalTPMP = parseInt(totalTPMP) + parseInt(total);
      });

      dataTPBOP.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalTPBOP = parseInt(totalTPBOP) + parseInt(total);
      });

      dataFNBP.map((val) => {
        if (
          val.id_finishing_barang_jadi !== '0' &&
          uniqueTipe.includes(val.id_finishing_barang_jadi)
        ) {
          const total = Math.round(
            parseFloat(val.qty).toFixed(6) *
              parseInt(val.unit_price) *
              parseFloat(val.konstanta).toFixed(6)
          );
          totalFNBP = parseInt(totalFNBP) + parseInt(total);
        }
      });

      dataFNSC.map((val) => {
        if (
          val.id_finishing_barang_jadi !== '0' &&
          uniqueTipe.includes(val.id_finishing_barang_jadi)
        ) {
          const total = Math.round(
            parseFloat(val.qty).toFixed(6) *
              parseInt(val.unit_price) *
              parseFloat(val.konstanta).toFixed(6)
          );
          totalFNSC = parseInt(totalFNSC) + parseInt(total);
        }
      });

      dataFNLC.map((val) => {
        if (
          val.id_finishing_barang_jadi !== '0' &&
          uniqueTipe.includes(val.id_finishing_barang_jadi)
        ) {
          const total = Math.round(
            parseFloat(val.qty).toFixed(6) *
              parseInt(val.unit_price) *
              parseFloat(val.konstanta).toFixed(6)
          );
          totalFNLC = parseInt(totalFNLC) + parseInt(total);
        }
      });

      const totalFinishing =
        parseInt(totalFNBP) + parseInt(totalFNSC) + parseInt(totalFNLC);

      const grandTotal =
        parseInt(totalHW) +
        parseInt(totalPlywood) +
        parseInt(totalTPFS) +
        parseInt(totalTPLC) +
        parseInt(totalTPMP) +
        parseInt(totalTPBOP) +
        parseInt(totalFinishing);

      const summaryBiaya = [
        { kode: 'hw', nama_biaya: 'Hardwood', subtotal: parseInt(totalHW) },
        { kode: 'pw', nama_biaya: 'Plywood', subtotal: parseInt(totalPlywood) },
        {
          kode: 'fs',
          nama_biaya: 'Factory Supply',
          subtotal: parseInt(totalTPFS),
        },
        {
          kode: 'lc',
          nama_biaya: 'Labour Cost',
          subtotal: parseInt(totalTPLC),
        },
        {
          kode: 'mp',
          nama_biaya: 'Machine Process',
          subtotal: parseInt(totalTPMP),
        },
        {
          kode: 'bop',
          nama_biaya: 'Overhead Pabrik',
          subtotal: parseInt(totalTPBOP),
        },
        {
          kode: 'fin',
          nama_biaya: 'Finishing',
          subtotal: parseInt(totalFinishing),
        },
      ];

      return summaryBiaya;
      // return RupiahConvert(grandTotal.toString()).detail
    };

    useEffect(() => {
      filterDataHardwood();
      filterDataPlywood();
      filterDataTipeBarangJadi();
      filterDataFinishingBarangJadi();
    }, []);

    useEffect(() => {
      getTotalLuasTipeFinishing();
    }, [dataPlywood, dataHardwood]);

    const SelectKelompokTipeBarangJadi = ({
      kelompokTipe,
      setKelompokTipe,
      dataTipe,
      setDataTipe,
      tipeBarang,
      setTipeBarang,
    }) => {
      return (
        <div className="d-flex justify-content-end mt-3">
          <div className="row col-md-7">
            <div className="col-md-6">
              <SelectSearch
                label="Tipe Produksi"
                placeholder="Pilih tipe produksi"
                className="m-1"
                defaultValue={kelompokTipe.value ? kelompokTipe : ''}
                option={dataKelompokTipeBarangJadi.map((val) => {
                  return {
                    value: val.id_kelompok_tipe_barang_jadi,
                    label: val.nama_kelompok_barang_jadi,
                  };
                })}
                onChange={(val) => {
                  const id = val.value;
                  setKelompokTipe(val);
                  const filter = dataTipeBarangJadi.filter(
                    (val) => val.id_kelompok_tipe_barang_jadi === id
                  );
                  setDataTipe(filter);
                  setTipeBarang([]);
                }}
              />
            </div>
            <div className="col-md-6">
              <SelectSearch
                label="Sub Tipe Produksi"
                placeholder="Pilih sub tipe produksi"
                className="m-1"
                defaultValue={tipeBarang.value ? tipeBarang : ''}
                option={dataTipe.map((val) => {
                  return {
                    value: val.id_tipe_barang_jadi,
                    label: val.nama_tipe_barang_jadi,
                  };
                })}
                onChange={(val) => {
                  setTipeBarang(val);
                  getDetailDataTipe(val.value);
                }}
                isDisabled={kelompokTipe.value ? false : true}
              />
            </div>
          </div>
        </div>
      );
    };

    const SelectFinishingBarangJadi = ({
      finishingBarang,
      setFinishingBarang,
      dataFinishingBarangJadi,
    }) => {
      return (
        <div className="d-flex justify-content-end mt-3">
          <div className="col-md-5">
            <SelectSearch
              label="Tipe Finishing"
              placeholder="Pilih tipe finishing"
              className="m-1"
              defaultValue={finishingBarang.value ? finishingBarang : ''}
              option={dataFinishingBarangJadi.map((val) => {
                return {
                  value: val.id_finishing_barang_jadi,
                  label: val.nama_finishing_barang_jadi,
                };
              })}
              onChange={(val) => {
                const id = val.value;
                setFinishingBarang(val);
                getDetailDataFinishing(id);
              }}
            />
          </div>
        </div>
      );
    };

    // Konfigurasi komponen tabs
    const tabsConfig = [
      {
        tab: 'bahan_baku',
        label: 'Analisa Bahan Baku',
        component: () => (
          <>
            <TableListHardwood
              dataKayu={dataKayu.filter(
                (kayu) => kayu.tipe_kayu.toUpperCase() === 'HARDWOOD'
              )}
              dataHardwood={dataHardwood}
              setDataHardwood={setDataHardwood}
              dropdownTipeSisi={dropdownTipeSisi}
              dropdownTipeFinishing={dropdownTipeFinishing}
              dropdownSpekKayu={dropdownSpekKayu}
            />
            <TableListPlywood
              dataKayu={dataKayu.filter(
                (kayu) => kayu.tipe_kayu.toUpperCase() === 'PLYWOOD'
              )}
              dataPlywood={dataPlywood}
              setDataPlywood={setDataPlywood}
              dropdownTipeSisi={dropdownTipeSisi}
              dropdownTipeFinishing={dropdownTipeFinishing}
              dropdownSpekPlywood={dropdownSpekPlywood}
            />
          </>
        ),
      },
      {
        tab: 'penunjang',
        label: 'Analisa Penunjang Produksi',
        component: () => (
          <>
            {/* <SelectKelompokTipeBarangJadi
              dataTipe={dataTipe}
              setDataTipe={setDataTipe}
              kelompokTipe={kelompokTipe}
              setKelompokTipe={setKelompokTipe}
              tipeBarang={tipeBarang}
              setTipeBarang={setTipeBarang}
            /> */}
            {loading.tipe ? (
              <div style={{ height: '800px' }}>
                <DataStatus
                  loading={true}
                  text="Memuat data sub tipe produksi"
                />
              </div>
            ) : (
              <>
                <TableListTPFS
                  dataSelectTPFS={dataSelectTPFS}
                  dataTPFS={dataTPFS}
                  setDataTPFS={setDataTPFS}
                  dataHardwood={dataHardwood}
                />
                <TableListTPLC
                  dataSelectTPLC={dataSelectTPLC}
                  dataTPLC={dataTPLC}
                  setDataTPLC={setDataTPLC}
                  dataHardwood={dataHardwood}
                />
                <TableListTPMP
                  dataSelectTPMP={dataSelectTPMP}
                  dataTPMP={dataTPMP}
                  setDataTPMP={setDataTPMP}
                  dataHardwood={dataHardwood}
                />
                <TableListTPBOP
                  dataSelectTPBOP={dataSelectTPBOP}
                  dataTPBOP={dataTPBOP}
                  setDataTPBOP={setDataTPBOP}
                  dataHardwood={dataHardwood}
                />
              </>
            )}
          </>
        ),
      },
      {
        tab: 'finishing',
        label: 'Analisa Finshing',
        component: () => {
          let totalLuasTipeFinishing = getTotalLuasTipeFinishing();
          return (
            <>
              {totalLuasTipeFinishing.map((val) => {
                return (
                  <div className="mt-2">
                    <h5 className="font-weight-bold pl-2">
                      {val.nama_finishing_barang_jadi}
                    </h5>
                    <TableListFNBP
                      id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                      dataSelectFNBP={dataSelectFNBP}
                      dataFNBP={dataFNBP}
                      setDataFNBP={setDataFNBP}
                      defaultKonst={val.total_luas_tipe_finishing}
                    />
                    <TableListFNSC
                      id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                      dataSelectFNSC={dataSelectFNSC}
                      dataFNSC={dataFNSC}
                      setDataFNSC={setDataFNSC}
                      defaultKonst={val.total_luas_tipe_finishing}
                    />
                    <TableListFNLC
                      id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                      dataSelectFNLC={dataSelectFNLC}
                      dataFNLC={dataFNLC}
                      setDataFNLC={setDataFNLC}
                      defaultKonst={val.total_luas_tipe_finishing}
                    />
                    <hr />
                  </div>
                );
              })}
            </>
          );
        },
      },
    ];

    // Menangani perubahan pada tabs
    const onTabsChangeHandler = (e, newPage) => {
      e.preventDefault();
      setTabs(newPage);
    };

    useEffect(() => {
      setTabs(tabsConfig[0].tab);
    }, []);

    const TabsNav = ({ tab, label }) => (
      <Nav.Item>
        <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
          {label}
        </Nav.Link>
      </Nav.Item>
    );

    return (
      <>
        <Card>
          <Card.Header>
            <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
              {tabsConfig.map((val, index) => (
                <TabsNav key={index} tab={val.tab} label={val.label} />
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>
            {tabsConfig.map(
              ({ tab, component: Component }, index) =>
                tab === tabs && <Component key={index} />
            )}
          </Card.Body>
        </Card>

        {/* Card Summary Biaya */}
        <h6 className="font-weight-bold text-uppercase">Summary Biaya</h6>
        <Card>
          <Card.Body>
            <TableListSummary getGrandTotal={getGrandTotal} />
          </Card.Body>
        </Card>
      </>
    );
  };

  return (
    <>
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : isFetchingFailed ? (
        <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
      ) : (
        <>
          <InfoSection />
        </>
      )}
      {!isPageLoading && !isFetchingFailed && (
        <TableSection
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
        />
      )}
      {/* {!isPageLoading && !isFetchingFailed && (
        <div style={{ display: "none" }}>
          <PrintAnalisaBarangJadi
            ref={componentRef}
            dataAnalisaBarangJadi={dataAnalisaBarangJadi}
          />
        </div>
      )} */}
    </>
  );
};

export default FormAnalisaBarangJadi;

import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  UpdateButton,
  DataStatus,
  ReadButton,
  Alert,
  Pagination,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  DeleteModal,
  FilterButton,
  ApprovalStatusButton,
} from 'components';
import { RegistrasiPeluangApi } from 'api';
import { PageNumber, DateConvert } from 'utilities';
import { ModalFilter } from './Section';

const RegistrasiPeluang = ({ setNavbarTitle }) => {
  const history = useHistory();
  const title = 'Peluang';
  const location = useLocation();
  const state = location?.state;
  let registerAlertConfig = location?.state?.registerAlertConfig;
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [isDeleteData, setIsDeleteData] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [dataCount, setDataCount] = useState(0);
  const [deleteData, setDeleteData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    active: state?.dataFilter?.active,
    tgl_peluang_mulai: state?.dataFilter?.tgl_peluang_mulai,
    tgl_peluang_selesai: state?.dataFilter?.tgl_peluang_selesai,
    peringkat_peluang: state?.dataFilter?.peringkat_peluang,
    customer: state?.dataFilter?.customer,
    proyek: state?.dataFilter?.proyek,
    kelompok_proyek: state?.dataFilter?.kelompok_proyek,
    target_penawaran_mulai: state?.dataFilter?.target_penawaran_mulai,
    target_penawaran_selesai: state?.dataFilter?.target_penawaran_selesai,
    realisasi_penawaran_mulai: state?.dataFilter?.realisasi_penawaran_mulai,
    realisasi_penawaran_selesai: state?.dataFilter?.realisasi_penawaran_selesai,
    instalasi: state?.dataFilter?.instalasi,
  });

  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    RegistrasiPeluangApi.getPage({
      page: page,
      per_page: dataLength,
      q: searchKey ? searchKey : undefined,
      tgl_peluang_mulai: dataFilter?.tgl_peluang_mulai,
      tgl_peluang_selesai: dataFilter?.tgl_peluang_selesai,
      peringkat_peluang: dataFilter?.peringkat_peluang,
      customer: dataFilter?.customer,
      proyek: dataFilter?.proyek,
      kelompok_proyek: dataFilter?.kelompok_proyek,
      target_penawaran_mulai: dataFilter?.target_penawaran_mulai,
      target_penawaran_selesai: dataFilter?.target_penawaran_selesai,
      realisasi_penawaran_mulai: dataFilter?.realisasi_penawaran_mulai,
      realisasi_penawaran_selesai: dataFilter?.realisasi_penawaran_selesai,
      instalasi: dataFilter?.instalasi,
    })
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch((err) => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey != '') {
          setAlertConfig({
            variant: 'primary',
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        }
        setIsLoading(false);
      });
  };

  const getStatusApprovalLabel = (status_approval) => {
    switch (status_approval) {
      case 'APP':
        return {
          variant: 'outline-success',
          label: 'APPROVED',
        };
      case 'REJ':
        return {
          variant: 'outline-danger',
          label: 'REJECT',
        };
      case 'REV':
        return {
          variant: 'outline-warning',
          label: 'REVISI',
        };
      case 'VER':
        return {
          variant: 'outline-success',
          label: 'VERIFIED',
        };
      default:
        return {
          variant: 'outline-secondary',
          label: 'PENDING',
        };
    }
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(title);

    // jalankan function request data ke server
    getData();

    // SHOW ALERT, IF REGISTER ALERT IS EXIST
    if (registerAlertConfig !== undefined) {
      setAlertConfig(registerAlertConfig);
      setShowAlert(true);
    }

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [
    setNavbarTitle,
    page,
    dataLength,
    searchKey,
    dataFilter?.active,
    dataFilter?.tgl_peluang_mulai,
    dataFilter?.tgl_peluang_selesai,
    dataFilter?.peringkat_peluang,
    dataFilter?.customer,
    dataFilter?.proyek,
    dataFilter?.kelompok_proyek,
    dataFilter?.target_penawaran_mulai,
    dataFilter?.target_penawaran_selesai,
    dataFilter?.realisasi_penawaran_mulai,
    dataFilter?.realisasi_penawaran_selesai,
    dataFilter?.instalasi,
  ]);

  // Modal Hapus
  const HapusModal = () => {
    // set id dari data yang ingin dihapus
    const values = { id_peluang: deleteData.id_peluang };

    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false);

    // menangani pembersihan saat fetching data
    useEffect(() => {
      return () => {
        setBtnLoading(false);
      };
    }, []);

    // request hapus data ke server
    const deleteDataHandler = () => {
      // set delete button loading
      setBtnLoading(true);

      RegistrasiPeluangApi.delete(values)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: 'primary',
            text: 'Hapus data berhasil!',
          });
        })
        .catch((err) => {
          // konfigurasi alert
          setAlertConfig({
            variant: 'danger',
            text: `Hapus data gagal! ${err.response.data.message}`,
          });
        })
        .finally(() => {
          // menutup modal
          setIsDeleteData(false);
          // menampilkan alert
          setShowAlert(true);
          // request data baru ke server
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>No Peluang : {deleteData.no_peluang}</div>
      </DeleteModal>
    );
  };

  // Tabel
  const Table = () => (
    <>
      <b>List Data Peluang</b>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi Peluang</ThFixed>
            <ThFixed>Peringkat Peluang</ThFixed>
            <Th style={{ minWidth: '300px' }}>Customer</Th>
            <Th style={{ minWidth: '300px' }}>Proyek</Th>
            <Th style={{ minWidth: '300px' }}>ATT</Th>
            <ThFixed>Tgl. Selesai</ThFixed>
            <ThFixed>Status Approval</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
              <TdFixed>
                <div className="d-flex justify-content-start">
                  {val.status_approval === 'REV' ? (
                    <>
                      <ReadButton
                        onClick={() => {
                          history.push(
                            `/transaksi/registrasi-peluang/detail/${val.id_peluang}`,
                            { dataFilter }
                          );
                        }}
                      />
                      <UpdateButton
                        onClick={() => {
                          history.push(
                            `/transaksi/registrasi-peluang/ubah/${val.id_peluang}`,
                            { dataFilter }
                          );
                        }}
                      />
                    </>
                  ) : (
                    <ReadButton
                      onClick={() => {
                        history.push(
                          `/transaksi/registrasi-peluang/detail/${val.id_peluang}`,
                          { dataFilter }
                        );
                      }}
                    />
                  )}
                </div>
              </TdFixed>
              <Td>
                <div>{DateConvert(new Date(val.tgl_peluang)).custom}</div>
                <div>{val.no_peluang}</div>
              </Td>
              <TdFixed>{val.nama_peringkat_peluang}</TdFixed>
              <Td>{val.nama_customer ?? '-'}</Td>
              <Td>{val.nama_proyek ?? '-'}</Td>
              <Td>{val.att}</Td>
              <Td className="text-center">
                {DateConvert(new Date(val.tgl_selesai)).custom}
              </Td>
              <TdFixed>
                <ApprovalStatusButton
                  variant={getStatusApprovalLabel(val.status_approval).variant}
                >
                  {getStatusApprovalLabel(val.status_approval).label}
                </ApprovalStatusButton>
              </TdFixed>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setPage(1);
            setDataLength(e.target.value);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      )}
    </>
  );

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex align-items-center mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setPage(1);
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />
            <FilterButton
              active={dataFilter.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          {/* <ExportButton /> */}
          <CreateButton
            onClick={() => {
              history.push(`/transaksi/registrasi-peluang/tambah`, {
                dataFilter,
              });
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => {
          setShowAlert(false);
          // CLEAR HISTORY LOCATION
          history.replace('/transaksi/registrasi-peluang', {
            registerAlertConfig: undefined,
          });
        }}
      />

      {/* Table Section */}
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data ? (
        data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Server error" />
      )}

      {/* Modal */}
      <HapusModal />
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default RegistrasiPeluang;

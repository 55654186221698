import { Services, UploadServices } from '../../../services';

class RegistrasiPeluangApi {
  getPage(params) {
    return Services.get(`/peluang/page`, { params });
  }

  create(data) {
    return Services.post('/peluang', data);
  }

  createDetail(data) {
    return Services.post('/peluang/detail', data);
  }

  search(key) {
    return Services.get(`/peluang/page?q=${key}`);
  }

  getSingle(data) {
    return Services.get(`/peluang/single?id_peluang=${data}`);
  }

  update(data) {
    return Services.put('/peluang', data);
  }

  hide(data) {
    return Services.put('/peluang/hide', data);
  }

  show(data) {
    return Services.put('/peluang/show', data);
  }

  delete(data) {
    return Services.post('/peluang/delete', data);
  }

  deleteDetail(data) {
    return Services.post('/peluang/detail_delete', data);
  }

  getCustomer() {
    return Services.get('/peluang/customer');
  }

  getProyek(params) {
    return Services.get(`dropdown/proyek`, { params });
  }

  getPeringkatPeluang() {
    return Services.get('/peluang/peringkat_peluang');
  }

  getNoBaru(data) {
    return Services.get(`/peluang/no_baru?tanggal=${data}`);
  }

  getBarangJadi() {
    return Services.get('/peluang/barang_jadi');
  }

  upload(data) {
    return UploadServices.post('/upload/peluang_multiple', data);
  }
}

export default new RegistrasiPeluangApi();

import { useState } from 'react';

const BarangJadiCollapse = ({ data }) => {
  const [isReadMoreClick, setisReadMoreClick] = useState(false);
  return (
    <>
      <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
        {data.map((brg, index) => {
          const key = `${brg.id_rab_detail}${brg.id_barang_jadi}${brg.id_rae_detail}`;

          if (!isReadMoreClick) {
            return (
              index <= 2 && (
                <li key={key} index={index}>
                  {brg.nama_item}
                </li>
              )
            );
          }

          return (
            <li key={key} index={index}>
              {brg.nama_item}
            </li>
          );
        })}
      </ul>

      {data?.length > 2 && (
        <div
          className="text-primary"
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => {
            setisReadMoreClick((prev) => !prev);
          }}
        >
          {isReadMoreClick ? 'Sembunyikan' : 'Selengkapnya...'}
        </div>
      )}
    </>
  );
};
export default BarangJadiCollapse;

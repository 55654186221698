import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { IoDocumentTextOutline } from 'react-icons/io5';
import FileSaver from 'file-saver';
import {
  CRUDLayout,
  Tr,
  Th,
  Td,
  Alert,
  DataStatus,
  TdFixed,
  ThFixed,
  THead,
  TBody,
  Pagination,
  DatePicker,
  SelectSearch,
  ActionButton,
} from 'components';
import { TableNumber, DateConvert } from 'utilities';
import { LaporanSPKApi } from 'api';

const LaporanSPK = ({ setNavbarTitle }) => {
  const TODAY = new Date().toISOString().slice(0, 10);
  const [data, setData] = useState([]);
  const [dataSegmentasiPasar, setDataSegmentasiPasar] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [form, setForm] = useState({
    tanggal_start: TODAY,
    tanggal_end: TODAY,
    nama_segmentasi_pasar: null,
  });
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
    show: false,
  });

  const mapDataFromServer = (data) => {
    const uniqNumber = [];
    const mappedData = data.map((item) => {
      if (!uniqNumber.includes(item.no_spk)) {
        uniqNumber.push(item.no_spk);
        return item;
      }
      return Object({
        nama_item: item.nama_item,
        qty_spk: item.qty_spk,
        nama_satuan: item.nama_satuan,
      });
    });
    return mappedData;
  };

  const handleFetchReport = () => {
    setLoading(true);

    const query = {
      ...form,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    };

    LaporanSPKApi.getPage(query)
      .then(({ data }) => {
        setData(mapDataFromServer(data.data ?? []));
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch(() =>
        setAlertConfig({
          variant: 'danger',
          text: 'Tidak dapat memuat data!',
          show: true,
        })
      )
      .finally(() => setLoading(false));
  };

  const handleButtonExport = (tipe) => {
    const query = { ...form, tipe };

    LaporanSPKApi.getSPKExport(query).then(({ data }) => {
      const ext = tipe === 'excel' ? 'xlsx' : 'pdf';
      FileSaver.saveAs(data.data, `Laporan_SPK.${ext}`);
    });
  };

  const getInitialData = () => {
    LaporanSPKApi.getSegmentasiPasar()
      .then(({ data }) => {
        const dataSegmentasi = data?.data?.map((item) => ({
          value: item.nama_segmentasi_pasar,
          label: item.nama_segmentasi_pasar,
        }));
        setDataSegmentasiPasar(dataSegmentasi ?? []);
      })
      .catch(() =>
        setAlertConfig({
          variant: 'danger',
          text: 'Tidak dapat memuat data!',
          show: true,
        })
      );
  };

  useEffect(() => getInitialData(), []);

  useEffect(() => {
    setNavbarTitle('Laporan SPK');
    handleFetchReport();
  }, [setNavbarTitle, paginationConfig.page, paginationConfig.dataLength]);

  // Reset page on form filter change
  useEffect(
    () =>
      setPaginationConfig({
        page: '1',
        dataLength: '10',
        totalPage: '1',
        dataCount: '0',
      }),
    [form]
  );

  const ExportButton = ({
    variant = 'outline-success',
    text = 'Export EXCEL',
    onClick,
  }) => {
    return (
      <Button variant={variant} onClick={onClick} className="mx-1 mb-1">
        <IoDocumentTextOutline size={18} />
        <span className="ml-2 text-uppercase">{text}</span>
      </Button>
    );
  };

  const TableSection = () => {
    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed rowSpan={2}>No</ThFixed>
              <ThFixed rowSpan={2}>No.SPK</ThFixed>
              <Th rowSpan={2}>Customer</Th>
              <ThFixed colSpan={2}>Tanggal</ThFixed>
              <Th rowSpan={2}>Description (Barang Jadi)</Th>
              <Th rowSpan={2} style={{ minWidth: 100 }}>
                Qty.
              </Th>
              <Th rowSpan={2}>Proyek</Th>
              <Th rowSpan={2}>Segment Pasar</Th>
            </Tr>
            <Tr>
              <ThFixed>Tgl. SPK</ThFixed>
              <ThFixed>Tgl. Selesai</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((item, index) => {
              return (
                <Tr key={index}>
                  <TdFixed>
                    {TableNumber(
                      paginationConfig.page,
                      paginationConfig.dataLength,
                      index
                    )}
                  </TdFixed>
                  <Td className="text-center">
                    {item.no_spk ? item.no_spk : ''}
                  </Td>
                  <Td>{item.no_spk ? item.nama_customer : ''}</Td>
                  <Td className="text-center">
                    {item.no_spk
                      ? item.tgl_spk
                        ? DateConvert(new Date(item.tgl_spk)).custom
                        : '-'
                      : ''}
                  </Td>
                  <Td className="text-center">
                    {item.no_spk
                      ? item.tgl_selesai
                        ? DateConvert(new Date(item.tgl_selesai)).custom
                        : '-'
                      : ''}
                  </Td>
                  <Td>{item.nama_item ?? '-'}</Td>
                  <Td>{`${parseFloat(item.qty_spk) || '-'} ${
                    item.nama_satuan ?? ''
                  } `}</Td>
                  <Td>{item.no_spk ? item.nama_proyek : ''}</Td>
                  <Td>{item.no_spk ? item.nama_segmentasi_pasar : ''}</Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>

        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <Row className="d-flex flex-wrap px-1 py-2 mx-1 mt-1 mb-3 bg-white rounded-lg">
        {/* Periode */}
        <Col
          md={4}
          className="d-flex flex-row justify-content-start align-items-center"
        >
          <span className="mr-2 mt-1 text-">Periode:</span>
          <DatePicker
            label="Tgl. Mulai"
            name="tanggal_start"
            selected={form.tanggal_start ? new Date(form.tanggal_start) : ''}
            onChange={(val) => {
              const value = val.toISOString().slice(0, 10);
              setForm((prev) => ({ ...prev, tanggal_start: value }));
            }}
          />
          <span className="mx-2 mt-1">s.d.</span>
          <DatePicker
            label="Tgl. Selesai"
            name="tanggal_end"
            selected={form.tanggal_end ? new Date(form.tanggal_end) : ''}
            onChange={(val) => {
              const value = val.toISOString().slice(0, 10);
              setForm((prev) => ({ ...prev, tanggal_end: value }));
            }}
          />
        </Col>

        {/* Filter */}
        <Col
          md={8}
          className="d-flex flex-row justify-content-start align-items-center"
        >
          <span className="mt-1">Filter By:</span>
          <SelectSearch
            wrapperClassName="w-25 mx-2"
            label="Segment Pasar"
            placeholder="Pilih segment pasar"
            option={dataSegmentasiPasar ?? []}
            onChange={(val) => {
              setForm((prev) => ({
                ...prev,
                nama_segmentasi_pasar: val.value,
              }));
            }}
          />
          <ActionButton
            className="ml-2 mt-3 p-2"
            size="sm"
            text="LIHAT DATA"
            onClick={handleFetchReport}
          />
        </Col>
      </Row>

      <Alert
        showCloseButton={true}
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig((prev) => ({ ...prev, show: false }))}
      />

      {!isLoading && data.length > 0 && (
        <Row className="d-flex justify-content-end align-items-center mx-1 mt-4 mb-1">
          <ExportButton
            variant="outline-danger"
            text="Export PDF"
            onClick={() => handleButtonExport('pdf')}
          />
          <ExportButton onClick={() => handleButtonExport('excel')} />
        </Row>
      )}

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat data ..." />
      ) : (
        <TableSection />
      )}
    </CRUDLayout>
  );
};

export default LaporanSPK;

import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Axios from 'axios';
import { useReactToPrint } from 'react-to-print';
import { Row, Col, Card, Modal } from 'react-bootstrap';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import {
  Alert,
  DataStatus,
  BackButton,
  ActionButton,
  TextEditor,
  Approval,
} from 'components';
import { RupiahConvert, DateConvert } from 'utilities';
import { PenawaranApi } from 'api';

import AnalisaBarangJadiPenawaran from '../AnalisaBarangJadiPenawaran';
import PrintPenawaran from './PrintPenawaran';
import PeluangPenawaran from '../PeluangPenawaran';
import { TablePenawaran } from '../TablePenawaran';

const DetailPenawaran = ({ setNavbarTitle }) => {
  const { id_penawaran } = useParams();
  const history = useHistory();
  const location = useLocation();

  const statusAPP = location?.state?.status_approval;

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataListPenawaran, setDataListPenawaran] = useState([]);

  const [dataPenawaran, setDataPenawaran] = useState({});
  const [dataPrelim, setDataPrelim] = useState([]);
  const [totalPenawaran, setTotalPenawaran] = useState(0);
  const [totalLC, setTotalLC] = useState(0);
  const [totalMP, setTotalMP] = useState(0);

  const [processedData, setProcessedData] = useState({});
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });

  // STATE TEXT EDITOR PEMBUKA
  const [textEditorState, setTextEditorState] = useState(() => {
    EditorState.createEmpty();
  });
  // STATE TEXT EDITOR PENUTUP
  const [textEditorStatePenutup, setTextEditorStatePenutup] = useState(() => {
    EditorState.createEmpty();
  });

  const tableStyling = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle',
  };
  const [modalPeluangConfig, setModalPeluangConfig] = useState({
    show: false,
    no_peluang: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  // INIT USE REACT TO PRINT
  const refPrint = useRef();
  const reactToPrint = useReactToPrint({
    content: () => refPrint.current,
  });
  // ON CLICK HANDLE PRINT
  const handlePrint = () => {
    reactToPrint();
  };

  const getInitialData = () => {
    setIsPageLoading(true);

    //GET SINGLE DETAIL RAB
    Axios.all([PenawaranApi.getSingle({ id_penawaran: id_penawaran })])
      .then(
        Axios.spread((data) => {
          const dataInfo = data?.data?.data ?? {};
          const filterPrelim = dataInfo?.detail_prelim?.map((val, index) => ({
            id_kelompok: val.id_kelompok,
            id_item: val.id_item_prelim,
            kode_item: val.kode_item_prelim,
            nama_item: val.nama_item_prelim,
            qty_item: val.qty_analisa,
            id_unit: val.id_satuan_prelim,
            nama_unit: val.nama_satuan_prelim,
            harga: parseFloat(val.unit_price ?? 0),
            qty_durasi: parseFloat(val.qty_durasi ?? 0),
            konstanta: parseFloat(val.konstanta ?? 0),
            order: index + 1,
          }));

          setDataPenawaran(dataInfo);
          setDataListPenawaran(dataInfo.detail_barang_jadi ?? []);
          setDataPrelim(filterPrelim ?? []);

          // SET TEXT EDIOR
          setTextEditorState(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(data?.data?.data?.teks_pembuka ?? '<p></p>')
              )
            )
          );
          setTextEditorStatePenutup(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(data?.data?.data?.teks_penutup ?? '<p></p>')
              )
            )
          );
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => {
        setIsPageLoading(false);
      });
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const InfoSection = () => {
    const InfoItem = ({ title, value, link, onClick }) => (
      <tr onClick={onClick}>
        <td>{title}</td>
        <td className={`pl-4 pr-2`}>:</td>
        <td
          className={link ? 'text-primary' : ''}
          style={{ cursor: link ? 'pointer' : 'default' }}
        >
          {value}
        </td>
      </tr>
    );

    return (
      <Row>
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Tgl. RAB"
                value={
                  dataPenawaran?.tgl_rab
                    ? DateConvert(new Date(dataPenawaran.tgl_rab)).detail
                    : '-'
                }
              />
              <InfoItem title="No. RAB" value={dataPenawaran?.no_rab ?? '-'} />
              <InfoItem
                link
                title="No. Peluang"
                value={dataPenawaran?.no_peluang ?? '-'}
                onClick={() =>
                  setModalPeluangConfig({
                    show: true,
                    no_peluang: dataPenawaran?.no_peluang ?? '',
                  })
                }
              />
              <InfoItem
                title="Tgl. Penawaran"
                value={
                  dataPenawaran?.tgl_penawaran
                    ? DateConvert(new Date(dataPenawaran.tgl_penawaran)).detail
                    : '-'
                }
              />
              <InfoItem
                title="No. Penawaran"
                value={dataPenawaran?.no_penawaran ?? '-'}
              />
            </tbody>
          </table>
        </Col>
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Customer"
                value={dataPenawaran?.nama_customer ?? '-'}
              />
              <InfoItem title="ATT" value={dataPenawaran?.att ?? '-'} />
              <InfoItem
                title="Tgl. Realisasi Penawaran"
                value={
                  dataPenawaran?.tgl_selesai
                    ? DateConvert(new Date(dataPenawaran.tgl_selesai)).detail
                    : '-'
                }
              />
              <InfoItem
                title="Proyek"
                value={dataPenawaran?.nama_proyek ?? '-'}
              />
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const GrandTotalSection = ({ totalPenawaran, totalLC, totalMP }) => {
    const hitungGrandTotal = () => {
      let grandTotal = totalPenawaran + totalLC + totalMP;
      return RupiahConvert(String(grandTotal)).detail;
    };

    useEffect(() => {
      hitungGrandTotal();
    }, [totalPenawaran, totalLC, totalMP]);

    return (
      <div className="p-2 bg-light text-right border">
        <b className="text-nowrap pr-3" style={{ fontSize: '14px' }}>
          Grand Total : {hitungGrandTotal()}
        </b>
      </div>
    );
  };

  const ModalSection = ({
    processedData,
    dataBarangJadi,
    setDataBarangJadi,
    alertConfig,
    setAlertConfig,
    modalConfig,
    setModalConfig,
  }) => {
    const AnalisaBarangJadiModal = ({
      processedData,
      dataBarangJadi,
      setDataBarangJadi,
      alertConfig,
      setAlertConfig,
      modalConfig,
      setModalConfig,
    }) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadiPenawaran
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            modalConfig={modalConfig}
            setAlertConfig={setAlertConfig}
            setModalConfig={setModalConfig}
          />
        </Modal.Body>
      );
    };

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === 'analisa' ? 'xl' : 'md'}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <AnalisaBarangJadiModal
          processedData={processedData}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      </Modal>
    );
  };

  useEffect(() => {
    setNavbarTitle('Penawaran');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Penawaran</b>
          <div className="d-flex flex-row justfiy-content-center align-items-center">
            {statusAPP === 'APP' ? (
              <ActionButton
                className="btn btn-primary text-white mb-1 mr-2"
                size="md"
                text="Cetak"
                onClick={() => handlePrint()}
              />
            ) : (
              ''
            )}

            <BackButton onClick={() => history.goBack()} />
          </div>
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              <InfoSection />
              <hr />
            </>
          )}

          {!isPageLoading && (
            <>
              <TablePenawaran
                type="DETAIL"
                ppn={parseFloat(dataPenawaran.ppn ?? 0)}
                diskon={parseFloat(dataPenawaran.diskon ?? 0).toPrecision()}
                setTotalPenawaran={setTotalPenawaran}
                totalPenawaran={totalPenawaran}
                dataBarangJadi={dataListPenawaran}
                dataPrelim={dataPrelim}
              />
              <GrandTotalSection
                totalPenawaran={totalPenawaran}
                totalLC={totalLC}
                totalMP={totalMP}
              />
              <div className="row mt-2">
                <div className="col">
                  <TextEditor
                    label="Teks Penutup"
                    placeholder="Tuliskan teks penutup"
                    editorState={textEditorStatePenutup}
                  />
                </div>
              </div>
              <Approval data={dataPenawaran} />
            </>
          )}

          <div style={{ display: 'none' }}>
            <PrintPenawaran
              id={id_penawaran}
              dataPenawaran={dataPenawaran}
              dataListPenawaran={dataListPenawaran}
              dataPrelim={dataPrelim}
              ref={refPrint}
            />
          </div>

          <ModalSection
            processedData={processedData}
            dataBarangJadi={dataListPenawaran}
            setDataBarangJadi={setDataListPenawaran}
            alertConfig={alertConfig}
            setAlertConfig={setAlertConfig}
            modalConfig={modalConfig}
            setModalConfig={setModalConfig}
          />
        </Card.Body>
      </Card>

      {/* Modal Peluang */}
      {modalPeluangConfig.show && (
        <PeluangPenawaran
          type="DETAIL"
          modalPeluangConfig={modalPeluangConfig}
          setModalPeluangConfig={setModalPeluangConfig}
        />
      )}
    </>
  );
};

export default DetailPenawaran;

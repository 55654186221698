import { Card, Col, Row } from 'react-bootstrap';
import { DateConvert } from 'utilities';

const Approval = ({ data, title }) => {
  const InfoItem = ({ title1, value1, title2, value2, tanggal, createdAt }) => {
    const inputDate = new Date(createdAt);
    const time = inputDate.toLocaleTimeString('en-US', { hour12: false });

    return (
      <div className="mb-2">
        <div className="mt-1">
          <small>Tanggal</small>
          <br />
          {/* <b>{DateConvert(new Date(tanggal)).detail}</b> */}
          <b>{DateConvert(new Date(createdAt)).detail}</b>
          <div>{time}</div>
        </div>

        <div className="mt-1">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
        </div>

        <div className="mt-1">
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      </div>
    );
  };

  return (
    <Card className="mt-4 mb-5">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Catatan Approval {title}</b>
      </Card.Header>
      <Card.Body>
        <Row>
          {data.stakeholder.map(
            (val, index) =>
              val.status_approval !== 'PEN' && (
                <Col sm>
                  <InfoItem
                    title1={
                      val.status_approval === 'VER' ||
                      val.status_approval === 'REV'
                        ? `Pemeriksa ${
                            val.approval_level !== '0' ? val.approval_level : ''
                          }`
                        : val.status_approval === 'APP'
                        ? 'Pengesah'
                        : 'Di Tolak Oleh'
                    }
                    value1={val.nama_karyawan ?? '-'}
                    title2="Catatan"
                    value2={val.catatan ?? '-'}
                    tanggal={val.tgl_approval}
                    createdAt={val.created_at}
                  />
                </Col>
              )
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Approval;

import { useRef, useMemo, useState, useCallback } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import FileSaver from 'file-saver';
import {
  ActionButton,
  DataStatus,
  DeleteModal,
  Alert,
  Table,
  ThFixed,
  Th,
  Td,
} from 'components';
import {
  IoDocumentOutline,
  IoDownloadOutline,
  IoTrashOutline,
} from 'react-icons/io5';

const TabFile = ({ type: TYPE, dataFile, setDataFile, action }) => {
  const { REACT_APP_DOWNLOAD_FILE_BASE_URL } = process.env;
  const inputFileRef = useRef();
  const [modalDeleteConfig, setModalDeleteConfig] = useState({
    index: '',
    show: false,
    loading: false,
  });

  // Trigger input file agar terclick
  const onClickFilesHandler = () =>
    TYPE !== 'DETAIL' && inputFileRef.current.click();

  // Menangani saat file dipilih
  const onChangeFileHandler = (e) => {
    const file = [...e.target.files];

    // Check apakah ada file yang dipilih
    if (!file) return null;

    const getFileType = file.map((val) => val.name.split('.').pop());

    const acceptedFormat = [
      'doc',
      'docx',
      'pdf',
      'xlsx',
      'xls',
      'dwg',
      'docx',
      'skp',
      'zip',
      'rar',
    ];

    if (getFileType.some((e) => acceptedFormat.includes(e))) {
      const finalValue = file.map((item) => {
        const generateName = item.name;
        const generateLink = URL.createObjectURL(item);
        return { data: item, nama: generateName, link: generateLink };
      });

      setDataFile([...dataFile, ...finalValue]);
    } else {
      window.alert(
        'File tidak valid! harap masukan file berformat ' +
          acceptedFormat.map((val) => `.${val}`).join(' ')
      );
    }
  };

  // Menangani hapus file
  const onDeleteHandler = useCallback(() => {
    setModalDeleteConfig({ ...modalDeleteConfig, loading: true });
    const filterData = dataFile.filter(
      (val, index) => index !== modalDeleteConfig.index
    );

    setTimeout(() => {
      setDataFile(filterData);
      setModalDeleteConfig({
        index: '',
        show: false,
        loading: false,
      });
    }, 300);
  }, [modalDeleteConfig]);

  return (
    <div>
      {/* Button Section */}
      <div className="mb-3 text-right">
        <input
          ref={inputFileRef}
          type="file"
          style={{ display: 'none' }}
          onChange={onChangeFileHandler}
          multiple
        />
        {TYPE !== 'DETAIL' && (
          <ActionButton text="Tambah File" onClick={onClickFilesHandler} />
        )}
      </div>

      {/* Table */}
      <Table>
        <thead className="bg-light">
          <tr>
            <ThFixed>No</ThFixed>
            <Th>Nama File</Th>
            <Th width={60}>Aksi</Th>
          </tr>
        </thead>
        <tbody>
          {dataFile && dataFile.length > 0 ? (
            dataFile.map((val, index) => {
              const downloadFile =
                action === 'READ'
                  ? REACT_APP_DOWNLOAD_FILE_BASE_URL + val.link
                  : val.link;

              return (
                <tr>
                  <Td textCenter>{index + 1}</Td>
                  <Td>{val.nama}</Td>
                  <Td className="text-center">
                    <ButtonGroup className="m-1">
                      {/* Button Download */}
                      <ActionButton
                        size="sm"
                        onClick={() => FileSaver.saveAs(downloadFile, val.nama)}
                      >
                        <IoDownloadOutline />
                      </ActionButton>

                      {/* Button Delete */}
                      {TYPE !== 'DETAIL' && (
                        <ActionButton
                          size="sm"
                          variant="danger"
                          onClick={() => {
                            setModalDeleteConfig({
                              show: true,
                              loading: false,
                              index: index,
                            });
                          }}
                        >
                          <IoTrashOutline />
                        </ActionButton>
                      )}
                    </ButtonGroup>
                  </Td>
                </tr>
              );
            })
          ) : (
            <tr style={{ cursor: 'pointer' }} onClick={onClickFilesHandler}>
              <Td colSpan={3}>
                <div className="d-flex flex-column justify-content-center align-items-center py-5">
                  <IoDocumentOutline size={50} />
                  <b style={{ fontSize: 16 }} className="mt-2">
                    Tidak ada file
                  </b>
                </div>
              </Td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Modal */}
      <DeleteModal
        show={modalDeleteConfig.show}
        title="File"
        loading={modalDeleteConfig.loading}
        onConfirm={onDeleteHandler}
        onHide={() =>
          setModalDeleteConfig({
            show: false,
            index: '',
          })
        }
      />
    </div>
  );
};

export default TabFile;

import { createContext, useEffect, useState } from 'react';
import { AnalisaBarangJadiRABApi } from 'api';

export const DropdownRABContext = createContext();

export const DropdownRABContextProvider = ({ children }) => {
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [dropdown, setDropdown] = useState({
    item_prelim: [],
  });
  const [loading, setLoading] = useState({ item_prelim: false });

  const fetchItemPrelim = () => {
    setLoading((prev) => ({ ...prev, item_prelim: true }));

    AnalisaBarangJadiRABApi.getItemPrelim()
      .then((res) =>
        setDropdown((prev) => ({
          ...prev,
          item_prelim: res.data.data,
        }))
      )
      .catch(() => alert('Gagal memuat dropdown item prelim'))
      .finally(() => setLoading((prev) => ({ ...prev, item_prelim: false })));
  };

  useEffect(() => {
    if (triggerFetch) {
      fetchItemPrelim();
    }

    return () => {
      setDropdown({
        item_prelim: [],
      });

      setLoading({
        item_prelim: false,
      });
    };
  }, [triggerFetch]);

  return (
    <DropdownRABContext.Provider
      value={{
        dropdown,
        setDropdown,
        loading,
        setLoading,
        setTriggerFetch,
      }}
    >
      {children}
    </DropdownRABContext.Provider>
  );
};

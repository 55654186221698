import { useContext, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  DatePicker,
  Input,
} from 'components';
import { DateConvert } from 'utilities';
import { RencanaAnggaranBiayaApi } from 'api';
import PeluangRAB from '../PeluangRAB';
import {
  DropdownAnalisaBarangJadiContext,
  DropdownAnalisaBarangJadiContextProvider,
  DropdownRABContext,
  DropdownRABContextProvider,
  RABContext,
  RABContextProvider,
} from '../RABContext';
import { TableRAB } from '../Section';

const TambahRAB = ({ setNavbarTitle }) => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const ContentTambahRAB = () => {
    const {
      dataAnalisaBarangJadi: contextAnalisa,
      dataPrelim,
      setDataPrelim,
    } = useContext(RABContext);
    const { setTriggerFetch: setTriggerFetchDropdownAnalisa } = useContext(
      DropdownAnalisaBarangJadiContext
    );
    const { setTriggerFetch: setTriggerFetchDropdownRAB } =
      useContext(DropdownRABContext);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [dataRAB, setDataRAB] = useState({});
    const [dataListRAB, setDataListRAB] = useState([]);
    const [noRAB, setNoRAB] = useState();
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: 'primary',
      text: '',
    });
    const [modalPeluangConfig, setModalPeluangConfig] = useState({
      show: false,
      no_peluang: '',
    });

    const getInitialData = () => {
      setIsPageLoading(true);

      //GET SINGLE DETAIL RAB
      Axios.all([RencanaAnggaranBiayaApi.getSingleRAE({ id_rae: id })])
        .then(
          Axios.spread((data) => {
            const dataInfo = data.data.data;
            const dataList = dataInfo.detail ?? [];
            const dataPrelim = dataInfo.prelim ?? [];

            const mapDataListRAB = dataList.map((val) => ({
              id_rae_detail: val.id_rae_detail,
              id_barang_jadi: val.id_barang_jadi,
              kode_item: val.kode_item,
              nama_item: val.nama_item,
              nama_satuan: val.nama_satuan,
              qty: val.qty_rae ? parseFloat(val.qty_rae) : 0,
              harga: val.harga_satuan_rae
                ? parseFloat(val.harga_satuan_rae)
                : 0,
              rounded: val.harga_satuan_rae
                ? parseFloat(val.harga_satuan_rae)
                : 0,
              profit: val.harga_satuan_rae
                ? parseFloat(val.harga_satuan_rae)
                : 0,
            }));

            const filterPrelim = dataPrelim.map((val, index) => ({
              id_kelompok: val.id_kelompok,
              id_item: val.id_item_prelim,
              kode_item: val.kode_item_prelim,
              nama_item: val.nama_item_prelim,
              qty_item: val.qty_analisa,
              id_unit: val.id_satuan_prelim,
              nama_unit: val.nama_satuan_prelim,
              harga: parseFloat(val.unit_price ?? 0),
              qty_durasi: parseFloat(val.qty_durasi ?? 0),
              konstanta: parseFloat(val.konstanta ?? 0),
              order: index + 1,
            }));

            setDataRAB(dataInfo);
            setDataListRAB(mapDataListRAB);
            setDataPrelim(filterPrelim);

            setTriggerFetchDropdownAnalisa(true);
            setTriggerFetchDropdownRAB(true);
          })
        )
        .catch(() =>
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal dimuat!',
          })
        )
        .finally(() => setIsPageLoading(false));
    };

    // GET NOMOR RAB
    const getNomorRAB = (tgl) => {
      RencanaAnggaranBiayaApi.getNomorRAB({ tanggal: tgl })
        .then((no_rab) => {
          setNoRAB(no_rab.data.data ?? '');
        })
        .catch((err) => alert(err.response.data.data))
        .finally(() => setIsPageLoading(false));
    };

    const checkAlert = () => {
      const locationState = location.state;

      if (locationState) {
        if (locationState.alert) {
          setAlertConfig({
            show: locationState.alert.show,
            text: locationState.alert.text,
            variant: locationState.alert.variant,
          });
        }
      }
    };

    const InfoSection = () => {
      const InfoItem = ({ title, value, link, onClick }) => (
        <tr onClick={onClick}>
          <td>{title}</td>
          <td className={`pl-4 pr-2`}>:</td>
          <td
            className={link ? 'text-primary' : ''}
            style={{ cursor: link ? 'pointer' : 'default' }}
          >
            {value}
          </td>
        </tr>
      );

      return (
        <Row>
          <Col>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItem
                  title="Tgl. RAE"
                  value={
                    dataRAB?.tgl_rae
                      ? DateConvert(new Date(dataRAB.tgl_rae)).detail
                      : '-'
                  }
                />
                <InfoItem title="No. RAE" value={dataRAB?.no_rae ?? '-'} />
                <InfoItem
                  link
                  title="No. Peluang"
                  value={dataRAB?.no_peluang ?? '-'}
                  onClick={() =>
                    setModalPeluangConfig({
                      show: true,
                      no_peluang: dataRAB?.no_peluang ?? '',
                    })
                  }
                />
                <InfoItem title="Proyek" value={dataRAB?.nama_proyek ?? '-'} />
              </tbody>
            </table>
          </Col>
          <Col>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItem
                  title="Customer"
                  value={dataRAB?.nama_customer ?? '-'}
                />
                <InfoItem title="ATT" value={dataRAB?.att ?? '-'} />
                <InfoItem
                  title="Tgl. Realisasi Penawaran"
                  value={
                    dataRAB?.tgl_selesai
                      ? DateConvert(new Date(dataRAB.tgl_selesai)).detail
                      : '-'
                  }
                />
              </tbody>
            </table>
          </Col>
        </Row>
      );
    };

    useEffect(() => {
      setNavbarTitle('Rencana Anggaran Biaya');
      getInitialData();
      getNomorRAB(new Date().toISOString().split('T')[0]);
      checkAlert();

      return () => {
        setIsPageLoading(false);
      };
    }, []);

    const mappingHardwood = (data) =>
      data.map((val) => ({
        id_barang_jadi: val.id_barang_jadi,
        urutan_item: val.urutan_item,
        id_deskripsi: val.id_deskripsi,
        is_header: val.is_header,
        qty_raw: val.qty_raw,
        t_raw: val.t_raw,
        w_raw: val.w_raw,
        l_raw: val.l_raw,
        qty_final: val.qty_final,
        t_final: val.t_final,
        w_final: val.w_final,
        l_final: val.l_final,
        unit_price: val.unit_price,
        konstanta: val.konstanta,
        id_jenis_kayu: val.id_jenis_kayu,
        id_finishing_barang_jadi: val.id_finishing_barang_jadi,
        id_tipe_sisi: val.id_tipe_sisi,
        id_part_kayu: val.id_part_kayu,
      }));

    const mappingPlywood = (data) =>
      data.map((val) => ({
        id_barang_jadi: val.id_barang_jadi,
        urutan_item: val.urutan_item,
        id_deskripsi: val.id_deskripsi,
        deskripsi: val.deskripsi,
        is_header: val.is_header,
        qty_final: val.qty_final,
        t_final: val.t_final,
        w_final: val.w_final,
        l_final: val.l_final,
        qty_raw: val.qty_raw,
        t_raw: val.t_raw,
        w_raw: val.w_raw,
        l_raw: val.l_raw,
        unit_price: val.unit_price,
        konstanta: val.konstanta,
        id_item_buaso: val.id_item_buaso,
        id_finishing_barang_jadi: val.id_finishing_barang_jadi,
        id_tipe_sisi: val.id_tipe_sisi,
      }));

    const mappingTP = (value) => {
      const final = [];
      value.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          id_item_buaso: val.id_item_buaso,
          qty: val.qty,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
        });
      });

      return final;
    };

    const mappingFN = (value) => {
      const final = [];
      value.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          id_item_buaso: val.id_item_bahan,
          qty: val.qty,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
        });
      });

      return final;
    };

    const mappingPrelim = (data) =>
      data.map((val) => ({
        id_item_buaso: val.id_item,
        qty_analisa: val.qty_item,
        unit_price: val.harga,
        qty_durasi: val.qty_durasi,
        id_satuan_durasi: val.id_unit,
        konstanta: val.konstanta,
      }));

    // INITIAL VALUES FORM TAMBAH DATA TIPE BARANG JADI
    const formInitialValues = {
      id_rae: id,
      tgl_rab: new Date().toISOString().split('T')[0],
      no_rab: noRAB ?? '-',
      profit: 0,
    };

    // FORMIK VALIDATION SCHEMA TIPE BARANG JADI DENGAN YUP VALIDATION
    const formValidationSchema = Yup.object().shape({
      tgl_rab: Yup.date().required('Tanggal RAB wajib diisi'),
    });

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values, { setSubmitting }) => {
      const detailAnalisa = [];
      contextAnalisa.map((val) =>
        detailAnalisa.push({
          index: val.index,
          analisa_hardwood: mappingHardwood(val.dataHardwood ?? []),
          analisa_plywood: mappingPlywood(val.dataPlywood ?? []),
          analisa_fin_tp_fs: mappingTP(val.dataTPFS ?? []),
          analisa_fin_tp_lc: mappingTP(val.dataTPLC ?? []),
          analisa_fin_tp_mp: mappingTP(val.dataTPMP ?? []),
          analisa_fin_tp_bop: mappingTP(val.dataTPBOP ?? []),
          analisa_fin_fn_bp: mappingFN(val.dataFNBP ?? []),
          analisa_fin_fn_sc: mappingFN(val.dataFNSC ?? []),
          analisa_fin_fn_lc: mappingFN(val.dataFNLC ?? []),
          analisa_alat_bantu_onsite: val.dataAlatBantuOnSite,
          analisa_biaya_overhead_kantor: val.dataAnalisaBOK,
          analisa_labour_cost_onsite: val.dataLabourCostOnSite,
        })
      );

      const listRAB = dataListRAB.map((val, idx) => ({
        is_edit: val.is_edit ?? false,
        id_rae_detail: val.id_rae_detail,
        rounded: val.rounded,
        harga_satuan_rab: val.harga,
        qty_rab: val.qty,
        id_barang_jadi: val.id_barang_jadi,
        analisa_barang_jadi: detailAnalisa.find((val) => val.index === idx)
          ? detailAnalisa.find((val) => val.index === idx)
          : {},
      }));

      const finalValues = {
        id_rae: id,
        tgl_rab: values.tgl_rab,
        no_rab: values.no_rab,
        profit: values.profit,
        prelim: 0,
        detail_barang_jadi: listRAB,
        detail_prelim: mappingPrelim(dataPrelim),
      };

      RencanaAnggaranBiayaApi.save(finalValues)
        .then(() =>
          history.push('/transaksi/rencana-anggaran-biaya', {
            alert: {
              show: true,
              variant: 'primary',
              text: 'Data berhasil ditambah!',
            },
          })
        )
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal ditambah!',
          });
        })
        .finally(() => setSubmitting(false));
    };

    return (
      <>
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Tambah Data RAB</b>
            <BackButton onClick={() => history.goBack()} />
          </Card.Header>
          <Card.Body>
            <Alert
              showCloseButton
              show={alertConfig.show}
              variant={alertConfig.variant}
              text={alertConfig.text}
              onClose={() =>
                setAlertConfig({
                  ...alertConfig,
                  show: false,
                })
              }
            />
            {isPageLoading ? (
              <DataStatus loading={true} text="Memuat data . . ." />
            ) : (
              <>
                <InfoSection />
                <hr />
              </>
            )}

            <Formik
              enableReinitialize={true}
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={formSubmitHandler}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleSubmit,
                setFieldValue,
                setValues,
              }) => {
                const getNewNomor = (tgl) => {
                  RencanaAnggaranBiayaApi.getNomorRAB({ tanggal: tgl })
                    .then((res) => {
                      const nomor = res?.data?.data ?? '';

                      setValues({
                        ...values,
                        no_rab: nomor,
                        tgl_rab: tgl,
                      });
                    })
                    .catch((err) => {
                      window.alert('Nomor gagal dimuat');
                      setValues({
                        ...values,
                        no_rab: '',
                        tgl_rab: '',
                      });
                    })
                    .finally(() => setIsPageLoading(false));
                };

                const getAfterProfit = (index) => {
                  const newProfit = values.profit
                    ? parseFloat(values.profit)
                    : 0;
                  const newHarga = dataListRAB[index].harga
                    ? parseFloat(dataListRAB[index].harga)
                    : 0;
                  const afterProfit = (newProfit / 100 + 1) * newHarga;

                  return parseInt(afterProfit);
                };

                const getTotal = (index) => {
                  const price = dataListRAB[index].rounded
                    ? dataListRAB[index].rounded
                    : 0;
                  const qty = dataListRAB[index].qty
                    ? dataListRAB[index].qty
                    : 0;

                  return parseFloat(price * qty);
                };

                const getGrandTotalPrelim = dataPrelim.reduce(
                  (prev, current) => {
                    const newQtyItem = current.qty_item
                      ? parseFloat(current.qty_item)
                      : 0;
                    const newHarga = current.harga
                      ? parseFloat(current.harga)
                      : 0;
                    const newQtyDurasi = current.qty_durasi
                      ? parseFloat(current.qty_durasi)
                      : 0;
                    const newKonts = current.konstanta
                      ? parseFloat(current.konstanta)
                      : 0;
                    const subTotal = newQtyItem * newHarga;
                    // const total = subTotal * newQtyDurasi * newKonts;
                    const total = subTotal;

                    return prev + total;
                  },
                  0
                );

                const onProfitChange = (profit) => {
                  const newProfit = profit ? profit : 0;

                  const newList = dataListRAB.map((val) => {
                    const afterProfit = (newProfit / 100 + 1) * val.harga;

                    return {
                      ...val,
                      profit: parseInt(afterProfit),
                      rounded: parseInt(afterProfit),
                    };
                  });

                  setFieldValue('profit', profit);
                  setDataListRAB(newList);
                };

                const onInputRoundedChange = ({ value, index }) => {
                  const newList = dataListRAB.map((val, idx) =>
                    idx === index ? { ...val, rounded: value } : val
                  );

                  setDataListRAB(newList);
                };

                return (
                  <form onSubmit={handleSubmit}>
                    {!isPageLoading && (
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <DatePicker
                            label="Tgl. RAB"
                            name="tgl_rab"
                            selected={
                              values.tgl_rab ? new Date(values.tgl_rab) : ''
                            }
                            onChange={(val) => {
                              const value = val.toISOString().split('T')[0];
                              getNewNomor(value);
                            }}
                            error={errors.tgl_rab && touched.tgl_rab && true}
                            errorText={errors.tgl_rab}
                          />
                        </div>
                        <div className="col-md-6">
                          <Input
                            label="No. RAB"
                            type="text"
                            name="no_rab"
                            value={values.no_rab}
                            readOnly={true}
                          />
                        </div>
                      </div>
                    )}

                    {!isPageLoading && (
                      <>
                        <TableRAB
                          profit={values.profit}
                          dataListRAB={dataListRAB}
                          setDataListRAB={setDataListRAB}
                          getTotal={getTotal}
                          getAfterProfit={getAfterProfit}
                          getGrandTotalPrelim={getGrandTotalPrelim}
                          onProfitChange={onProfitChange}
                          onInputRoundedChange={onInputRoundedChange}
                        />

                        <hr />
                        <div className="text-right mt-3">
                          <ActionButton
                            type="submit"
                            className="btn btn-primary text-white px-4"
                            size="md"
                            text="Simpan Data RAB"
                            loading={isSubmitting}
                          />
                        </div>
                      </>
                    )}
                  </form>
                );
              }}
            </Formik>
          </Card.Body>
        </Card>

        {/* Modal Peluang */}
        {modalPeluangConfig.show && (
          <PeluangRAB
            type="TAMBAH"
            modalPeluangConfig={modalPeluangConfig}
            setModalPeluangConfig={setModalPeluangConfig}
          />
        )}
      </>
    );
  };

  return (
    <RABContextProvider>
      <DropdownRABContextProvider>
        <DropdownAnalisaBarangJadiContextProvider>
          <ContentTambahRAB />
        </DropdownAnalisaBarangJadiContextProvider>
      </DropdownRABContextProvider>
    </RABContextProvider>
  );
};

export default TambahRAB;
